.Mixin-module__bui_mixin_position___7xkir {
  position: var(--bui_mixin_position) !important;
}
.Mixin-module__bui_mixin_position___7xkir[style*='--bui_mixin_position--s'] {
  --bui_mixin_position: var(--bui_mixin_position--s);
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_position___7xkir[style*='--bui_mixin_position--m'] {
    --bui_mixin_position: var(--bui_mixin_position--m);
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_position___7xkir[style*='--bui_mixin_position--l'] {
    --bui_mixin_position: var(--bui_mixin_position--l);
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_position___7xkir[style*='--bui_mixin_position--xl'] {
    --bui_mixin_position: var(--bui_mixin_position--xl);
  }
}
.Mixin-module__bui_mixin_z-index-0___Pn0\+z {
  z-index: var(--bui_z_index_0) !important;
}
.Mixin-module__bui_mixin_z-index-1___UK0Ba {
  z-index: var(--bui_z_index_1) !important;
}
.Mixin-module__bui_mixin_z-index-2___Brtfr {
  z-index: var(--bui_z_index_2) !important;
}
.Mixin-module__bui_mixin_z-index-3___FgQI9 {
  z-index: var(--bui_z_index_3) !important;
}
.Mixin-module__bui_mixin_z-index-4___ZTF8S {
  z-index: var(--bui_z_index_4) !important;
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_z-index-0--m___rTqWp {
    z-index: var(--bui_z_index_0) !important;
  }
  .Mixin-module__bui_mixin_z-index-1--m___5Bq8t {
    z-index: var(--bui_z_index_1) !important;
  }
  .Mixin-module__bui_mixin_z-index-2--m___jC5l9 {
    z-index: var(--bui_z_index_2) !important;
  }
  .Mixin-module__bui_mixin_z-index-3--m___N67iy {
    z-index: var(--bui_z_index_3) !important;
  }
  .Mixin-module__bui_mixin_z-index-4--m___00J7z {
    z-index: var(--bui_z_index_4) !important;
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_z-index-0--l___QXVIs {
    z-index: var(--bui_z_index_0) !important;
  }
  .Mixin-module__bui_mixin_z-index-1--l___NWIdT {
    z-index: var(--bui_z_index_1) !important;
  }
  .Mixin-module__bui_mixin_z-index-2--l___Y-ukg {
    z-index: var(--bui_z_index_2) !important;
  }
  .Mixin-module__bui_mixin_z-index-3--l___TuFRV {
    z-index: var(--bui_z_index_3) !important;
  }
  .Mixin-module__bui_mixin_z-index-4--l___tEGwU {
    z-index: var(--bui_z_index_4) !important;
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_z-index-0--xl___8f5Hz {
    z-index: var(--bui_z_index_0) !important;
  }
  .Mixin-module__bui_mixin_z-index-1--xl___EMtIj {
    z-index: var(--bui_z_index_1) !important;
  }
  .Mixin-module__bui_mixin_z-index-2--xl___o53g3 {
    z-index: var(--bui_z_index_2) !important;
  }
  .Mixin-module__bui_mixin_z-index-3--xl___-ngfS {
    z-index: var(--bui_z_index_3) !important;
  }
  .Mixin-module__bui_mixin_z-index-4--xl___Z9Uz4 {
    z-index: var(--bui_z_index_4) !important;
  }
}
.Mixin-module__bui_mixin_height___6L\+O7 {
  height: var(--bui_mixin_height) !important;
}
.Mixin-module__bui_mixin_height___6L\+O7[style*='--bui_mixin_height--s'] {
  --bui_mixin_height: var(--bui_mixin_height--s);
}
.Mixin-module__bui_mixin_height___6L\+O7[style*='--bui_mixin_spaced_height--s'] {
  --bui_mixin_height: calc(var(--bui_mixin_spaced_height--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_height___6L\+O7[style*='--bui_mixin_height--m'] {
    --bui_mixin_height: var(--bui_mixin_height--m);
  }
  .Mixin-module__bui_mixin_height___6L\+O7[style*='--bui_mixin_spaced_height--m'] {
    --bui_mixin_height: calc(var(--bui_mixin_spaced_height--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_height___6L\+O7[style*='--bui_mixin_height--l'] {
    --bui_mixin_height: var(--bui_mixin_height--l);
  }
  .Mixin-module__bui_mixin_height___6L\+O7[style*='--bui_mixin_spaced_height--l'] {
    --bui_mixin_height: calc(var(--bui_mixin_spaced_height--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_height___6L\+O7[style*='--bui_mixin_height--xl'] {
    --bui_mixin_height: var(--bui_mixin_height--xl);
  }
  .Mixin-module__bui_mixin_height___6L\+O7[style*='--bui_mixin_spaced_height--xl'] {
    --bui_mixin_height: calc(var(--bui_mixin_spaced_height--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_min-height___TgE07 {
  min-height: var(--bui_mixin_min-height) !important;
}
.Mixin-module__bui_mixin_min-height___TgE07[style*='--bui_mixin_min-height--s'] {
  --bui_mixin_min-height: var(--bui_mixin_min-height--s);
}
.Mixin-module__bui_mixin_min-height___TgE07[style*='--bui_mixin_spaced_min-height--s'] {
  --bui_mixin_min-height: calc(var(--bui_mixin_spaced_min-height--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_min-height___TgE07[style*='--bui_mixin_min-height--m'] {
    --bui_mixin_min-height: var(--bui_mixin_min-height--m);
  }
  .Mixin-module__bui_mixin_min-height___TgE07[style*='--bui_mixin_spaced_min-height--m'] {
    --bui_mixin_min-height: calc(var(--bui_mixin_spaced_min-height--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_min-height___TgE07[style*='--bui_mixin_min-height--l'] {
    --bui_mixin_min-height: var(--bui_mixin_min-height--l);
  }
  .Mixin-module__bui_mixin_min-height___TgE07[style*='--bui_mixin_spaced_min-height--l'] {
    --bui_mixin_min-height: calc(var(--bui_mixin_spaced_min-height--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_min-height___TgE07[style*='--bui_mixin_min-height--xl'] {
    --bui_mixin_min-height: var(--bui_mixin_min-height--xl);
  }
  .Mixin-module__bui_mixin_min-height___TgE07[style*='--bui_mixin_spaced_min-height--xl'] {
    --bui_mixin_min-height: calc(var(--bui_mixin_spaced_min-height--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_max-height___-1F7e {
  max-height: var(--bui_mixin_max-height) !important;
}
.Mixin-module__bui_mixin_max-height___-1F7e[style*='--bui_mixin_max-height--s'] {
  --bui_mixin_max-height: var(--bui_mixin_max-height--s);
}
.Mixin-module__bui_mixin_max-height___-1F7e[style*='--bui_mixin_spaced_max-height--s'] {
  --bui_mixin_max-height: calc(var(--bui_mixin_spaced_max-height--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_max-height___-1F7e[style*='--bui_mixin_max-height--m'] {
    --bui_mixin_max-height: var(--bui_mixin_max-height--m);
  }
  .Mixin-module__bui_mixin_max-height___-1F7e[style*='--bui_mixin_spaced_max-height--m'] {
    --bui_mixin_max-height: calc(var(--bui_mixin_spaced_max-height--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_max-height___-1F7e[style*='--bui_mixin_max-height--l'] {
    --bui_mixin_max-height: var(--bui_mixin_max-height--l);
  }
  .Mixin-module__bui_mixin_max-height___-1F7e[style*='--bui_mixin_spaced_max-height--l'] {
    --bui_mixin_max-height: calc(var(--bui_mixin_spaced_max-height--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_max-height___-1F7e[style*='--bui_mixin_max-height--xl'] {
    --bui_mixin_max-height: var(--bui_mixin_max-height--xl);
  }
  .Mixin-module__bui_mixin_max-height___-1F7e[style*='--bui_mixin_spaced_max-height--xl'] {
    --bui_mixin_max-height: calc(var(--bui_mixin_spaced_max-height--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_width___Gpa6R {
  width: var(--bui_mixin_width) !important;
}
.Mixin-module__bui_mixin_width___Gpa6R[style*='--bui_mixin_width--s'] {
  --bui_mixin_width: var(--bui_mixin_width--s);
}
.Mixin-module__bui_mixin_width___Gpa6R[style*='--bui_mixin_spaced_width--s'] {
  --bui_mixin_width: calc(var(--bui_mixin_spaced_width--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_width___Gpa6R[style*='--bui_mixin_width--m'] {
    --bui_mixin_width: var(--bui_mixin_width--m);
  }
  .Mixin-module__bui_mixin_width___Gpa6R[style*='--bui_mixin_spaced_width--m'] {
    --bui_mixin_width: calc(var(--bui_mixin_spaced_width--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_width___Gpa6R[style*='--bui_mixin_width--l'] {
    --bui_mixin_width: var(--bui_mixin_width--l);
  }
  .Mixin-module__bui_mixin_width___Gpa6R[style*='--bui_mixin_spaced_width--l'] {
    --bui_mixin_width: calc(var(--bui_mixin_spaced_width--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_width___Gpa6R[style*='--bui_mixin_width--xl'] {
    --bui_mixin_width: var(--bui_mixin_width--xl);
  }
  .Mixin-module__bui_mixin_width___Gpa6R[style*='--bui_mixin_spaced_width--xl'] {
    --bui_mixin_width: calc(var(--bui_mixin_spaced_width--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_min-width___qCkAA {
  min-width: var(--bui_mixin_min-width) !important;
}
.Mixin-module__bui_mixin_min-width___qCkAA[style*='--bui_mixin_min-width--s'] {
  --bui_mixin_min-width: var(--bui_mixin_min-width--s);
}
.Mixin-module__bui_mixin_min-width___qCkAA[style*='--bui_mixin_spaced_min-width--s'] {
  --bui_mixin_min-width: calc(var(--bui_mixin_spaced_min-width--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_min-width___qCkAA[style*='--bui_mixin_min-width--m'] {
    --bui_mixin_min-width: var(--bui_mixin_min-width--m);
  }
  .Mixin-module__bui_mixin_min-width___qCkAA[style*='--bui_mixin_spaced_min-width--m'] {
    --bui_mixin_min-width: calc(var(--bui_mixin_spaced_min-width--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_min-width___qCkAA[style*='--bui_mixin_min-width--l'] {
    --bui_mixin_min-width: var(--bui_mixin_min-width--l);
  }
  .Mixin-module__bui_mixin_min-width___qCkAA[style*='--bui_mixin_spaced_min-width--l'] {
    --bui_mixin_min-width: calc(var(--bui_mixin_spaced_min-width--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_min-width___qCkAA[style*='--bui_mixin_min-width--xl'] {
    --bui_mixin_min-width: var(--bui_mixin_min-width--xl);
  }
  .Mixin-module__bui_mixin_min-width___qCkAA[style*='--bui_mixin_spaced_min-width--xl'] {
    --bui_mixin_min-width: calc(var(--bui_mixin_spaced_min-width--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_max-width___yUZ8U {
  max-width: var(--bui_mixin_max-width) !important;
}
.Mixin-module__bui_mixin_max-width___yUZ8U[style*='--bui_mixin_max-width--s'] {
  --bui_mixin_max-width: var(--bui_mixin_max-width--s);
}
.Mixin-module__bui_mixin_max-width___yUZ8U[style*='--bui_mixin_spaced_max-width--s'] {
  --bui_mixin_max-width: calc(var(--bui_mixin_spaced_max-width--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_max-width___yUZ8U[style*='--bui_mixin_max-width--m'] {
    --bui_mixin_max-width: var(--bui_mixin_max-width--m);
  }
  .Mixin-module__bui_mixin_max-width___yUZ8U[style*='--bui_mixin_spaced_max-width--m'] {
    --bui_mixin_max-width: calc(var(--bui_mixin_spaced_max-width--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_max-width___yUZ8U[style*='--bui_mixin_max-width--l'] {
    --bui_mixin_max-width: var(--bui_mixin_max-width--l);
  }
  .Mixin-module__bui_mixin_max-width___yUZ8U[style*='--bui_mixin_spaced_max-width--l'] {
    --bui_mixin_max-width: calc(var(--bui_mixin_spaced_max-width--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_max-width___yUZ8U[style*='--bui_mixin_max-width--xl'] {
    --bui_mixin_max-width: var(--bui_mixin_max-width--xl);
  }
  .Mixin-module__bui_mixin_max-width___yUZ8U[style*='--bui_mixin_spaced_max-width--xl'] {
    --bui_mixin_max-width: calc(var(--bui_mixin_spaced_max-width--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_inset___W0I4a {
  inset: var(--bui_mixin_inset) !important;
}
.Mixin-module__bui_mixin_inset___W0I4a[style*='--bui_mixin_spaced_inset--s'] {
  --bui_mixin_inset: calc(var(--bui_mixin_spaced_inset--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_inset___W0I4a[style*='--bui_mixin_spaced_inset--m'] {
    --bui_mixin_inset: calc(var(--bui_mixin_spaced_inset--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_inset___W0I4a[style*='--bui_mixin_spaced_inset--l'] {
    --bui_mixin_inset: calc(var(--bui_mixin_spaced_inset--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_inset___W0I4a[style*='--bui_mixin_spaced_inset--xl'] {
    --bui_mixin_inset: calc(var(--bui_mixin_spaced_inset--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_inset-block-start___Mpv9y {
  inset-block-start: var(--bui_mixin_inset-block-start) !important;
}
.Mixin-module__bui_mixin_inset-block-start___Mpv9y[style*='--bui_mixin_inset-block-start--s'] {
  --bui_mixin_inset-block-start: var(--bui_mixin_inset-block-start--s);
}
.Mixin-module__bui_mixin_inset-block-start___Mpv9y[style*='--bui_mixin_spaced_inset-block-start--s'] {
  --bui_mixin_inset-block-start: calc(var(--bui_mixin_spaced_inset-block-start--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_inset-block-start___Mpv9y[style*='--bui_mixin_inset-block-start--m'] {
    --bui_mixin_inset-block-start: var(--bui_mixin_inset-block-start--m);
  }
  .Mixin-module__bui_mixin_inset-block-start___Mpv9y[style*='--bui_mixin_spaced_inset-block-start--m'] {
    --bui_mixin_inset-block-start: calc(var(--bui_mixin_spaced_inset-block-start--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_inset-block-start___Mpv9y[style*='--bui_mixin_inset-block-start--l'] {
    --bui_mixin_inset-block-start: var(--bui_mixin_inset-block-start--l);
  }
  .Mixin-module__bui_mixin_inset-block-start___Mpv9y[style*='--bui_mixin_spaced_inset-block-start--l'] {
    --bui_mixin_inset-block-start: calc(var(--bui_mixin_spaced_inset-block-start--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_inset-block-start___Mpv9y[style*='--bui_mixin_inset-block-start--xl'] {
    --bui_mixin_inset-block-start: var(--bui_mixin_inset-block-start--xl);
  }
  .Mixin-module__bui_mixin_inset-block-start___Mpv9y[style*='--bui_mixin_spaced_inset-block-start--xl'] {
    --bui_mixin_inset-block-start: calc(var(--bui_mixin_spaced_inset-block-start--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_inset-block-end___xj5Aq {
  inset-block-end: var(--bui_mixin_inset-block-end) !important;
}
.Mixin-module__bui_mixin_inset-block-end___xj5Aq[style*='--bui_mixin_inset-block-end--s'] {
  --bui_mixin_inset-block-end: var(--bui_mixin_inset-block-end--s);
}
.Mixin-module__bui_mixin_inset-block-end___xj5Aq[style*='--bui_mixin_spaced_inset-block-end--s'] {
  --bui_mixin_inset-block-end: calc(var(--bui_mixin_spaced_inset-block-end--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_inset-block-end___xj5Aq[style*='--bui_mixin_inset-block-end--m'] {
    --bui_mixin_inset-block-end: var(--bui_mixin_inset-block-end--m);
  }
  .Mixin-module__bui_mixin_inset-block-end___xj5Aq[style*='--bui_mixin_spaced_inset-block-end--m'] {
    --bui_mixin_inset-block-end: calc(var(--bui_mixin_spaced_inset-block-end--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_inset-block-end___xj5Aq[style*='--bui_mixin_inset-block-end--l'] {
    --bui_mixin_inset-block-end: var(--bui_mixin_inset-block-end--l);
  }
  .Mixin-module__bui_mixin_inset-block-end___xj5Aq[style*='--bui_mixin_spaced_inset-block-end--l'] {
    --bui_mixin_inset-block-end: calc(var(--bui_mixin_spaced_inset-block-end--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_inset-block-end___xj5Aq[style*='--bui_mixin_inset-block-end--xl'] {
    --bui_mixin_inset-block-end: var(--bui_mixin_inset-block-end--xl);
  }
  .Mixin-module__bui_mixin_inset-block-end___xj5Aq[style*='--bui_mixin_spaced_inset-block-end--xl'] {
    --bui_mixin_inset-block-end: calc(var(--bui_mixin_spaced_inset-block-end--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_inset-inline-start___zMSsv {
  inset-inline-start: var(--bui_mixin_inset-inline-start) !important;
}
.Mixin-module__bui_mixin_inset-inline-start___zMSsv[style*='--bui_mixin_inset-inline-start--s'] {
  --bui_mixin_inset-inline-start: var(--bui_mixin_inset-inline-start--s);
}
.Mixin-module__bui_mixin_inset-inline-start___zMSsv[style*='--bui_mixin_spaced_inset-inline-start--s'] {
  --bui_mixin_inset-inline-start: calc(var(--bui_mixin_spaced_inset-inline-start--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_inset-inline-start___zMSsv[style*='--bui_mixin_inset-inline-start--m'] {
    --bui_mixin_inset-inline-start: var(--bui_mixin_inset-inline-start--m);
  }
  .Mixin-module__bui_mixin_inset-inline-start___zMSsv[style*='--bui_mixin_spaced_inset-inline-start--m'] {
    --bui_mixin_inset-inline-start: calc(var(--bui_mixin_spaced_inset-inline-start--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_inset-inline-start___zMSsv[style*='--bui_mixin_inset-inline-start--l'] {
    --bui_mixin_inset-inline-start: var(--bui_mixin_inset-inline-start--l);
  }
  .Mixin-module__bui_mixin_inset-inline-start___zMSsv[style*='--bui_mixin_spaced_inset-inline-start--l'] {
    --bui_mixin_inset-inline-start: calc(var(--bui_mixin_spaced_inset-inline-start--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_inset-inline-start___zMSsv[style*='--bui_mixin_inset-inline-start--xl'] {
    --bui_mixin_inset-inline-start: var(--bui_mixin_inset-inline-start--xl);
  }
  .Mixin-module__bui_mixin_inset-inline-start___zMSsv[style*='--bui_mixin_spaced_inset-inline-start--xl'] {
    --bui_mixin_inset-inline-start: calc(var(--bui_mixin_spaced_inset-inline-start--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_inset-inline-end___vesNY {
  inset-inline-end: var(--bui_mixin_inset-inline-end) !important;
}
.Mixin-module__bui_mixin_inset-inline-end___vesNY[style*='--bui_mixin_inset-inline-end--s'] {
  --bui_mixin_inset-inline-end: var(--bui_mixin_inset-inline-end--s);
}
.Mixin-module__bui_mixin_inset-inline-end___vesNY[style*='--bui_mixin_spaced_inset-inline-end--s'] {
  --bui_mixin_inset-inline-end: calc(var(--bui_mixin_spaced_inset-inline-end--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_inset-inline-end___vesNY[style*='--bui_mixin_inset-inline-end--m'] {
    --bui_mixin_inset-inline-end: var(--bui_mixin_inset-inline-end--m);
  }
  .Mixin-module__bui_mixin_inset-inline-end___vesNY[style*='--bui_mixin_spaced_inset-inline-end--m'] {
    --bui_mixin_inset-inline-end: calc(var(--bui_mixin_spaced_inset-inline-end--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_inset-inline-end___vesNY[style*='--bui_mixin_inset-inline-end--l'] {
    --bui_mixin_inset-inline-end: var(--bui_mixin_inset-inline-end--l);
  }
  .Mixin-module__bui_mixin_inset-inline-end___vesNY[style*='--bui_mixin_spaced_inset-inline-end--l'] {
    --bui_mixin_inset-inline-end: calc(var(--bui_mixin_spaced_inset-inline-end--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_inset-inline-end___vesNY[style*='--bui_mixin_inset-inline-end--xl'] {
    --bui_mixin_inset-inline-end: var(--bui_mixin_inset-inline-end--xl);
  }
  .Mixin-module__bui_mixin_inset-inline-end___vesNY[style*='--bui_mixin_spaced_inset-inline-end--xl'] {
    --bui_mixin_inset-inline-end: calc(var(--bui_mixin_spaced_inset-inline-end--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_margin___yXZwB {
  margin: var(--bui_mixin_margin) !important;
}
.Mixin-module__bui_mixin_margin___yXZwB[style*='--bui_mixin_margin--s'] {
  --bui_mixin_margin: var(--bui_mixin_margin--s);
}
.Mixin-module__bui_mixin_margin___yXZwB[style*='--bui_mixin_spaced_margin--s'] {
  --bui_mixin_margin: calc(var(--bui_mixin_spaced_margin--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_margin___yXZwB[style*='--bui_mixin_margin--m'] {
    --bui_mixin_margin: var(--bui_mixin_margin--m);
  }
  .Mixin-module__bui_mixin_margin___yXZwB[style*='--bui_mixin_spaced_margin--m'] {
    --bui_mixin_margin: calc(var(--bui_mixin_spaced_margin--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_margin___yXZwB[style*='--bui_mixin_margin--l'] {
    --bui_mixin_margin: var(--bui_mixin_margin--l);
  }
  .Mixin-module__bui_mixin_margin___yXZwB[style*='--bui_mixin_spaced_margin--l'] {
    --bui_mixin_margin: calc(var(--bui_mixin_spaced_margin--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_margin___yXZwB[style*='--bui_mixin_margin--xl'] {
    --bui_mixin_margin: var(--bui_mixin_margin--xl);
  }
  .Mixin-module__bui_mixin_margin___yXZwB[style*='--bui_mixin_spaced_margin--xl'] {
    --bui_mixin_margin: calc(var(--bui_mixin_spaced_margin--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_margin-block-start___h6hna {
  margin-block-start: var(--bui_mixin_margin-block-start) !important;
}
.Mixin-module__bui_mixin_margin-block-start___h6hna[style*='--bui_mixin_margin-block-start--s'] {
  --bui_mixin_margin-block-start: var(--bui_mixin_margin-block-start--s);
}
.Mixin-module__bui_mixin_margin-block-start___h6hna[style*='--bui_mixin_spaced_margin-block-start--s'] {
  --bui_mixin_margin-block-start: calc(var(--bui_mixin_spaced_margin-block-start--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_margin-block-start___h6hna[style*='--bui_mixin_margin-block-start--m'] {
    --bui_mixin_margin-block-start: var(--bui_mixin_margin-block-start--m);
  }
  .Mixin-module__bui_mixin_margin-block-start___h6hna[style*='--bui_mixin_spaced_margin-block-start--m'] {
    --bui_mixin_margin-block-start: calc(var(--bui_mixin_spaced_margin-block-start--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_margin-block-start___h6hna[style*='--bui_mixin_margin-block-start--l'] {
    --bui_mixin_margin-block-start: var(--bui_mixin_margin-block-start--l);
  }
  .Mixin-module__bui_mixin_margin-block-start___h6hna[style*='--bui_mixin_spaced_margin-block-start--l'] {
    --bui_mixin_margin-block-start: calc(var(--bui_mixin_spaced_margin-block-start--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_margin-block-start___h6hna[style*='--bui_mixin_margin-block-start--xl'] {
    --bui_mixin_margin-block-start: var(--bui_mixin_margin-block-start--xl);
  }
  .Mixin-module__bui_mixin_margin-block-start___h6hna[style*='--bui_mixin_spaced_margin-block-start--xl'] {
    --bui_mixin_margin-block-start: calc(var(--bui_mixin_spaced_margin-block-start--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_margin-block-end___JUzVF {
  margin-block-end: var(--bui_mixin_margin-block-end) !important;
}
.Mixin-module__bui_mixin_margin-block-end___JUzVF[style*='--bui_mixin_margin-block-end--s'] {
  --bui_mixin_margin-block-end: var(--bui_mixin_margin-block-end--s);
}
.Mixin-module__bui_mixin_margin-block-end___JUzVF[style*='--bui_mixin_spaced_margin-block-end--s'] {
  --bui_mixin_margin-block-end: calc(var(--bui_mixin_spaced_margin-block-end--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_margin-block-end___JUzVF[style*='--bui_mixin_margin-block-end--m'] {
    --bui_mixin_margin-block-end: var(--bui_mixin_margin-block-end--m);
  }
  .Mixin-module__bui_mixin_margin-block-end___JUzVF[style*='--bui_mixin_spaced_margin-block-end--m'] {
    --bui_mixin_margin-block-end: calc(var(--bui_mixin_spaced_margin-block-end--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_margin-block-end___JUzVF[style*='--bui_mixin_margin-block-end--l'] {
    --bui_mixin_margin-block-end: var(--bui_mixin_margin-block-end--l);
  }
  .Mixin-module__bui_mixin_margin-block-end___JUzVF[style*='--bui_mixin_spaced_margin-block-end--l'] {
    --bui_mixin_margin-block-end: calc(var(--bui_mixin_spaced_margin-block-end--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_margin-block-end___JUzVF[style*='--bui_mixin_margin-block-end--xl'] {
    --bui_mixin_margin-block-end: var(--bui_mixin_margin-block-end--xl);
  }
  .Mixin-module__bui_mixin_margin-block-end___JUzVF[style*='--bui_mixin_spaced_margin-block-end--xl'] {
    --bui_mixin_margin-block-end: calc(var(--bui_mixin_spaced_margin-block-end--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_margin-inline-start___fTWA0 {
  margin-inline-start: var(--bui_mixin_margin-inline-start) !important;
}
.Mixin-module__bui_mixin_margin-inline-start___fTWA0[style*='--bui_mixin_margin-inline-start--s'] {
  --bui_mixin_margin-inline-start: var(--bui_mixin_margin-inline-start--s);
}
.Mixin-module__bui_mixin_margin-inline-start___fTWA0[style*='--bui_mixin_spaced_margin-inline-start--s'] {
  --bui_mixin_margin-inline-start: calc(var(--bui_mixin_spaced_margin-inline-start--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_margin-inline-start___fTWA0[style*='--bui_mixin_margin-inline-start--m'] {
    --bui_mixin_margin-inline-start: var(--bui_mixin_margin-inline-start--m);
  }
  .Mixin-module__bui_mixin_margin-inline-start___fTWA0[style*='--bui_mixin_spaced_margin-inline-start--m'] {
    --bui_mixin_margin-inline-start: calc(var(--bui_mixin_spaced_margin-inline-start--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_margin-inline-start___fTWA0[style*='--bui_mixin_margin-inline-start--l'] {
    --bui_mixin_margin-inline-start: var(--bui_mixin_margin-inline-start--l);
  }
  .Mixin-module__bui_mixin_margin-inline-start___fTWA0[style*='--bui_mixin_spaced_margin-inline-start--l'] {
    --bui_mixin_margin-inline-start: calc(var(--bui_mixin_spaced_margin-inline-start--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_margin-inline-start___fTWA0[style*='--bui_mixin_margin-inline-start--xl'] {
    --bui_mixin_margin-inline-start: var(--bui_mixin_margin-inline-start--xl);
  }
  .Mixin-module__bui_mixin_margin-inline-start___fTWA0[style*='--bui_mixin_spaced_margin-inline-start--xl'] {
    --bui_mixin_margin-inline-start: calc(var(--bui_mixin_spaced_margin-inline-start--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_margin-inline-end___z6kbm {
  margin-inline-end: var(--bui_mixin_margin-inline-end) !important;
}
.Mixin-module__bui_mixin_margin-inline-end___z6kbm[style*='--bui_mixin_margin-inline-end--s'] {
  --bui_mixin_margin-inline-end: var(--bui_mixin_margin-inline-end--s);
}
.Mixin-module__bui_mixin_margin-inline-end___z6kbm[style*='--bui_mixin_spaced_margin-inline-end--s'] {
  --bui_mixin_margin-inline-end: calc(var(--bui_mixin_spaced_margin-inline-end--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_margin-inline-end___z6kbm[style*='--bui_mixin_margin-inline-end--m'] {
    --bui_mixin_margin-inline-end: var(--bui_mixin_margin-inline-end--m);
  }
  .Mixin-module__bui_mixin_margin-inline-end___z6kbm[style*='--bui_mixin_spaced_margin-inline-end--m'] {
    --bui_mixin_margin-inline-end: calc(var(--bui_mixin_spaced_margin-inline-end--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_margin-inline-end___z6kbm[style*='--bui_mixin_margin-inline-end--l'] {
    --bui_mixin_margin-inline-end: var(--bui_mixin_margin-inline-end--l);
  }
  .Mixin-module__bui_mixin_margin-inline-end___z6kbm[style*='--bui_mixin_spaced_margin-inline-end--l'] {
    --bui_mixin_margin-inline-end: calc(var(--bui_mixin_spaced_margin-inline-end--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_margin-inline-end___z6kbm[style*='--bui_mixin_margin-inline-end--xl'] {
    --bui_mixin_margin-inline-end: var(--bui_mixin_margin-inline-end--xl);
  }
  .Mixin-module__bui_mixin_margin-inline-end___z6kbm[style*='--bui_mixin_spaced_margin-inline-end--xl'] {
    --bui_mixin_margin-inline-end: calc(var(--bui_mixin_spaced_margin-inline-end--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_padding___BL4S1 {
  padding: var(--bui_mixin_padding) !important;
}
.Mixin-module__bui_mixin_padding___BL4S1[style*='--bui_mixin_spaced_padding--s'] {
  --bui_mixin_padding: calc(var(--bui_mixin_spaced_padding--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_padding___BL4S1[style*='--bui_mixin_spaced_padding--m'] {
    --bui_mixin_padding: calc(var(--bui_mixin_spaced_padding--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_padding___BL4S1[style*='--bui_mixin_spaced_padding--l'] {
    --bui_mixin_padding: calc(var(--bui_mixin_spaced_padding--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_padding___BL4S1[style*='--bui_mixin_spaced_padding--xl'] {
    --bui_mixin_padding: calc(var(--bui_mixin_spaced_padding--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_padding-block-start___7qQyE {
  padding-block-start: var(--bui_mixin_padding-block-start) !important;
}
.Mixin-module__bui_mixin_padding-block-start___7qQyE[style*='--bui_mixin_spaced_padding-block-start--s'] {
  --bui_mixin_padding-block-start: calc(var(--bui_mixin_spaced_padding-block-start--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_padding-block-start___7qQyE[style*='--bui_mixin_spaced_padding-block-start--m'] {
    --bui_mixin_padding-block-start: calc(var(--bui_mixin_spaced_padding-block-start--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_padding-block-start___7qQyE[style*='--bui_mixin_spaced_padding-block-start--l'] {
    --bui_mixin_padding-block-start: calc(var(--bui_mixin_spaced_padding-block-start--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_padding-block-start___7qQyE[style*='--bui_mixin_spaced_padding-block-start--xl'] {
    --bui_mixin_padding-block-start: calc(var(--bui_mixin_spaced_padding-block-start--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_padding-block-end___0BAJd {
  padding-block-end: var(--bui_mixin_padding-block-end) !important;
}
.Mixin-module__bui_mixin_padding-block-end___0BAJd[style*='--bui_mixin_spaced_padding-block-end--s'] {
  --bui_mixin_padding-block-end: calc(var(--bui_mixin_spaced_padding-block-end--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_padding-block-end___0BAJd[style*='--bui_mixin_spaced_padding-block-end--m'] {
    --bui_mixin_padding-block-end: calc(var(--bui_mixin_spaced_padding-block-end--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_padding-block-end___0BAJd[style*='--bui_mixin_spaced_padding-block-end--l'] {
    --bui_mixin_padding-block-end: calc(var(--bui_mixin_spaced_padding-block-end--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_padding-block-end___0BAJd[style*='--bui_mixin_spaced_padding-block-end--xl'] {
    --bui_mixin_padding-block-end: calc(var(--bui_mixin_spaced_padding-block-end--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_padding-inline-start___etpee {
  padding-inline-start: var(--bui_mixin_padding-inline-start) !important;
}
.Mixin-module__bui_mixin_padding-inline-start___etpee[style*='--bui_mixin_spaced_padding-inline-start--s'] {
  --bui_mixin_padding-inline-start: calc(var(--bui_mixin_spaced_padding-inline-start--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_padding-inline-start___etpee[style*='--bui_mixin_spaced_padding-inline-start--m'] {
    --bui_mixin_padding-inline-start: calc(var(--bui_mixin_spaced_padding-inline-start--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_padding-inline-start___etpee[style*='--bui_mixin_spaced_padding-inline-start--l'] {
    --bui_mixin_padding-inline-start: calc(var(--bui_mixin_spaced_padding-inline-start--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_padding-inline-start___etpee[style*='--bui_mixin_spaced_padding-inline-start--xl'] {
    --bui_mixin_padding-inline-start: calc(var(--bui_mixin_spaced_padding-inline-start--xl) * var(--bui_spacing_1x));
  }
}
.Mixin-module__bui_mixin_padding-inline-end___AR52s {
  padding-inline-end: var(--bui_mixin_padding-inline-end) !important;
}
.Mixin-module__bui_mixin_padding-inline-end___AR52s[style*='--bui_mixin_spaced_padding-inline-end--s'] {
  --bui_mixin_padding-inline-end: calc(var(--bui_mixin_spaced_padding-inline-end--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Mixin-module__bui_mixin_padding-inline-end___AR52s[style*='--bui_mixin_spaced_padding-inline-end--m'] {
    --bui_mixin_padding-inline-end: calc(var(--bui_mixin_spaced_padding-inline-end--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Mixin-module__bui_mixin_padding-inline-end___AR52s[style*='--bui_mixin_spaced_padding-inline-end--l'] {
    --bui_mixin_padding-inline-end: calc(var(--bui_mixin_spaced_padding-inline-end--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Mixin-module__bui_mixin_padding-inline-end___AR52s[style*='--bui_mixin_spaced_padding-inline-end--xl'] {
    --bui_mixin_padding-inline-end: calc(var(--bui_mixin_spaced_padding-inline-end--xl) * var(--bui_spacing_1x));
  }
}
.Icon-module__root___kmTzd {
  display: inline-block;
  fill: currentcolor;
  height: var(--bui_icon_height);
}
.Icon-module__root___kmTzd svg {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: auto;
}
[dir='rtl'] .Icon-module__root___kmTzd svg[data-rtl-flip] {
  transform: scaleX(-1);
}
.Icon-module__root--display-block___lME9D {
  display: block;
}
.Icon-module__root--size-smallest___8JBYf {
  --bui_icon_height: var(--bui_spacing_3x);
  --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 3);
}
.Icon-module__root--size-smaller___xRBgn {
  --bui_icon_height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half));
  --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 3.5);
}
.Icon-module__root--size-small___u2VRB {
  --bui_icon_height: var(--bui_spacing_4x);
  --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 4);
}
.Icon-module__root--size-medium___znUOh {
  --bui_icon_height: calc(var(--bui_spacing_1x) * 5);
  --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 5);
}
.Icon-module__root--size-large___9KJ4t {
  --bui_icon_height: var(--bui_spacing_6x);
  --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 6);
}
.Icon-module__root--size-larger___CAGVU {
  --bui_icon_height: calc(var(--bui_spacing_1x) * 7);
  --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 7);
}
.Icon-module__root--size-largest___6yGtu {
  --bui_icon_height: calc(var(--bui_spacing_1x) * 9);
  --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 9);
}
@media (min-width: 576px) {
  .Icon-module__root--size-smallest--m___oDOY0 {
    --bui_icon_height: var(--bui_spacing_3x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 3);
  }
  .Icon-module__root--size-smaller--m___m14xd {
    --bui_icon_height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half));
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 3.5);
  }
  .Icon-module__root--size-small--m___PuMjM {
    --bui_icon_height: var(--bui_spacing_4x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 4);
  }
  .Icon-module__root--size-medium--m___zoYPv {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 5);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 5);
  }
  .Icon-module__root--size-large--m___i0g5O {
    --bui_icon_height: var(--bui_spacing_6x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 6);
  }
  .Icon-module__root--size-larger--m___EMkmx {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 7);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 7);
  }
  .Icon-module__root--size-largest--m___\+6qsy {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 9);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 9);
  }
}
@media (min-width: 1024px) {
  .Icon-module__root--size-smallest--l___NbUvg {
    --bui_icon_height: var(--bui_spacing_3x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 3);
  }
  .Icon-module__root--size-smaller--l___Hyna6 {
    --bui_icon_height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half));
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 3.5);
  }
  .Icon-module__root--size-small--l___YPk1N {
    --bui_icon_height: var(--bui_spacing_4x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 4);
  }
  .Icon-module__root--size-medium--l___ci4Fe {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 5);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 5);
  }
  .Icon-module__root--size-large--l___lnc5N {
    --bui_icon_height: var(--bui_spacing_6x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 6);
  }
  .Icon-module__root--size-larger--l___Z2bt9 {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 7);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 7);
  }
  .Icon-module__root--size-largest--l___jCNn2 {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 9);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 9);
  }
}
@media (min-width: 1280px) {
  .Icon-module__root--size-smallest--xl___YVLUT {
    --bui_icon_height: var(--bui_spacing_3x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 3);
  }
  .Icon-module__root--size-smaller--xl___U5DR4 {
    --bui_icon_height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half));
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 3.5);
  }
  .Icon-module__root--size-small--xl___cgU2\+ {
    --bui_icon_height: var(--bui_spacing_4x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 4);
  }
  .Icon-module__root--size-medium--xl___wbND9 {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 5);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 5);
  }
  .Icon-module__root--size-large--xl___ccwe3 {
    --bui_icon_height: var(--bui_spacing_6x);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 6);
  }
  .Icon-module__root--size-larger--xl___dKEEZ {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 7);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 7);
  }
  .Icon-module__root--size-largest--xl___TnWPu {
    --bui_icon_height: calc(var(--bui_spacing_1x) * 9);
    --bui_icon_height_scaling: calc(var(--bui_rem_spacing_1x) * 9);
  }
}
.Icon-module__root--scale___ISdHD {
  height: var(--bui_icon_height_scaling);
}
.Icon-module__root--color-white___X-4bl {
  color: var(--bui_color_white);
  fill: var(--bui_color_white);
}
.Icon-module__root--color-action___9bSKf {
  color: var(--bui_color_action_foreground);
  fill: var(--bui_color_action_foreground);
}
.Icon-module__root--color-destructive___6k88u {
  color: var(--bui_color_destructive_foreground);
  fill: var(--bui_color_destructive_foreground);
}
.Icon-module__root--color-callout___2qDhE {
  color: var(--bui_color_callout_foreground);
  fill: var(--bui_color_callout_foreground);
}
.Icon-module__root--color-accent___nm\+wI {
  color: var(--bui_color_accent_foreground);
  fill: var(--bui_color_accent_foreground);
}
.Icon-module__root--color-constructive___fDFAx {
  color: var(--bui_color_constructive_foreground);
  fill: var(--bui_color_constructive_foreground);
}
.Icon-module__root--color-neutral___h8Yk0 {
  color: var(--bui_color_foreground);
  fill: var(--bui_color_foreground);
}
.Icon-module__root--color-neutral_alt___t1-LQ {
  color: var(--bui_color_foreground_alt);
  fill: var(--bui_color_foreground_alt);
}
.Icon-module__root--color-disabled___jZ0yg {
  color: var(--bui_color_foreground_disabled);
  fill: var(--bui_color_foreground_disabled);
}
.Icon-module__root--color-brand_genius_secondary___nT9RE {
  color: var(--bui_color_brand_genius_secondary_foreground);
  fill: var(--bui_color_brand_genius_secondary_foreground);
}
.Accordion-module__button___B-zlf {
  background: none;
  padding: 0;
  border: none;
  width: 100%;
  cursor: pointer;
  text-align: start;
  outline: none;
  display: flex;
  align-items: center;
}
.Accordion-module__label___WYoSP {
  flex-grow: 1;
}
.Accordion-module__icon___42Fvh {
  margin-inline-start: var(--bui_spacing_4x);
  flex-shrink: 0;
  transition: transform var(--bui_animation_press);
}
[data-bui-keyboard] .Accordion-module__button___B-zlf:focus .Accordion-module__icon___42Fvh {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.Accordion-module__collapser___4vpjL {
  height: 0;
  transition: height var(--bui_animation_appear);
}
.Accordion-module__button___B-zlf + .Accordion-module__collapser___4vpjL > .Accordion-module__content___8cuL8 {
  padding-block-start: var(--bui_spacing_4x);
}
.Accordion-module__root--active___QVihQ > .Accordion-module__button___B-zlf > .Accordion-module__icon___42Fvh {
  transform: rotate(180deg);
}
.Accordion-module__root--static___OwoER > .Accordion-module__button___B-zlf {
  cursor: default;
}
.Accordion-module__root--static___OwoER > .Accordion-module__button___B-zlf > .Accordion-module__icon___42Fvh {
  visibility: hidden;
}
.Actionable-module__root___-naV5 {
  display: inline-flex;
  vertical-align: top;
  text-decoration: none;
  color: inherit;
  text-align: start;
  border: none;
  padding: 0;
  background: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}
.Actionable-module__root___-naV5[role='button'],
a.Actionable-module__root___-naV5,
button.Actionable-module__root___-naV5 {
  cursor: pointer;
}
.Actionable-module__root___-naV5:focus {
  outline: none;
  z-index: 10;
}
button.Actionable-module__root___-naV5[disabled] {
  cursor: not-allowed;
}
button.Actionable-module__root___-naV5[disabled]:active {
  transform: none;
}
[data-bui-keyboard] .Actionable-module__root___-naV5:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
[data-bui-keyboard] .Actionable-module__root___-naV5.Actionable-module__focus-inset___dMB7O:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--bui_color_background_base), inset 0 0 0 4px var(--bui_color_action_border);
}
.Actionable-module__root--wide-true___Cb4-v {
  width: 100%;
}
.Actionable-module__root--wide-false___i4RXZ {
  width: auto;
}
@media (min-width: 576px) {
  .Actionable-module__root--wide-true--m___chcWz {
    width: 100%;
  }
  .Actionable-module__root--wide-false--m___qWmXl {
    width: auto;
  }
}
@media (min-width: 1024px) {
  .Actionable-module__root--wide-true--l___QH5fm {
    width: 100%;
  }
  .Actionable-module__root--wide-false--l___vf1VI {
    width: auto;
  }
}
@media (min-width: 1280px) {
  .Actionable-module__root--wide-true--xl___Fa70k {
    width: 100%;
  }
  .Actionable-module__root--wide-false--xl___h1Pjg {
    width: auto;
  }
}
@keyframes Spinner-module__bui-spinner___J141- {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(2turn);
  }
}
.Spinner-module__root___T4FYb {
  direction: ltr;
  position: relative;
  animation: Spinner-module__bui-spinner___J141- 1s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  width: var(--bui_spinner_size);
  height: var(--bui_spinner_size);
}
.Spinner-module__root___T4FYb:after,
.Spinner-module__root___T4FYb:before {
  content: '';
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  transform: translateY(-50%);
  width: var(--bui_spinner_before_size);
  height: var(--bui_spinner_before_size);
}
.Spinner-module__root___T4FYb:after {
  right: 0;
  top: 50%;
}
.Spinner-module__root___T4FYb:before {
  left: 0;
  top: 50%;
}
.Spinner-module__inner___N\+WgC:after,
.Spinner-module__inner___N\+WgC:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  border: solid transparent;
  border-width: var(--bui_spinner_inner_after_border_width);
}
.Spinner-module__inner___N\+WgC:after {
  transform: rotate(-45deg);
}
.Spinner-module__root--color-white___gFCFV:after,
.Spinner-module__root--color-white___gFCFV:before {
  background: var(--bui_color_white);
}
.Spinner-module__root--color-white___gFCFV .Spinner-module__inner___N\+WgC:before {
  border-color: var(--bui_color_white);
  opacity: 0.5;
}
.Spinner-module__root--color-white___gFCFV .Spinner-module__inner___N\+WgC:after {
  border-right-color: var(--bui_color_white);
  border-top-color: var(--bui_color_white);
}
.Spinner-module__root--color-action___ZjS\+9:after,
.Spinner-module__root--color-action___ZjS\+9:before {
  background: var(--bui_color_action_border);
}
.Spinner-module__root--color-action___ZjS\+9 .Spinner-module__inner___N\+WgC:before {
  border-color: var(--bui_color_border_alt);
}
.Spinner-module__root--color-action___ZjS\+9 .Spinner-module__inner___N\+WgC:after {
  border-right-color: var(--bui_color_action_border);
  border-top-color: var(--bui_color_action_border);
}
.Spinner-module__root--color-destructive___PVpuO:after,
.Spinner-module__root--color-destructive___PVpuO:before {
  background: var(--bui_color_destructive_border);
}
.Spinner-module__root--color-destructive___PVpuO .Spinner-module__inner___N\+WgC:before {
  border-color: var(--bui_color_border_alt);
}
.Spinner-module__root--color-destructive___PVpuO .Spinner-module__inner___N\+WgC:after {
  border-right-color: var(--bui_color_destructive_border);
  border-top-color: var(--bui_color_destructive_border);
}
.Spinner-module__root--color-inherit___QBxx0:after,
.Spinner-module__root--color-inherit___QBxx0:before {
  background: currentcolor;
}
.Spinner-module__root--color-inherit___QBxx0 .Spinner-module__inner___N\+WgC:before {
  border-color: currentcolor;
  opacity: 0.5;
}
.Spinner-module__root--color-inherit___QBxx0 .Spinner-module__inner___N\+WgC:after {
  border-right-color: currentcolor;
  border-top-color: currentcolor;
}
.Spinner-module__root--size-small___APuV9 {
  --bui_spinner_size: var(--bui_spacing_4x);
  --bui_spinner_before_size: var(--bui_spacing_half);
  --bui_spinner_inner_after_border_width: var(--bui_spacing_half);
}
.Spinner-module__root--size-medium___3PIzy {
  --bui_spinner_size: var(--bui_spacing_6x);
  --bui_spinner_before_size: calc(var(--bui_spacing_half) * 1.5);
  --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half) * 1.5);
}
.Spinner-module__root--size-large___K7a0C {
  --bui_spinner_size: var(--bui_spacing_8x);
  --bui_spinner_before_size: var(--bui_spacing_1x);
  --bui_spinner_inner_after_border_width: var(--bui_spacing_1x);
}
.Spinner-module__root--size-larger___Td2ZC {
  --bui_spinner_size: calc(var(--bui_spacing_6x) * 2);
  --bui_spinner_before_size: calc(var(--bui_spacing_half) * 3);
  --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half) * 3);
}
@media (min-width: 576px) {
  .Spinner-module__root--size-small--m___TpW8A {
    --bui_spinner_size: var(--bui_spacing_4x);
    --bui_spinner_before_size: var(--bui_spacing_half);
    --bui_spinner_inner_after_border_width: var(--bui_spacing_half);
  }
  .Spinner-module__root--size-medium--m___xvVoV {
    --bui_spinner_size: var(--bui_spacing_6x);
    --bui_spinner_before_size: calc(var(--bui_spacing_half) * 1.5);
    --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half) * 1.5);
  }
  .Spinner-module__root--size-large--m___qiae0 {
    --bui_spinner_size: var(--bui_spacing_8x);
    --bui_spinner_before_size: var(--bui_spacing_1x);
    --bui_spinner_inner_after_border_width: var(--bui_spacing_1x);
  }
  .Spinner-module__root--size-larger--m___GxTSK {
    --bui_spinner_size: calc(var(--bui_spacing_6x) * 2);
    --bui_spinner_before_size: calc(var(--bui_spacing_half) * 3);
    --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half) * 3);
  }
}
@media (min-width: 1024px) {
  .Spinner-module__root--size-small--l___PMuX3 {
    --bui_spinner_size: var(--bui_spacing_4x);
    --bui_spinner_before_size: var(--bui_spacing_half);
    --bui_spinner_inner_after_border_width: var(--bui_spacing_half);
  }
  .Spinner-module__root--size-medium--l___WC3In {
    --bui_spinner_size: var(--bui_spacing_6x);
    --bui_spinner_before_size: calc(var(--bui_spacing_half) * 1.5);
    --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half) * 1.5);
  }
  .Spinner-module__root--size-large--l___GJBSx {
    --bui_spinner_size: var(--bui_spacing_8x);
    --bui_spinner_before_size: var(--bui_spacing_1x);
    --bui_spinner_inner_after_border_width: var(--bui_spacing_1x);
  }
  .Spinner-module__root--size-larger--l___Q7PlF {
    --bui_spinner_size: calc(var(--bui_spacing_6x) * 2);
    --bui_spinner_before_size: calc(var(--bui_spacing_half) * 3);
    --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half) * 3);
  }
}
@media (min-width: 1280px) {
  .Spinner-module__root--size-small--xl___z2c\+k {
    --bui_spinner_size: var(--bui_spacing_4x);
    --bui_spinner_before_size: var(--bui_spacing_half);
    --bui_spinner_inner_after_border_width: var(--bui_spacing_half);
  }
  .Spinner-module__root--size-medium--xl___gcIDk {
    --bui_spinner_size: var(--bui_spacing_6x);
    --bui_spinner_before_size: calc(var(--bui_spacing_half) * 1.5);
    --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half) * 1.5);
  }
  .Spinner-module__root--size-large--xl___KBS\+t {
    --bui_spinner_size: var(--bui_spacing_8x);
    --bui_spinner_before_size: var(--bui_spacing_1x);
    --bui_spinner_inner_after_border_width: var(--bui_spacing_1x);
  }
  .Spinner-module__root--size-larger--xl___Gp54e {
    --bui_spinner_size: calc(var(--bui_spacing_6x) * 2);
    --bui_spinner_before_size: calc(var(--bui_spacing_half) * 3);
    --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half) * 3);
  }
}
.Button-module__root___WCvx6 {
  vertical-align: middle;
  border-radius: var(--bui_border_radius_100);
  background: transparent;
  transition: var(--bui_animation_press);
  transition-property: transform;
}
.Button-module__root___WCvx6:before {
  content: '';
  position: absolute;
  inset: 0;
  pointer-events: none;
  border: var(--bui_border_width_100) solid transparent;
  border-radius: var(--bui_border_radius_100);
  box-sizing: border-box;
  transition: var(--bui_animation_hover);
  transition-property: background-color, border-color;
}
.Button-module__root___WCvx6:active {
  transform: scale(0.98);
}
.Button-module__root___WCvx6.Button-module__root--active___AslOT,
.Button-module__root___WCvx6:active,
.Button-module__root___WCvx6:focus,
.Button-module__root___WCvx6:hover,
.Button-module__root___WCvx6:link,
.Button-module__root___WCvx6:visited {
  text-decoration: none;
}
.Button-module__icon___SF\+gR {
  position: relative;
  display: inline-flex;
  fill: currentcolor;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0 calc(var(--bui_spacing_3x) * -1);
}
.Button-module__icon--start___r7JbS {
  margin-inline-start: 0;
  margin-inline-end: var(--bui_spacing_2x);
}
.Button-module__icon--end___KUgmj {
  margin-inline-start: var(--bui_spacing_2x);
  margin-inline-end: 0;
}
.Button-module__text___0eDMC {
  position: relative;
}
.Button-module__spinner___2fhUj {
  display: none;
  position: absolute;
  inset-block-start: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Button-module__root--loading___tOcmz {
  cursor: default;
}
.Button-module__root--loading___tOcmz > .Button-module__spinner___2fhUj {
  display: block;
}
.Button-module__root--loading___tOcmz > .Button-module__icon___SF\+gR,
.Button-module__root--loading___tOcmz > .Button-module__text___0eDMC {
  visibility: hidden;
}
.Button-module__root--variant-primary___sQdsJ[disabled],
.Button-module__root--variant-primary___sQdsJ[disabled]:active,
.Button-module__root--variant-primary___sQdsJ[disabled]:hover,
.Button-module__root--variant-primary___sQdsJ[disabled][data-bui-focus] {
  color: var(--bui_color_foreground_disabled);
  cursor: not-allowed;
  text-decoration: none;
}
.Button-module__root--variant-primary___sQdsJ[disabled]:active:before,
.Button-module__root--variant-primary___sQdsJ[disabled]:before,
.Button-module__root--variant-primary___sQdsJ[disabled]:hover:before,
.Button-module__root--variant-primary___sQdsJ[disabled][data-bui-focus]:before {
  background-color: var(--bui_color_background_disabled);
  border-color: var(--bui_color_background_disabled);
}
.Button-module__root--variant-primary-action___v3fAL {
  color: var(--bui_color_on_cta_background);
}
.Button-module__root--variant-primary-action___v3fAL:before {
  background-color: var(--bui_color_cta_background);
  border-color: var(--bui_color_cta_background);
}
.Button-module__root--variant-primary-action___v3fAL.Button-module__root--active___AslOT,
.Button-module__root--variant-primary-action___v3fAL:active,
.Button-module__root--variant-primary-action___v3fAL:focus,
.Button-module__root--variant-primary-action___v3fAL:hover,
.Button-module__root--variant-primary-action___v3fAL:link,
.Button-module__root--variant-primary-action___v3fAL:visited,
.Button-module__root--variant-primary-action___v3fAL[data-bui-focus] {
  color: var(--bui_color_on_cta_background);
}
.Button-module__root--variant-primary-action___v3fAL.Button-module__root--active___AslOT:before,
.Button-module__root--variant-primary-action___v3fAL:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-primary-action___v3fAL[data-bui-focus]:not(.Button-module__root--loading___tOcmz):before {
  background-color: var(--bui_color_cta_highlighted);
  border-color: var(--bui_color_cta_highlighted);
}
@media (hover: hover) {
  .Button-module__root--variant-primary-action___v3fAL:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_cta_highlighted);
    border-color: var(--bui_color_cta_highlighted);
  }
}
.Button-module__root--variant-primary-destructive___M7B4U {
  color: var(--bui_color_on_destructive_background);
}
.Button-module__root--variant-primary-destructive___M7B4U:before {
  background-color: var(--bui_color_destructive_background);
  border-color: var(--bui_color_destructive_background);
}
.Button-module__root--variant-primary-destructive___M7B4U.Button-module__root--active___AslOT,
.Button-module__root--variant-primary-destructive___M7B4U:active,
.Button-module__root--variant-primary-destructive___M7B4U:focus,
.Button-module__root--variant-primary-destructive___M7B4U:hover,
.Button-module__root--variant-primary-destructive___M7B4U:link,
.Button-module__root--variant-primary-destructive___M7B4U:visited,
.Button-module__root--variant-primary-destructive___M7B4U[data-bui-focus] {
  color: var(--bui_color_on_destructive_background);
}
.Button-module__root--variant-primary-destructive___M7B4U.Button-module__root--active___AslOT:before,
.Button-module__root--variant-primary-destructive___M7B4U:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-primary-destructive___M7B4U[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_destructive_highlighted);
  border-color: var(--bui_color_destructive_highlighted);
}
@media (hover: hover) {
  .Button-module__root--variant-primary-destructive___M7B4U:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_destructive_highlighted);
    border-color: var(--bui_color_destructive_highlighted);
  }
}
.Button-module__root--variant-secondary___seSdP[data-bui-focus][disabled],
.Button-module__root--variant-secondary___seSdP[disabled],
.Button-module__root--variant-secondary___seSdP[disabled]:active,
.Button-module__root--variant-secondary___seSdP[disabled]:hover {
  color: var(--bui_color_foreground_disabled);
  cursor: not-allowed;
  text-decoration: none;
}
.Button-module__root--variant-secondary___seSdP[disabled]:active:before
  .Button-module__root--variant-secondary___seSdP[data-bui-focus][disabled]:before,
.Button-module__root--variant-secondary___seSdP[disabled]:before,
.Button-module__root--variant-secondary___seSdP[disabled]:hover:before {
  background-color: var(--bui_color_background_disabled_alt);
  border-color: var(--bui_color_background_disabled);
}
.Button-module__root--variant-secondary-action___ouSlf {
  background-color: var(--bui_color_background_elevation_one);
  color: var(--bui_color_action_foreground);
}
.Button-module__root--variant-secondary-action___ouSlf:before {
  background-color: transparent;
  border-color: var(--bui_color_action_border);
}
.Button-module__root--variant-secondary-action___ouSlf.Button-module__root--active___AslOT,
.Button-module__root--variant-secondary-action___ouSlf:active,
.Button-module__root--variant-secondary-action___ouSlf:focus,
.Button-module__root--variant-secondary-action___ouSlf:hover,
.Button-module__root--variant-secondary-action___ouSlf:link,
.Button-module__root--variant-secondary-action___ouSlf:visited,
.Button-module__root--variant-secondary-action___ouSlf[data-bui-focus] {
  color: var(--bui_color_action_foreground);
}
.Button-module__root--variant-secondary-action___ouSlf.Button-module__root--active___AslOT:before,
.Button-module__root--variant-secondary-action___ouSlf:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-secondary-action___ouSlf[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_action_highlighted_alt);
  border-color: var(--bui_color_action_border);
}
@media (hover: hover) {
  .Button-module__root--variant-secondary-action___ouSlf:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_action_highlighted_alt);
    border-color: var(--bui_color_action_border);
  }
}
.Button-module__root--variant-secondary-destructive___ByO6g {
  background-color: var(--bui_color_background_elevation_one);
  color: var(--bui_color_destructive_foreground);
}
.Button-module__root--variant-secondary-destructive___ByO6g:before {
  background-color: transparent;
  border-color: var(--bui_color_destructive_border);
}
.Button-module__root--variant-secondary-destructive___ByO6g.Button-module__root--active___AslOT,
.Button-module__root--variant-secondary-destructive___ByO6g:active,
.Button-module__root--variant-secondary-destructive___ByO6g:focus,
.Button-module__root--variant-secondary-destructive___ByO6g:hover,
.Button-module__root--variant-secondary-destructive___ByO6g:link,
.Button-module__root--variant-secondary-destructive___ByO6g:visited,
.Button-module__root--variant-secondary-destructive___ByO6g[data-bui-focus] {
  color: var(--bui_color_destructive_foreground);
}
.Button-module__root--variant-secondary-destructive___ByO6g.Button-module__root--active___AslOT:before,
.Button-module__root--variant-secondary-destructive___ByO6g:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-secondary-destructive___ByO6g[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_destructive_highlighted_alt);
  border-color: var(--bui_color_destructive_border);
}
@media (hover: hover) {
  .Button-module__root--variant-secondary-destructive___ByO6g:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_destructive_highlighted_alt);
    border-color: var(--bui_color_destructive_border);
  }
}
.Button-module__root--variant-secondary-neutral___Eanzu {
  background-color: transparent;
  color: var(--bui_color_foreground);
}
.Button-module__root--variant-secondary-neutral___Eanzu:before {
  background-color: transparent;
  border-color: var(--bui_color_border);
}
.Button-module__root--variant-secondary-neutral___Eanzu.Button-module__root--active___AslOT,
.Button-module__root--variant-secondary-neutral___Eanzu:active,
.Button-module__root--variant-secondary-neutral___Eanzu:focus,
.Button-module__root--variant-secondary-neutral___Eanzu:hover,
.Button-module__root--variant-secondary-neutral___Eanzu:link,
.Button-module__root--variant-secondary-neutral___Eanzu:visited,
.Button-module__root--variant-secondary-neutral___Eanzu[data-bui-focus] {
  color: var(--bui_color_foreground);
}
.Button-module__root--variant-secondary-neutral___Eanzu.Button-module__root--active___AslOT:before,
.Button-module__root--variant-secondary-neutral___Eanzu:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-secondary-neutral___Eanzu[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_highlighted_alt);
  border-color: var(--bui_color_border);
}
@media (hover: hover) {
  .Button-module__root--variant-secondary-neutral___Eanzu:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_highlighted_alt);
    border-color: var(--bui_color_border);
  }
}
.Button-module__root--variant-tertiary___0pr75 {
  --bui_button_inline_padding_extra: calc(var(--bui_spacing_1x) * -1);
}
.Button-module__root--variant-tertiary___0pr75:before {
  background-color: transparent;
  border-color: transparent;
}
.Button-module__root--variant-tertiary___0pr75[data-bui-focus][disabled],
.Button-module__root--variant-tertiary___0pr75[disabled],
.Button-module__root--variant-tertiary___0pr75[disabled]:active,
.Button-module__root--variant-tertiary___0pr75[disabled]:hover {
  color: var(--bui_color_foreground_disabled);
  cursor: not-allowed;
  text-decoration: none;
}
.Button-module__root--variant-tertiary-action___l-H7E,
.Button-module__root--variant-tertiary-action___l-H7E.Button-module__root--active___AslOT,
.Button-module__root--variant-tertiary-action___l-H7E:active,
.Button-module__root--variant-tertiary-action___l-H7E:focus,
.Button-module__root--variant-tertiary-action___l-H7E:hover,
.Button-module__root--variant-tertiary-action___l-H7E:link,
.Button-module__root--variant-tertiary-action___l-H7E:visited,
.Button-module__root--variant-tertiary-action___l-H7E[data-bui-focus] {
  color: var(--bui_color_action_foreground);
}
.Button-module__root--variant-tertiary-action___l-H7E.Button-module__root--active___AslOT:before,
.Button-module__root--variant-tertiary-action___l-H7E:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-tertiary-action___l-H7E[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_action_highlighted_alt);
  border-color: transparent;
}
@media (hover: hover) {
  .Button-module__root--variant-tertiary-action___l-H7E:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_action_highlighted_alt);
    border-color: transparent;
  }
}
.Button-module__root--variant-tertiary-destructive___egYr2,
.Button-module__root--variant-tertiary-destructive___egYr2.Button-module__root--active___AslOT,
.Button-module__root--variant-tertiary-destructive___egYr2:active,
.Button-module__root--variant-tertiary-destructive___egYr2:focus,
.Button-module__root--variant-tertiary-destructive___egYr2:hover,
.Button-module__root--variant-tertiary-destructive___egYr2:link,
.Button-module__root--variant-tertiary-destructive___egYr2:visited,
.Button-module__root--variant-tertiary-destructive___egYr2[data-bui-focus] {
  color: var(--bui_color_destructive_foreground);
}
.Button-module__root--variant-tertiary-destructive___egYr2.Button-module__root--active___AslOT:before,
.Button-module__root--variant-tertiary-destructive___egYr2:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-tertiary-destructive___egYr2[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_destructive_highlighted_alt);
  border-color: transparent;
}
@media (hover: hover) {
  .Button-module__root--variant-tertiary-destructive___egYr2:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_destructive_highlighted_alt);
    border-color: transparent;
  }
}
.Button-module__root--variant-tertiary-neutral___poaFY,
.Button-module__root--variant-tertiary-neutral___poaFY.Button-module__root--active___AslOT,
.Button-module__root--variant-tertiary-neutral___poaFY:active,
.Button-module__root--variant-tertiary-neutral___poaFY:focus,
.Button-module__root--variant-tertiary-neutral___poaFY:hover,
.Button-module__root--variant-tertiary-neutral___poaFY:link,
.Button-module__root--variant-tertiary-neutral___poaFY:visited,
.Button-module__root--variant-tertiary-neutral___poaFY[data-bui-focus] {
  color: var(--bui_color_foreground);
}
.Button-module__root--variant-tertiary-neutral___poaFY.Button-module__root--active___AslOT:before,
.Button-module__root--variant-tertiary-neutral___poaFY:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-tertiary-neutral___poaFY[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_highlighted_alt);
  border-color: transparent;
}
@media (hover: hover) {
  .Button-module__root--variant-tertiary-neutral___poaFY:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_highlighted_alt);
    border-color: transparent;
  }
}
.Button-module__root--variant-tertiary-inherit___kU3HM {
  color: currentcolor;
}
.Button-module__root--variant-tertiary-inherit___kU3HM:before {
  opacity: 0;
}
.Button-module__root--variant-tertiary-inherit___kU3HM.Button-module__root--active___AslOT,
.Button-module__root--variant-tertiary-inherit___kU3HM:active,
.Button-module__root--variant-tertiary-inherit___kU3HM:focus,
.Button-module__root--variant-tertiary-inherit___kU3HM:hover,
.Button-module__root--variant-tertiary-inherit___kU3HM:link,
.Button-module__root--variant-tertiary-inherit___kU3HM:visited,
.Button-module__root--variant-tertiary-inherit___kU3HM[data-bui-focus] {
  color: currentcolor;
}
.Button-module__root--variant-tertiary-inherit___kU3HM.Button-module__root--active___AslOT:before,
.Button-module__root--variant-tertiary-inherit___kU3HM:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-tertiary-inherit___kU3HM[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: currentcolor;
  opacity: 0.1;
  border-color: transparent;
}
@media (hover: hover) {
  .Button-module__root--variant-tertiary-inherit___kU3HM:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: currentcolor;
    opacity: 0.1;
    border-color: transparent;
  }
}
.Button-module__root--variant-white___M\+aLB[disabled]:active:before,
.Button-module__root--variant-white___M\+aLB[disabled]:before,
.Button-module__root--variant-white___M\+aLB[disabled]:hover:before,
.Button-module__root--variant-white___M\+aLB[disabled][data-bui-focus]:before {
  background-color: var(--bui_color_white);
}
.Button-module__root--variant-white___M\+aLB[data-bui-focus][disabled],
.Button-module__root--variant-white___M\+aLB[disabled],
.Button-module__root--variant-white___M\+aLB[disabled]:active,
.Button-module__root--variant-white___M\+aLB[disabled]:hover {
  color: var(--bui_color_foreground_disabled);
  cursor: not-allowed;
  text-decoration: none;
}
.Button-module__root--variant-white-action___nxLTT {
  background-color: var(--bui_color_white);
  color: var(--bui_color_black);
}
.Button-module__root--variant-white-action___nxLTT:before {
  border-color: transparent;
}
.Button-module__root--variant-white-action___nxLTT.Button-module__root--active___AslOT,
.Button-module__root--variant-white-action___nxLTT:active,
.Button-module__root--variant-white-action___nxLTT:focus,
.Button-module__root--variant-white-action___nxLTT:hover,
.Button-module__root--variant-white-action___nxLTT:link,
.Button-module__root--variant-white-action___nxLTT:visited,
.Button-module__root--variant-white-action___nxLTT[data-bui-focus] {
  color: var(--bui_color_black);
}
.Button-module__root--variant-white-action___nxLTT.Button-module__root--active___AslOT:before,
.Button-module__root--variant-white-action___nxLTT:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-white-action___nxLTT[data-bui-focus]:not(.Button-module__root--loading___tOcmz):before {
  background-color: rgba(0, 0, 0, 0.12);
}
@media (hover: hover) {
  .Button-module__root--variant-white-action___nxLTT:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
.Button-module__root--variant-elevated___caBN4[disabled],
.Button-module__root--variant-elevated___caBN4[disabled]:active,
.Button-module__root--variant-elevated___caBN4[disabled]:hover,
.Button-module__root--variant-elevated___caBN4[disabled][data-bui-focus] {
  color: var(--bui_color_foreground_disabled);
  cursor: not-allowed;
  text-decoration: none;
  box-shadow: var(--bui_shadow_100);
}
.Button-module__root--variant-elevated___caBN4[disabled]:active:before,
.Button-module__root--variant-elevated___caBN4[disabled]:before,
.Button-module__root--variant-elevated___caBN4[disabled]:hover:before,
.Button-module__root--variant-elevated___caBN4[disabled][data-bui-focus]:before {
  background-color: var(--bui_color_background_elevation_two);
  border-color: transparent;
}
.Button-module__root--icon-only___wZCZy.Button-module__root--variant-elevated___caBN4,
.Button-module__root--icon-only___wZCZy.Button-module__root--variant-elevated___caBN4:before {
  border-radius: 50%;
}
.Button-module__root--variant-elevated-action___umKgL {
  background-color: var(--bui_color_background_elevation_two);
  color: var(--bui_color_foreground);
  box-shadow: var(--bui_shadow_100);
}
.Button-module__root--variant-elevated-action___umKgL:before {
  border-color: transparent;
}
.Button-module__root--variant-elevated-action___umKgL.Button-module__root--active___AslOT,
.Button-module__root--variant-elevated-action___umKgL:active,
.Button-module__root--variant-elevated-action___umKgL:focus,
.Button-module__root--variant-elevated-action___umKgL:hover,
.Button-module__root--variant-elevated-action___umKgL:link,
.Button-module__root--variant-elevated-action___umKgL:visited,
.Button-module__root--variant-elevated-action___umKgL[data-bui-focus] {
  color: var(--bui_color_foreground);
}
.Button-module__root--variant-elevated-action___umKgL.Button-module__root--active___AslOT:before,
.Button-module__root--variant-elevated-action___umKgL:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-elevated-action___umKgL[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_highlighted_alt);
}
@media (hover: hover) {
  .Button-module__root--variant-elevated-action___umKgL:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_highlighted_alt);
  }
}
.Button-module__root--variant-elevated-destructive___eM7Oi {
  background-color: var(--bui_color_background_elevation_two);
  box-shadow: var(--bui_shadow_100);
  color: var(--bui_color_destructive_foreground);
}
.Button-module__root--variant-elevated-destructive___eM7Oi.Button-module__root--active___AslOT,
.Button-module__root--variant-elevated-destructive___eM7Oi:active,
.Button-module__root--variant-elevated-destructive___eM7Oi:focus,
.Button-module__root--variant-elevated-destructive___eM7Oi:hover,
.Button-module__root--variant-elevated-destructive___eM7Oi:link,
.Button-module__root--variant-elevated-destructive___eM7Oi:visited,
.Button-module__root--variant-elevated-destructive___eM7Oi[data-bui-focus] {
  color: var(--bui_color_destructive_foreground);
}
.Button-module__root--variant-elevated-destructive___eM7Oi.Button-module__root--active___AslOT:before,
.Button-module__root--variant-elevated-destructive___eM7Oi:not(.Button-module__root--loading___tOcmz):active:before,
.Button-module__root--variant-elevated-destructive___eM7Oi[data-bui-focus]:not(
    .Button-module__root--loading___tOcmz
  ):before {
  background-color: var(--bui_color_destructive_highlighted_alt);
  border-color: transparent;
}
@media (hover: hover) {
  .Button-module__root--variant-elevated-destructive___eM7Oi:not(.Button-module__root--loading___tOcmz):hover:before {
    background-color: var(--bui_color_destructive_highlighted_alt);
    border-color: transparent;
  }
}
.Button-module__root--rounded___jzhSr,
.Button-module__root--rounded___jzhSr:before {
  border-radius: 999px;
}
.Button-module__root--size-medium___\+S1hf {
  padding: var(--bui_spacing_1x) calc(var(--bui_spacing_3x) + var(--bui_button_inline_padding_extra, 0px));
  margin-block-start: var(--bui_button_medium_margin_block_start, initial);
  margin-block-end: var(--bui_button_medium_margin_block_end, initial);
  margin-inline-start: var(--bui_button_medium_margin_inline_start, initial);
  margin-inline-end: var(--bui_button_medium_margin_inline_end, initial);
  min-height: calc(var(--bui_spacing_1x) * 9);
  min-width: calc(var(--bui_spacing_1x) * 9);
  font-size: var(--bui_font_emphasized_2_font-size);
  font-weight: var(--bui_font_emphasized_2_font-weight);
  line-height: var(--bui_font_emphasized_2_line-height);
  font-family: var(--bui_font_emphasized_2_font-family);
}
.Button-module__root--size-large___Sp\+g4 {
  padding: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) + var(--bui_button_inline_padding_extra, 0px));
  margin-block-start: var(--bui_button_large_margin_block_start, initial);
  margin-block-end: var(--bui_button_large_margin_block_end, initial);
  margin-inline-start: var(--bui_button_large_margin_inline_start, initial);
  margin-inline-end: var(--bui_button_large_margin_inline_end, initial);
  min-height: calc(var(--bui_spacing_1x) * 12);
  min-width: calc(var(--bui_spacing_1x) * 12);
  font-size: var(--bui_font_emphasized_1_font-size);
  font-weight: var(--bui_font_emphasized_1_font-weight);
  line-height: var(--bui_font_emphasized_1_line-height);
  font-family: var(--bui_font_emphasized_1_font-family);
}
@media (min-width: 576px) {
  .Button-module__root--size-medium--m___YcYUx {
    padding: var(--bui_spacing_1x) calc(var(--bui_spacing_3x) + var(--bui_button_inline_padding_extra, 0px));
    margin-block-start: var(--bui_button_medium_margin_block_start, initial);
    margin-block-end: var(--bui_button_medium_margin_block_end, initial);
    margin-inline-start: var(--bui_button_medium_margin_inline_start, initial);
    margin-inline-end: var(--bui_button_medium_margin_inline_end, initial);
    min-height: calc(var(--bui_spacing_1x) * 9);
    min-width: calc(var(--bui_spacing_1x) * 9);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height);
    font-family: var(--bui_font_emphasized_2_font-family);
  }
  .Button-module__root--size-large--m___8fTzT {
    padding: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) + var(--bui_button_inline_padding_extra, 0px));
    margin-block-start: var(--bui_button_large_margin_block_start, initial);
    margin-block-end: var(--bui_button_large_margin_block_end, initial);
    margin-inline-start: var(--bui_button_large_margin_inline_start, initial);
    margin-inline-end: var(--bui_button_large_margin_inline_end, initial);
    min-height: calc(var(--bui_spacing_1x) * 12);
    min-width: calc(var(--bui_spacing_1x) * 12);
    font-size: var(--bui_font_emphasized_1_font-size);
    font-weight: var(--bui_font_emphasized_1_font-weight);
    line-height: var(--bui_font_emphasized_1_line-height);
    font-family: var(--bui_font_emphasized_1_font-family);
  }
}
@media (min-width: 1024px) {
  .Button-module__root--size-medium--l___IpBnv {
    padding: var(--bui_spacing_1x) calc(var(--bui_spacing_3x) + var(--bui_button_inline_padding_extra, 0px));
    margin-block-start: var(--bui_button_medium_margin_block_start, initial);
    margin-block-end: var(--bui_button_medium_margin_block_end, initial);
    margin-inline-start: var(--bui_button_medium_margin_inline_start, initial);
    margin-inline-end: var(--bui_button_medium_margin_inline_end, initial);
    min-height: calc(var(--bui_spacing_1x) * 9);
    min-width: calc(var(--bui_spacing_1x) * 9);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height);
    font-family: var(--bui_font_emphasized_2_font-family);
  }
  .Button-module__root--size-large--l___i-PmG {
    padding: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) + var(--bui_button_inline_padding_extra, 0px));
    margin-block-start: var(--bui_button_large_margin_block_start, initial);
    margin-block-end: var(--bui_button_large_margin_block_end, initial);
    margin-inline-start: var(--bui_button_large_margin_inline_start, initial);
    margin-inline-end: var(--bui_button_large_margin_inline_end, initial);
    min-height: calc(var(--bui_spacing_1x) * 12);
    min-width: calc(var(--bui_spacing_1x) * 12);
    font-size: var(--bui_font_emphasized_1_font-size);
    font-weight: var(--bui_font_emphasized_1_font-weight);
    line-height: var(--bui_font_emphasized_1_line-height);
    font-family: var(--bui_font_emphasized_1_font-family);
  }
}
@media (min-width: 1280px) {
  .Button-module__root--size-medium--xl___okxeo {
    padding: var(--bui_spacing_1x) calc(var(--bui_spacing_3x) + var(--bui_button_inline_padding_extra, 0px));
    margin-block-start: var(--bui_button_medium_margin_block_start, initial);
    margin-block-end: var(--bui_button_medium_margin_block_end, initial);
    margin-inline-start: var(--bui_button_medium_margin_inline_start, initial);
    margin-inline-end: var(--bui_button_medium_margin_inline_end, initial);
    min-height: calc(var(--bui_spacing_1x) * 9);
    min-width: calc(var(--bui_spacing_1x) * 9);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height);
    font-family: var(--bui_font_emphasized_2_font-family);
  }
  .Button-module__root--size-large--xl___F0Mp7 {
    padding: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) + var(--bui_button_inline_padding_extra, 0px));
    margin-block-start: var(--bui_button_large_margin_block_start, initial);
    margin-block-end: var(--bui_button_large_margin_block_end, initial);
    margin-inline-start: var(--bui_button_large_margin_inline_start, initial);
    margin-inline-end: var(--bui_button_large_margin_inline_end, initial);
    min-height: calc(var(--bui_spacing_1x) * 12);
    min-width: calc(var(--bui_spacing_1x) * 12);
    font-size: var(--bui_font_emphasized_1_font-size);
    font-weight: var(--bui_font_emphasized_1_font-weight);
    line-height: var(--bui_font_emphasized_1_line-height);
    font-family: var(--bui_font_emphasized_1_font-family);
  }
}
.Button-module__aligner___kd5A9 {
  display: inline-block;
  vertical-align: middle;
}
.Button-module__root--alignment-top___3cF6F .Button-module__root--variant-tertiary-neutral___poaFY,
.Button-module__root--alignment-top___3cF6F .Button-module__root--variant-tertiary___0pr75 {
  --bui_button_medium_margin_block_start: calc(var(--bui_spacing_2x) * -1);
  --bui_button_large_margin_block_start: calc(var(--bui_spacing_3x) * -1);
}
.Button-module__root--alignment-bottom___pmlK4 .Button-module__root--variant-tertiary-neutral___poaFY,
.Button-module__root--alignment-bottom___pmlK4 .Button-module__root--variant-tertiary___0pr75 {
  --bui_button_medium_margin_block_end: calc(var(--bui_spacing_2x) * -1);
  --bui_button_large_margin_block_end: calc(var(--bui_spacing_3x) * -1);
}
.Button-module__root--alignment-start___eMpcF .Button-module__root--variant-tertiary-neutral___poaFY,
.Button-module__root--alignment-start___eMpcF .Button-module__root--variant-tertiary___0pr75 {
  --bui_button_medium_margin_inline_start: calc(var(--bui_spacing_2x) * -1);
  --bui_button_large_margin_inline_start: calc(var(--bui_spacing_3x) * -1);
}
.Button-module__root--alignment-end___cPboL .Button-module__root--variant-tertiary-neutral___poaFY,
.Button-module__root--alignment-end___cPboL .Button-module__root--variant-tertiary___0pr75 {
  --bui_button_medium_margin_inline_end: calc(var(--bui_spacing_2x) * -1);
  --bui_button_large_margin_inline_end: calc(var(--bui_spacing_3x) * -1);
}
.Divider-module__root___tDifK {
  margin: 0;
  border: 0;
  background: var(--bui_color_border_alt);
}
.Divider-module__root--vertical-false___ZXc14 {
  height: var(--bui_border_width_100);
  width: auto;
}
.Divider-module__root--vertical-true___upoIa {
  height: 100%;
  width: var(--bui_border_width_100);
}
@media (min-width: 576px) {
  .Divider-module__root--vertical-false--m___Yn8H7 {
    height: var(--bui_border_width_100);
    width: auto;
  }
  .Divider-module__root--vertical-true--m___Yv1u1 {
    height: 100%;
    width: var(--bui_border_width_100);
  }
}
@media (min-width: 1024px) {
  .Divider-module__root--vertical-false--l___hraR8 {
    height: var(--bui_border_width_100);
    width: auto;
  }
  .Divider-module__root--vertical-true--l___kes2j {
    height: 100%;
    width: var(--bui_border_width_100);
  }
}
@media (min-width: 1280px) {
  .Divider-module__root--vertical-false--xl___bTO1f {
    height: var(--bui_border_width_100);
    width: auto;
  }
  .Divider-module__root--vertical-true--xl___6y\+7g {
    height: 100%;
    width: var(--bui_border_width_100);
  }
}
.Stack-module__root___0gte2 {
  --bui_stack_height: initial;
  display: flex;
  padding: 0;
  list-style-type: none;
  height: var(--bui_stack_height, initial);
  margin: 0;
}
.Stack-module__root___0gte2 > * {
  flex-shrink: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
.Stack-module__root--direction-column___YKFXk {
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
}
.Stack-module__root--direction-column-reverse___D2L\+O {
  flex-wrap: nowrap;
  flex-direction: column-reverse;
  align-items: stretch;
}
.Stack-module__root--direction-row___PLZW3 {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.Stack-module__root--direction-row-reverse___n4qz5 {
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (min-width: 576px) {
  .Stack-module__root--direction-column--m___XGgWw {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
  }
  .Stack-module__root--direction-column-reverse--m___NfVpo {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    align-items: stretch;
  }
  .Stack-module__root--direction-row--m___i4EOY {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .Stack-module__root--direction-row-reverse--m___n5fnn {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
@media (min-width: 1024px) {
  .Stack-module__root--direction-column--l___WlzCW {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
  }
  .Stack-module__root--direction-column-reverse--l___8-VgC {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    align-items: stretch;
  }
  .Stack-module__root--direction-row--l___G-B5n {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .Stack-module__root--direction-row-reverse--l___hgpnH {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
@media (min-width: 1280px) {
  .Stack-module__root--direction-column--xl___k2wvT {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
  }
  .Stack-module__root--direction-column-reverse--xl___rOMJx {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    align-items: stretch;
  }
  .Stack-module__root--direction-row--xl___zgsPP {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .Stack-module__root--direction-row-reverse--xl___61OJO {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.Stack-module__root--align-items-start___1ABti {
  align-items: flex-start;
}
.Stack-module__root--align-items-end___u467k {
  align-items: flex-end;
}
.Stack-module__root--align-items-center___HU57i {
  align-items: center;
}
.Stack-module__root--align-items-stretch___7cpBp {
  align-items: stretch;
}
.Stack-module__root--align-items-baseline___idWiH {
  align-items: baseline;
}
@media (min-width: 576px) {
  .Stack-module__root--align-items-start--m___eTxux {
    align-items: flex-start;
  }
  .Stack-module__root--align-items-end--m___hfW71 {
    align-items: flex-end;
  }
  .Stack-module__root--align-items-center--m___CGK17 {
    align-items: center;
  }
  .Stack-module__root--align-items-stretch--m___9mUGs {
    align-items: stretch;
  }
  .Stack-module__root--align-items-baseline--m___mpPzr {
    align-items: baseline;
  }
}
@media (min-width: 1024px) {
  .Stack-module__root--align-items-start--l___\+0r6h {
    align-items: flex-start;
  }
  .Stack-module__root--align-items-end--l___HXJ3w {
    align-items: flex-end;
  }
  .Stack-module__root--align-items-center--l___pmXrk {
    align-items: center;
  }
  .Stack-module__root--align-items-stretch--l___MpBy5 {
    align-items: stretch;
  }
  .Stack-module__root--align-items-baseline--l___E28sj {
    align-items: baseline;
  }
}
@media (min-width: 1280px) {
  .Stack-module__root--align-items-start--xl___Wm6S\+ {
    align-items: flex-start;
  }
  .Stack-module__root--align-items-end--xl___NfnUg {
    align-items: flex-end;
  }
  .Stack-module__root--align-items-center--xl___muLGN {
    align-items: center;
  }
  .Stack-module__root--align-items-stretch--xl___fUkag {
    align-items: stretch;
  }
  .Stack-module__root--align-items-baseline--xl___JzQr2 {
    align-items: baseline;
  }
}
.Stack-module__root--justify-content-start___2WH8E {
  justify-content: flex-start;
}
.Stack-module__root--justify-content-center___B9H7p {
  justify-content: center;
}
.Stack-module__root--justify-content-end___O16SB {
  justify-content: flex-end;
}
.Stack-module__root--justify-content-space-between___-M1Bt {
  justify-content: space-between;
}
@media (min-width: 576px) {
  .Stack-module__root--justify-content-start--m___imQ07 {
    justify-content: flex-start;
  }
  .Stack-module__root--justify-content-center--m___Fw63B {
    justify-content: center;
  }
  .Stack-module__root--justify-content-end--m___fXkCK {
    justify-content: flex-end;
  }
  .Stack-module__root--justify-content-space-between--m___wgDgm {
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .Stack-module__root--justify-content-start--l___0P67p {
    justify-content: flex-start;
  }
  .Stack-module__root--justify-content-center--l___94GIF {
    justify-content: center;
  }
  .Stack-module__root--justify-content-end--l___gpnBZ {
    justify-content: flex-end;
  }
  .Stack-module__root--justify-content-space-between--l___EKHT3 {
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .Stack-module__root--justify-content-start--xl___nuPzw {
    justify-content: flex-start;
  }
  .Stack-module__root--justify-content-center--xl___ZNaG8 {
    justify-content: center;
  }
  .Stack-module__root--justify-content-end--xl___VeOH2 {
    justify-content: flex-end;
  }
  .Stack-module__root--justify-content-space-between--xl___DLPDy {
    justify-content: space-between;
  }
}
.Stack-module__root___0gte2 {
  gap: var(--bui_stack_gap);
}
.Stack-module__root___0gte2[style*='--bui_stack_spaced_gap--s'] {
  --bui_stack_gap: calc(var(--bui_stack_spaced_gap--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Stack-module__root___0gte2[style*='--bui_stack_spaced_gap--m'] {
    --bui_stack_gap: calc(var(--bui_stack_spaced_gap--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Stack-module__root___0gte2[style*='--bui_stack_spaced_gap--l'] {
    --bui_stack_gap: calc(var(--bui_stack_spaced_gap--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Stack-module__root___0gte2[style*='--bui_stack_spaced_gap--xl'] {
    --bui_stack_gap: calc(var(--bui_stack_spaced_gap--xl) * var(--bui_spacing_1x));
  }
}
.Stack-module__root--direction-column___YKFXk > .Stack-module__item--split___ynkcz {
  margin-block: auto 0;
  margin-inline: 0;
}
.Stack-module__root--direction-column-reverse___D2L\+O > .Stack-module__item--split___ynkcz {
  margin-block: 0 auto;
  margin-inline: 0;
}
.Stack-module__root--direction-___lwmik > .Stack-module__item--split___ynkcz,
.Stack-module__root--direction-row___PLZW3 > .Stack-module__item--split___ynkcz {
  margin-block: 0;
  margin-inline: auto 0;
}
.Stack-module__root--direction-row-reverse___n4qz5 > .Stack-module__item--split___ynkcz {
  margin-block: 0;
  margin-inline: 0 auto;
}
@media (min-width: 576px) {
  .Stack-module__root--direction-column--m___XGgWw > .Stack-module__item--split___ynkcz {
    margin-block: auto 0;
    margin-inline: 0;
  }
  .Stack-module__root--direction-column-reverse--m___NfVpo > .Stack-module__item--split___ynkcz {
    margin-block: 0 auto;
    margin-inline: 0;
  }
  .Stack-module__root--direction---m___syeQl > .Stack-module__item--split___ynkcz,
  .Stack-module__root--direction-row--m___i4EOY > .Stack-module__item--split___ynkcz {
    margin-block: 0;
    margin-inline: auto 0;
  }
  .Stack-module__root--direction-row-reverse--m___n5fnn > .Stack-module__item--split___ynkcz {
    margin-block: 0;
    margin-inline: 0 auto;
  }
}
@media (min-width: 1024px) {
  .Stack-module__root--direction-column--l___WlzCW > .Stack-module__item--split___ynkcz {
    margin-block: auto 0;
    margin-inline: 0;
  }
  .Stack-module__root--direction-column-reverse--l___8-VgC > .Stack-module__item--split___ynkcz {
    margin-block: 0 auto;
    margin-inline: 0;
  }
  .Stack-module__root--direction---l___tpb\+y > .Stack-module__item--split___ynkcz,
  .Stack-module__root--direction-row--l___G-B5n > .Stack-module__item--split___ynkcz {
    margin-block: 0;
    margin-inline: auto 0;
  }
  .Stack-module__root--direction-row-reverse--l___hgpnH > .Stack-module__item--split___ynkcz {
    margin-block: 0;
    margin-inline: 0 auto;
  }
}
@media (min-width: 1280px) {
  .Stack-module__root--direction-column--xl___k2wvT > .Stack-module__item--split___ynkcz {
    margin-block: auto 0;
    margin-inline: 0;
  }
  .Stack-module__root--direction-column-reverse--xl___rOMJx > .Stack-module__item--split___ynkcz {
    margin-block: 0 auto;
    margin-inline: 0;
  }
  .Stack-module__root--direction---xl___B\+\+vh > .Stack-module__item--split___ynkcz,
  .Stack-module__root--direction-row--xl___zgsPP > .Stack-module__item--split___ynkcz {
    margin-block: 0;
    margin-inline: auto 0;
  }
  .Stack-module__root--direction-row-reverse--xl___61OJO > .Stack-module__item--split___ynkcz {
    margin-block: 0;
    margin-inline: 0 auto;
  }
}
.Stack-module__root--direction-column-reverse___D2L\+O:only-child,
.Stack-module__root--direction-column___YKFXk:only-child {
  --bui_stack_height: 100%;
}
@media (min-width: 576px) {
  .Stack-module__root--direction-column--m___XGgWw:only-child,
  .Stack-module__root--direction-column-reverse--m___NfVpo:only-child {
    --bui_stack_height: 100%;
  }
}
@media (min-width: 1024px) {
  .Stack-module__root--direction-column--l___WlzCW:only-child,
  .Stack-module__root--direction-column-reverse--l___8-VgC:only-child {
    --bui_stack_height: 100%;
  }
}
@media (min-width: 1280px) {
  .Stack-module__root--direction-column--xl___k2wvT:only-child,
  .Stack-module__root--direction-column-reverse--xl___rOMJx:only-child {
    --bui_stack_height: 100%;
  }
}
.Stack-module__root--nowrap___c806o,
.Stack-module__root--wrap-nowrap___0DJ\+R {
  flex-wrap: nowrap;
}
.Stack-module__root--wrap-wrap___BzBf2 {
  flex-wrap: wrap;
}
.Stack-module__root--wrap-wrap-reverse___bgh45 {
  flex-wrap: wrap-reverse;
}
@media (min-width: 576px) {
  .Stack-module__root--wrap-nowrap--m___hxcV7 {
    flex-wrap: nowrap;
  }
  .Stack-module__root--wrap-wrap--m___M8kbM {
    flex-wrap: wrap;
  }
  .Stack-module__root--wrap-wrap-reverse--m___g8JRl {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 1024px) {
  .Stack-module__root--wrap-nowrap--l___JxZ3D {
    flex-wrap: nowrap;
  }
  .Stack-module__root--wrap-wrap--l___lLgZO {
    flex-wrap: wrap;
  }
  .Stack-module__root--wrap-wrap-reverse--l___GhONa {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 1280px) {
  .Stack-module__root--wrap-nowrap--xl___LNcOM {
    flex-wrap: nowrap;
  }
  .Stack-module__root--wrap-wrap--xl___1H1ZI {
    flex-wrap: wrap;
  }
  .Stack-module__root--wrap-wrap-reverse--xl___itDuZ {
    flex-wrap: wrap-reverse;
  }
}
.Stack-module__root--grow-true___9KrRm {
  flex-grow: 1;
}
.Stack-module__root--grow-false___iDQIq {
  flex-grow: 0;
}
@media (min-width: 576px) {
  .Stack-module__root--grow-true--m___gbMZO {
    flex-grow: 1;
  }
  .Stack-module__root--grow-false--m___JrqKJ {
    flex-grow: 0;
  }
}
@media (min-width: 1024px) {
  .Stack-module__root--grow-true--l___7ouIx {
    flex-grow: 1;
  }
  .Stack-module__root--grow-false--l___MTWsf {
    flex-grow: 0;
  }
}
@media (min-width: 1280px) {
  .Stack-module__root--grow-true--xl___QGBlq {
    flex-grow: 1;
  }
  .Stack-module__root--grow-false--xl___5Ln5d {
    flex-grow: 0;
  }
}
.Stack-module__root--align-self-start___twY83 {
  align-self: flex-start;
}
.Stack-module__root--align-self-center___EiRHZ {
  align-self: center;
}
.Stack-module__root--align-self-end___jDJ2c {
  align-self: flex-end;
}
@media (min-width: 576px) {
  .Stack-module__root--align-self-start--m___PfSIO {
    align-self: flex-start;
  }
  .Stack-module__root--align-self-center--m___Mg7eS {
    align-self: center;
  }
  .Stack-module__root--align-self-end--m___a8NPk {
    align-self: flex-end;
  }
}
@media (min-width: 1024px) {
  .Stack-module__root--align-self-start--l___Gaycs {
    align-self: flex-start;
  }
  .Stack-module__root--align-self-center--l___rKwTy {
    align-self: center;
  }
  .Stack-module__root--align-self-end--l___xinoj {
    align-self: flex-end;
  }
}
@media (min-width: 1280px) {
  .Stack-module__root--align-self-start--xl___5JcoL {
    align-self: flex-start;
  }
  .Stack-module__root--align-self-center--xl___5L28h {
    align-self: center;
  }
  .Stack-module__root--align-self-end--xl___d8vLO {
    align-self: flex-end;
  }
}
.Stack-module__item--grow___nSxf9 {
  flex-basis: 100%;
  flex-grow: 1;
}
.Stack-module__item--shrink___T3V4Q {
  flex-shrink: 1;
}
.Stack-module__item--align-self-start___J17J6 {
  align-self: flex-start;
}
.Stack-module__item--align-self-center___HgXOD {
  align-self: center;
}
.Stack-module__item--align-self-end___GnDOt {
  align-self: flex-end;
}
.Stack-module__divider___HmaXd {
  align-self: stretch;
}
.ActionBar-module__root___ztB-q {
  margin-block-start: calc(var(--bui_spacing_2x) * -1);
  padding-block-start: var(--bui_spacing_2x);
  overflow: hidden;
}
.ActionBar-module__container___6ctVz {
  position: relative;
  border-block-start: var(--bui_action_bar_container_border_block_start, initial);
  background: var(--bui_action_bar_container_background, initial);
  padding-block-start: var(--bui_action_bar_container_padding, initial);
  padding-block-end: var(--bui_action_bar_container_padding, initial);
}
.ActionBar-module__container___6ctVz:before {
  content: var(--bui_action_bar_container_before_content, none);
  position: absolute;
  inset: 0;
  box-shadow: var(--bui_shadow_100);
  transform: rotate(180deg);
}
.ActionBar-module__content___JtCQS,
.ActionBar-module__top-content___esLgq:not(.ActionBar-module__top-content--fill___QvJud) {
  padding-inline-start: var(--bui_action_bar_container_padding, initial);
  padding-inline-end: var(--bui_action_bar_container_padding, initial);
}
.ActionBar-module__container--size-medium___tjzdV {
  --bui_action_bar_container_padding: var(--bui_spacing_4x);
}
.ActionBar-module__container--size-large___gnCz\+ {
  --bui_action_bar_container_padding: var(--bui_spacing_6x);
}
@media (min-width: 576px) {
  .ActionBar-module__container--size-medium--m___J-SKI {
    --bui_action_bar_container_padding: var(--bui_spacing_4x);
  }
  .ActionBar-module__container--size-large--m___HrUy\+ {
    --bui_action_bar_container_padding: var(--bui_spacing_6x);
  }
}
@media (min-width: 1024px) {
  .ActionBar-module__container--size-medium--l___cb4k2 {
    --bui_action_bar_container_padding: var(--bui_spacing_4x);
  }
  .ActionBar-module__container--size-large--l___gIPH4 {
    --bui_action_bar_container_padding: var(--bui_spacing_6x);
  }
}
@media (min-width: 1280px) {
  .ActionBar-module__container--size-medium--xl___CVqZI {
    --bui_action_bar_container_padding: var(--bui_spacing_4x);
  }
  .ActionBar-module__container--size-large--xl___yjCVN {
    --bui_action_bar_container_padding: var(--bui_spacing_6x);
  }
}
.ActionBar-module__container--elevated-true___WQFaV {
  --bui_action_bar_container_background: var(--bui_color_background_elevation_two);
  --bui_action_bar_container_border_block_start: 0px;
  --bui_action_bar_container_before_content: '';
}
.ActionBar-module__container--elevated-false___gHWKx {
  --bui_action_bar_container_background: var(--bui_color_background_elevation_one);
  --bui_action_bar_container_border_block_start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
  --bui_action_bar_container_before_content: none;
}
@media (min-width: 576px) {
  .ActionBar-module__container--elevated-true--m___PDdFX {
    --bui_action_bar_container_background: var(--bui_color_background_elevation_two);
    --bui_action_bar_container_border_block_start: 0px;
    --bui_action_bar_container_before_content: '';
  }
  .ActionBar-module__container--elevated-false--m___nMtWZ {
    --bui_action_bar_container_background: var(--bui_color_background_elevation_one);
    --bui_action_bar_container_border_block_start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
    --bui_action_bar_container_before_content: none;
  }
}
@media (min-width: 1024px) {
  .ActionBar-module__container--elevated-true--l___szmcI {
    --bui_action_bar_container_background: var(--bui_color_background_elevation_two);
    --bui_action_bar_container_border_block_start: 0px;
    --bui_action_bar_container_before_content: '';
  }
  .ActionBar-module__container--elevated-false--l___czxWz {
    --bui_action_bar_container_background: var(--bui_color_background_elevation_one);
    --bui_action_bar_container_border_block_start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
    --bui_action_bar_container_before_content: none;
  }
}
@media (min-width: 1280px) {
  .ActionBar-module__container--elevated-true--xl___QsRpI {
    --bui_action_bar_container_background: var(--bui_color_background_elevation_two);
    --bui_action_bar_container_border_block_start: 0px;
    --bui_action_bar_container_before_content: '';
  }
  .ActionBar-module__container--elevated-false--xl___5ThS6 {
    --bui_action_bar_container_background: var(--bui_color_background_elevation_one);
    --bui_action_bar_container_border_block_start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
    --bui_action_bar_container_before_content: none;
  }
}
@media (max-width: 575px) {
  .BUIProvider-module__bleed--true___thqmy {
    margin-inline: calc(var(--bui_spacing_4x) * -1) !important;
  }
  .BUIProvider-module__bleed--0___5k\+-l,
  .BUIProvider-module__bleed--true___thqmy {
    border-radius: 0 !important;
    border-inline: 0 !important;
  }
}
.Text-module__root--color-neutral___HZOUz {
  color: var(--bui_color_foreground);
}
.Text-module__root--color-neutral_alt___yjrqF {
  color: var(--bui_color_foreground_alt);
}
.Text-module__root--color-action___7srmo {
  color: var(--bui_color_action_foreground);
}
.Text-module__root--color-constructive___Z\+u4c {
  color: var(--bui_color_constructive_foreground);
}
.Text-module__root--color-destructive___gtBy- {
  color: var(--bui_color_destructive_foreground);
}
.Text-module__root--color-accent___PikUB {
  color: var(--bui_color_accent_foreground);
}
.Text-module__root--color-callout___7WRsM {
  color: var(--bui_color_callout_foreground);
}
.Text-module__root--color-white___evN1V {
  color: var(--bui_color_white);
}
.Text-module__root--color-disabled___7eqVf {
  color: var(--bui_color_foreground_disabled);
}
.Text-module__root--color-brand_primary___NPDqz {
  color: var(--bui_color_brand_primary_foreground);
}
.Text-module__root--color-brand_genius_secondary___7Yyhr {
  color: var(--bui_color_brand_genius_secondary_foreground);
}
.Text-module__root--color-inherit___0zcul {
  color: currentcolor;
}
.Text-module__root--variant-display_1___QtvX1 {
  font-size: var(--bui_font_display_1_font-size);
  font-weight: var(--bui_font_display_1_font-weight);
  line-height: var(--bui_font_display_1_line-height);
  font-family: var(--bui_font_display_1_font-family);
}
.Text-module__root--variant-display_2___mTKNi {
  font-size: var(--bui_font_display_2_font-size);
  font-weight: var(--bui_font_display_2_font-weight);
  line-height: var(--bui_font_display_2_line-height);
  font-family: var(--bui_font_display_2_font-family);
}
.Text-module__root--variant-display_3___LK6lN {
  font-size: var(--bui_font_display_3_font-size);
  font-weight: var(--bui_font_display_3_font-weight);
  line-height: var(--bui_font_display_3_line-height);
  font-family: var(--bui_font_display_3_font-family);
}
.Text-module__root--variant-featured_1___t5\+5l {
  font-size: var(--bui_font_featured_1_font-size);
  font-weight: var(--bui_font_featured_1_font-weight);
  line-height: var(--bui_font_featured_1_line-height);
  font-family: var(--bui_font_featured_1_font-family);
}
.Text-module__root--variant-featured_2___fqlmw {
  font-size: var(--bui_font_featured_2_font-size);
  font-weight: var(--bui_font_featured_2_font-weight);
  line-height: var(--bui_font_featured_2_line-height);
  font-family: var(--bui_font_featured_2_font-family);
}
.Text-module__root--variant-featured_3___daWGv {
  font-size: var(--bui_font_featured_3_font-size);
  font-weight: var(--bui_font_featured_3_font-weight);
  line-height: var(--bui_font_featured_3_line-height);
  font-family: var(--bui_font_featured_3_font-family);
}
.Text-module__root--variant-headline_1___vJeVN {
  font-size: var(--bui_font_headline_1_font-size);
  font-weight: var(--bui_font_headline_1_font-weight);
  line-height: var(--bui_font_headline_1_line-height);
  font-family: var(--bui_font_headline_1_font-family);
}
.Text-module__root--variant-headline_2___2mfZw {
  font-size: var(--bui_font_headline_2_font-size);
  font-weight: var(--bui_font_headline_2_font-weight);
  line-height: var(--bui_font_headline_2_line-height);
  font-family: var(--bui_font_headline_2_font-family);
}
.Text-module__root--variant-headline_3___XUMUM {
  font-size: var(--bui_font_headline_3_font-size);
  font-weight: var(--bui_font_headline_3_font-weight);
  line-height: var(--bui_font_headline_3_line-height);
  font-family: var(--bui_font_headline_3_font-family);
}
.Text-module__root--variant-strong_1___V-mvO {
  font-size: var(--bui_font_strong_1_font-size);
  font-weight: var(--bui_font_strong_1_font-weight);
  line-height: var(--bui_font_strong_1_line-height);
  font-family: var(--bui_font_strong_1_font-family);
}
.Text-module__root--variant-strong_2___Kr4N5 {
  font-size: var(--bui_font_strong_2_font-size);
  font-weight: var(--bui_font_strong_2_font-weight);
  line-height: var(--bui_font_strong_2_line-height);
  font-family: var(--bui_font_strong_2_font-family);
}
.Text-module__root--variant-emphasized_1___dZPl- {
  font-size: var(--bui_font_emphasized_1_font-size);
  font-weight: var(--bui_font_emphasized_1_font-weight);
  line-height: var(--bui_font_emphasized_1_line-height);
  font-family: var(--bui_font_emphasized_1_font-family);
}
.Text-module__root--variant-emphasized_2___ETg9v {
  font-size: var(--bui_font_emphasized_2_font-size);
  font-weight: var(--bui_font_emphasized_2_font-weight);
  line-height: var(--bui_font_emphasized_2_line-height);
  font-family: var(--bui_font_emphasized_2_font-family);
}
.Text-module__root--variant-body_1___sY1al {
  font-size: var(--bui_font_body_1_font-size);
  font-weight: var(--bui_font_body_1_font-weight);
  line-height: var(--bui_font_body_1_line-height);
  font-family: var(--bui_font_body_1_font-family);
}
.Text-module__root--variant-body_2___NVJeQ {
  font-size: var(--bui_font_body_2_font-size);
  font-weight: var(--bui_font_body_2_font-weight);
  line-height: var(--bui_font_body_2_line-height);
  font-family: var(--bui_font_body_2_font-family);
}
.Text-module__root--variant-small_1___6Ehhs {
  font-size: var(--bui_font_small_1_font-size);
  font-weight: var(--bui_font_small_1_font-weight);
  line-height: var(--bui_font_small_1_line-height);
  font-family: var(--bui_font_small_1_font-family);
}
.Text-module__root--variant-small_2___VzgGR {
  font-size: var(--bui_font_small_2_font-size);
  font-weight: var(--bui_font_small_2_font-weight);
  line-height: var(--bui_font_small_2_line-height);
  font-family: var(--bui_font_small_2_font-family);
}
.Text-module__root--text-align-left___qtBG1 {
  text-align: start;
}
.Text-module__root--text-align-center___1lk9b {
  text-align: center;
}
.Text-module__root--text-align-right___upuqf {
  text-align: end;
}
.Text-module__root--text-decoration-underline___FBrPy {
  text-decoration: underline;
}
.Text-module__root--text-decoration-underline-dotted___T2Nqd {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.Text-module__root--text-decoration-line-through___IXuf\+ {
  text-decoration: line-through;
}
.Alert-module__root___TTPdE {
  border-radius: var(--bui_border_radius_200);
}
.Alert-module__title___EhcUG {
  display: var(--bui_alert_title_display);
  margin-block-end: var(--bui_spacing_2x);
}
.Alert-module__text___orpo4 {
  margin-block-start: 0;
}
.Alert-module__icon___0LICL {
  height: var(--bui_alert_icon_height);
  display: inline-flex;
  align-items: center;
}
.Alert-module__icon--no-title___Aa4GI {
  --bui_alert_icon_height: var(--bui_font_body_2_line-height);
}
.Alert-module__root--variant-success___hmedI {
  --bui_alert_background: var(--bui_color_constructive_background_alt);
  --bui_alert_border: 1px solid var(--bui_color_constructive_border);
  --bui_alert_color: var(--bui_color_constructive_foreground);
}
.Alert-module__root--variant-error___4I\+sH {
  --bui_alert_background: var(--bui_color_destructive_background_alt);
  --bui_alert_border: 1px solid var(--bui_color_destructive_border);
  --bui_alert_color: var(--bui_color_destructive_foreground);
}
.Alert-module__root--variant-primary___684FQ {
  --bui_alert_background: var(--bui_color_action_background_alt);
  --bui_alert_border: 1px solid var(--bui_color_action_border);
  --bui_alert_color: var(--bui_color_foreground);
}
.Alert-module__root--inline-true___G45La {
  --bui_alert_title_display: none;
  --bui_alert_icon_height: var(--bui_font_body_2_line-height);
  background-color: transparent;
  color: var(--bui_alert_color);
  border: none;
  padding: 0;
}
.Alert-module__root--inline-false___lVV4T {
  --bui_alert_title_display: block;
  --bui_alert_icon_height: var(--bui_font_strong_1_line-height);
  background-color: var(--bui_alert_background);
  color: var(--bui_color_foreground);
  border: var(--bui_alert_border);
  padding: var(--bui_spacing_4x);
}
@media (min-width: 576px) {
  .Alert-module__root--inline-true--m___HgZUN {
    --bui_alert_title_display: none;
    --bui_alert_icon_height: var(--bui_font_body_2_line-height);
    background-color: transparent;
    color: var(--bui_alert_color);
    border: none;
    padding: 0;
  }
  .Alert-module__root--inline-false--m___vxuNc {
    --bui_alert_title_display: block;
    --bui_alert_icon_height: var(--bui_font_strong_1_line-height);
    background-color: var(--bui_alert_background);
    color: var(--bui_color_foreground);
    border: var(--bui_alert_border);
    padding: var(--bui_spacing_4x);
  }
}
@media (min-width: 1024px) {
  .Alert-module__root--inline-true--l___eV02e {
    --bui_alert_title_display: none;
    --bui_alert_icon_height: var(--bui_font_body_2_line-height);
    background-color: transparent;
    color: var(--bui_alert_color);
    border: none;
    padding: 0;
  }
  .Alert-module__root--inline-false--l___zfxOz {
    --bui_alert_title_display: block;
    --bui_alert_icon_height: var(--bui_font_strong_1_line-height);
    background-color: var(--bui_alert_background);
    color: var(--bui_color_foreground);
    border: var(--bui_alert_border);
    padding: var(--bui_spacing_4x);
  }
}
@media (min-width: 1280px) {
  .Alert-module__root--inline-true--xl___iGLMR {
    --bui_alert_title_display: none;
    --bui_alert_icon_height: var(--bui_font_body_2_line-height);
    background-color: transparent;
    color: var(--bui_alert_color);
    border: none;
    padding: 0;
  }
  .Alert-module__root--inline-false--xl___-hDSe {
    --bui_alert_title_display: block;
    --bui_alert_icon_height: var(--bui_font_strong_1_line-height);
    background-color: var(--bui_alert_background);
    color: var(--bui_color_foreground);
    border: var(--bui_alert_border);
    padding: var(--bui_spacing_4x);
  }
}
.AspectRatio-module__root___w4jDx {
  max-width: 100%;
}
.AspectRatio-module__wrapper___AqDoQ {
  position: relative;
}
.AspectRatio-module__inner___LRYlQ {
  position: absolute;
  inset: 0;
}
.AspectRatio-module__inner___LRYlQ > img,
.AspectRatio-module__inner___LRYlQ > picture,
.AspectRatio-module__inner___LRYlQ > picture img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.AspectRatio-module__root___w4jDx {
  width: var(--bui_aspect_ratio_width);
}
.AspectRatio-module__root___w4jDx[style*='--bui_aspect_ratio_width--s'] {
  --bui_aspect_ratio_width: var(--bui_aspect_ratio_width--s);
}
.AspectRatio-module__root___w4jDx[style*='--bui_aspect_ratio_spaced_width--s'] {
  --bui_aspect_ratio_width: calc(var(--bui_aspect_ratio_spaced_width--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .AspectRatio-module__root___w4jDx[style*='--bui_aspect_ratio_width--m'] {
    --bui_aspect_ratio_width: var(--bui_aspect_ratio_width--m);
  }
  .AspectRatio-module__root___w4jDx[style*='--bui_aspect_ratio_spaced_width--m'] {
    --bui_aspect_ratio_width: calc(var(--bui_aspect_ratio_spaced_width--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .AspectRatio-module__root___w4jDx[style*='--bui_aspect_ratio_width--l'] {
    --bui_aspect_ratio_width: var(--bui_aspect_ratio_width--l);
  }
  .AspectRatio-module__root___w4jDx[style*='--bui_aspect_ratio_spaced_width--l'] {
    --bui_aspect_ratio_width: calc(var(--bui_aspect_ratio_spaced_width--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .AspectRatio-module__root___w4jDx[style*='--bui_aspect_ratio_width--xl'] {
    --bui_aspect_ratio_width: var(--bui_aspect_ratio_width--xl);
  }
  .AspectRatio-module__root___w4jDx[style*='--bui_aspect_ratio_spaced_width--xl'] {
    --bui_aspect_ratio_width: calc(var(--bui_aspect_ratio_spaced_width--xl) * var(--bui_spacing_1x));
  }
}
.AspectRatio-module__wrapper___AqDoQ {
  padding-top: var(--bui_aspect_ratio_padding-top);
}
.AspectRatio-module__wrapper___AqDoQ[style*='--bui_aspect_ratio_padding-top--s'] {
  --bui_aspect_ratio_padding-top: var(--bui_aspect_ratio_padding-top--s);
}
@media (min-width: 576px) {
  .AspectRatio-module__wrapper___AqDoQ[style*='--bui_aspect_ratio_padding-top--m'] {
    --bui_aspect_ratio_padding-top: var(--bui_aspect_ratio_padding-top--m);
  }
}
@media (min-width: 1024px) {
  .AspectRatio-module__wrapper___AqDoQ[style*='--bui_aspect_ratio_padding-top--l'] {
    --bui_aspect_ratio_padding-top: var(--bui_aspect_ratio_padding-top--l);
  }
}
@media (min-width: 1280px) {
  .AspectRatio-module__wrapper___AqDoQ[style*='--bui_aspect_ratio_padding-top--xl'] {
    --bui_aspect_ratio_padding-top: var(--bui_aspect_ratio_padding-top--xl);
  }
}
.DismissibleContainer-module__root___IQO6K {
  position: relative;
  padding-inline-end: calc(var(--bui_spacing_1x) * 9);
}
.DismissibleContainer-module__close___3B3p\+ {
  position: absolute;
  inset-block-start: var(--bui_spacing_half);
  inset-inline-end: 0;
  z-index: 5;
}
.DismissibleContainer-module__root--fill___sj0ib .DismissibleContainer-module__close___3B3p\+ {
  inset-block-start: var(--bui_spacing_4x);
  inset-inline-end: var(--bui_spacing_4x);
}
.DismissibleContainer-module__root--fill___sj0ib,
.DismissibleContainer-module__root--hide-close___H\+Yo0 {
  padding-inline-end: 0;
}
.Flyout-module__root___ks3pa {
  display: inline-block;
  vertical-align: middle;
}
.Flyout-module__root--display-block___gWKV- {
  display: block;
}
.Flyout-module__content___VW1-2 {
  z-index: var(--bui_z_index_4);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: var(--bui_animation_disappear);
  transition-property: opacity, transform, visibility;
}
.Flyout-module__content___VW1-2 .Flyout-module__inner___lYw8V {
  pointer-events: all;
  display: inline-block;
  vertical-align: top;
}
.Flyout-module__content___VW1-2.Flyout-module__content--position-top-end___KsiUa,
.Flyout-module__content___VW1-2.Flyout-module__content--position-top-start___mITRY,
.Flyout-module__content___VW1-2.Flyout-module__content--position-top___W53aR {
  transform: translateY(calc(var(--bui_spacing_4x) * -1));
}
.Flyout-module__content___VW1-2.Flyout-module__content--position-top-end___KsiUa.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-top-start___mITRY.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-top___W53aR.Flyout-module__content--visible___1igkC {
  transform: translateY(calc(var(--bui_spacing_2x) * -1));
}
.Flyout-module__content___VW1-2.Flyout-module__content--position-bottom-end___TIMMy,
.Flyout-module__content___VW1-2.Flyout-module__content--position-bottom-start___0al3A,
.Flyout-module__content___VW1-2.Flyout-module__content--position-bottom-stretch___pycWA,
.Flyout-module__content___VW1-2.Flyout-module__content--position-bottom___MPzIC {
  transform: translateY(var(--bui_spacing_4x));
}
.Flyout-module__content___VW1-2.Flyout-module__content--position-bottom-end___TIMMy.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-bottom-start___0al3A.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-bottom-stretch___pycWA.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-bottom___MPzIC.Flyout-module__content--visible___1igkC {
  transform: translateY(var(--bui_spacing_2x));
}
.Flyout-module__content___VW1-2.Flyout-module__content--position-start-bottom___WThhJ,
.Flyout-module__content___VW1-2.Flyout-module__content--position-start-top___XyKtD,
.Flyout-module__content___VW1-2.Flyout-module__content--position-start___05rpy {
  transform: translate(calc(var(--bui_spacing_4x) * -1));
}
.Flyout-module__content___VW1-2.Flyout-module__content--position-start-bottom___WThhJ.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-start-top___XyKtD.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-start___05rpy.Flyout-module__content--visible___1igkC {
  transform: translate(calc(var(--bui_spacing_2x) * -1));
}
.Flyout-module__content___VW1-2.Flyout-module__content--position-end-bottom___bV2zZ,
.Flyout-module__content___VW1-2.Flyout-module__content--position-end-top___MYKeY,
.Flyout-module__content___VW1-2.Flyout-module__content--position-end___oihDJ {
  transform: translate(var(--bui_spacing_4x));
}
.Flyout-module__content___VW1-2.Flyout-module__content--position-end-bottom___bV2zZ.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-end-top___MYKeY.Flyout-module__content--visible___1igkC,
.Flyout-module__content___VW1-2.Flyout-module__content--position-end___oihDJ.Flyout-module__content--visible___1igkC {
  transform: translate(var(--bui_spacing_2x));
}
.Flyout-module__content___VW1-2.Flyout-module__content--visible___1igkC {
  opacity: 1;
  visibility: visible;
  transition-timing-function: var(--bui_animation_appear_timing_function);
  transition-duration: var(--bui_animation_appear_duration);
}
.Flyout-module__content___VW1-2.Flyout-module__content--follow___d6aEt .Flyout-module__inner___lYw8V {
  transition-property: opacity;
  transform: translate(0);
}
.Flyout-module__content___VW1-2.Flyout-module__content--disable-animation___YEOxd {
  transition: none;
}
.Flyout-module__arrow___QirFk > span:first-child,
.Flyout-module__arrow___QirFk > span:last-child {
  width: var(--bui-flyout-arrow-size);
  height: var(--bui-flyout-arrow-size);
  position: absolute;
}
.Flyout-module__arrow___QirFk > span:first-child {
  background: var(--bui-flyout-arrow-background);
}
.Flyout-module__arrow___QirFk > span:last-child {
  box-shadow: var(--bui-flyout-arrow-shadow);
  z-index: -1;
}
.Flyout-module__arrow--position-top___JcxUz > span:first-child,
.Flyout-module__arrow--position-top___JcxUz > span:last-child {
  top: 100%;
  left: 50%;
  right: auto;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform-origin: center;
}
.Flyout-module__arrow--position-bottom-stretch___-Qa7N > span:first-child,
.Flyout-module__arrow--position-bottom-stretch___-Qa7N > span:last-child,
.Flyout-module__arrow--position-bottom___rIAw6 > span:first-child,
.Flyout-module__arrow--position-bottom___rIAw6 > span:last-child {
  top: 0;
  left: 50%;
  right: auto;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform-origin: center;
}
.Flyout-module__arrow--position-start___rS6ub > span:first-child,
.Flyout-module__arrow--position-start___rS6ub > span:last-child {
  top: 50%;
  left: 100%;
  right: auto;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform-origin: center;
}
.Flyout-module__arrow--position-end___YCyo1 > span:first-child,
.Flyout-module__arrow--position-end___YCyo1 > span:last-child {
  top: 50%;
  left: 0;
  right: auto;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform-origin: center;
}
.Flyout-module__arrow--position-top-start___IZzgz > span:first-child,
.Flyout-module__arrow--position-top-start___IZzgz > span:last-child {
  top: 100%;
  left: var(--bui-flyout-arrow-offset);
  right: auto;
  transform: rotate(-45deg);
  transform-origin: top left;
}
.Flyout-module__arrow--position-top-end___8Lzng > span:first-child,
.Flyout-module__arrow--position-top-end___8Lzng > span:last-child {
  top: 100%;
  left: auto;
  right: var(--bui-flyout-arrow-offset);
  transform: rotate(45deg);
  transform-origin: top right;
}
.Flyout-module__arrow--position-bottom-start___OlBPe > span:first-child,
.Flyout-module__arrow--position-bottom-start___OlBPe > span:last-child {
  top: 0;
  left: var(--bui-flyout-arrow-offset);
  right: auto;
  transform: rotate(-45deg);
  transform-origin: top left;
}
.Flyout-module__arrow--position-bottom-end___dUuDV > span:first-child,
.Flyout-module__arrow--position-bottom-end___dUuDV > span:last-child {
  top: 0;
  left: auto;
  right: var(--bui-flyout-arrow-offset);
  transform: rotate(45deg);
  transform-origin: top right;
}
.Flyout-module__arrow--position-start-top___nj0tp > span:first-child,
.Flyout-module__arrow--position-start-top___nj0tp > span:last-child {
  top: var(--bui-flyout-arrow-offset);
  left: 100%;
  right: auto;
  transform: rotate(45deg);
  transform-origin: top left;
}
.Flyout-module__arrow--position-start-bottom___xev35 > span:first-child,
.Flyout-module__arrow--position-start-bottom___xev35 > span:last-child {
  bottom: var(--bui-flyout-arrow-offset);
  left: 100%;
  right: auto;
  transform: rotate(-45deg);
  transform-origin: bottom left;
}
.Flyout-module__arrow--position-end-top___StgPl > span:first-child,
.Flyout-module__arrow--position-end-top___StgPl > span:last-child {
  top: var(--bui-flyout-arrow-offset);
  left: 0;
  right: auto;
  transform: rotate(45deg);
  transform-origin: top left;
}
.Flyout-module__arrow--position-end-bottom___OA3Zb > span:first-child,
.Flyout-module__arrow--position-end-bottom___OA3Zb > span:last-child {
  left: 0;
  right: auto;
  bottom: var(--bui-flyout-arrow-offset);
  transform: rotate(-45deg);
  transform-origin: bottom left;
}
.Popover-module__root___gopMa {
  border-radius: var(--bui_border_radius_200);
  background: var(--bui_color_background_elevation_two);
  color: var(--bui_color_foreground);
  box-shadow: var(--bui_shadow_100);
  padding: var(--bui_spacing_4x);
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  width: var(--bui-popover-width);
}
.Popover-module__heading___\+KVOO {
  padding-inline-end: calc(var(--bui_spacing_1x) * 9);
}
.Popover-module__arrow--hide___6QA8h {
  display: none;
}
@media (max-width: 575px) {
  .Popover-module__arrow___Yl0KB {
    display: none;
  }
}
@media (max-width: 575px) {
  .Popover-module__root--size-auto___TBHGv,
  .Popover-module__root--size-medium___jzAH\+ {
    max-width: 100%;
  }
}
.Popover-module__root--fill___bF3Zn {
  padding: 0;
}
.ListItem-module__root___6QrwX {
  display: block;
  width: 100%;
  border-radius: 0;
  color: var(--bui_color_foreground);
  text-decoration: none;
  box-sizing: border-box;
  cursor: default;
  font-size: var(--bui_font_body_2_font-size);
  font-weight: var(--bui_font_body_2_font-weight);
  line-height: var(--bui_font_body_2_line-height);
  font-family: var(--bui_font_body_2_font-family);
  transform: none;
  transition: var(--bui_animation_hover);
  transition-property: background-color;
}
.ListItem-module__content___5lPD5 {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ListItem-module__root--interactive___zvPrc:hover,
.ListItem-module__root--interactive___zvPrc[data-bui-focus] {
  background-color: var(--bui_color_highlighted_alt);
  cursor: pointer;
}
.ListItem-module__root--interactive___zvPrc.ListItem-module__root--active___FKWlB,
.ListItem-module__root--interactive___zvPrc.ListItem-module__root--active___FKWlB:focus,
.ListItem-module__root--interactive___zvPrc.ListItem-module__root--active___FKWlB:hover,
.ListItem-module__root--interactive___zvPrc.ListItem-module__root-active___OsWZ3[data-bui-focus] {
  background: var(--bui_color_action_highlighted_alt);
  color: var(--bui_color_action_foreground);
}
.ListItem-module__root--interactive___zvPrc[disabled],
.ListItem-module__root--interactive___zvPrc[disabled]:hover
  .ListItem-module__root--interactive___zvPrc[disabled][data-bui-focus] {
  color: var(--bui_color_foreground_disabled);
  background: none;
  cursor: not-allowed;
}
.ListItem-module__root--roundedCorners___WFenh {
  border-radius: var(--bui_border_radius_100);
}
.ListItem-module__root--spacing-small___E7MDt.ListItem-module__root--edge-spacing___2DVQB,
.ListItem-module__root--spacing-small___E7MDt.ListItem-module__root--interactive___zvPrc {
  padding: var(--bui_spacing_3x);
}
.ListItem-module__root--spacing-medium___UG9QP.ListItem-module__root--edge-spacing___2DVQB,
.ListItem-module__root--spacing-medium___UG9QP.ListItem-module__root--interactive___zvPrc {
  padding: var(--bui_spacing_3x) var(--bui_spacing_4x);
}
.ListItem-module__root--spacing-large___Wg9Ys.ListItem-module__root--edge-spacing___2DVQB,
.ListItem-module__root--spacing-large___Wg9Ys.ListItem-module__root--interactive___zvPrc {
  padding: var(--bui_spacing_4x) var(--bui_spacing_6x);
}
.Image-module__root___nWwis {
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
}
.Image-module__image___Wr9\+L {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.Image-module__image--fill-width___QyPFE {
  width: 100%;
}
.Image-module__image--fill-height___cOA3n {
  height: 100%;
}
.Image-module__root___nWwis {
  width: var(--bui_image_width);
}
.Image-module__root___nWwis[style*='--bui_image_width--s'] {
  --bui_image_width: var(--bui_image_width--s);
}
.Image-module__root___nWwis[style*='--bui_image_spaced_width--s'] {
  --bui_image_width: calc(var(--bui_image_spaced_width--s) * var(--bui_spacing_1x));
}
.Image-module__root___nWwis {
  height: var(--bui_image_height);
}
.Image-module__root___nWwis[style*='--bui_image_height--s'] {
  --bui_image_height: var(--bui_image_height--s);
}
.Image-module__root___nWwis[style*='--bui_image_spaced_height--s'] {
  --bui_image_height: calc(var(--bui_image_spaced_height--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Image-module__root___nWwis[style*='--bui_image_width--m'] {
    --bui_image_width: var(--bui_image_width--m);
  }
  .Image-module__root___nWwis[style*='--bui_image_spaced_width--m'] {
    --bui_image_width: calc(var(--bui_image_spaced_width--m) * var(--bui_spacing_1x));
  }
  .Image-module__root___nWwis[style*='--bui_image_height--m'] {
    --bui_image_height: var(--bui_image_height--m);
  }
  .Image-module__root___nWwis[style*='--bui_image_spaced_height--m'] {
    --bui_image_height: calc(var(--bui_image_spaced_height--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Image-module__root___nWwis[style*='--bui_image_width--l'] {
    --bui_image_width: var(--bui_image_width--l);
  }
  .Image-module__root___nWwis[style*='--bui_image_spaced_width--l'] {
    --bui_image_width: calc(var(--bui_image_spaced_width--l) * var(--bui_spacing_1x));
  }
  .Image-module__root___nWwis[style*='--bui_image_height--l'] {
    --bui_image_height: var(--bui_image_height--l);
  }
  .Image-module__root___nWwis[style*='--bui_image_spaced_height--l'] {
    --bui_image_height: calc(var(--bui_image_spaced_height--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Image-module__root___nWwis[style*='--bui_image_width--xl'] {
    --bui_image_width: var(--bui_image_width--xl);
  }
  .Image-module__root___nWwis[style*='--bui_image_spaced_width--xl'] {
    --bui_image_width: calc(var(--bui_image_spaced_width--xl) * var(--bui_spacing_1x));
  }
  .Image-module__root___nWwis[style*='--bui_image_height--xl'] {
    --bui_image_height: var(--bui_image_height--xl);
  }
  .Image-module__root___nWwis[style*='--bui_image_spaced_height--xl'] {
    --bui_image_height: calc(var(--bui_image_spaced_height--xl) * var(--bui_spacing_1x));
  }
}
.Image-module__image--content-mode-fill___poA67 {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}
.Image-module__image--content-mode-fit___JRWdd {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}
.Image-module__image--error___hSr6X {
  visibility: hidden;
}
.Image-module__root--border-radius-100___EXtW8 {
  border-radius: var(--bui_border_radius_100);
}
.Image-module__root--border-radius-200___SMl0g {
  border-radius: var(--bui_border_radius_200);
}
.Image-module__root--border-radius-300___fOPjt {
  border-radius: var(--bui_border_radius_300);
}
.Image-module__fallback___5Zepr {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--bui_color_background_alt);
  padding: var(--bui_spacing_6x);
}
.Image-module__fallback-image-padding--none___WsNTx {
  padding: 0;
}
.Image-module__fallback-image-padding--small___tbfRW {
  padding: var(--bui_spacing_2x);
}
.Image-module__fallback-image-padding--large___QAUmf {
  padding: var(--bui_spacing_6x);
}
.Box-module__root___f9GwN {
  box-sizing: border-box;
}
[data-bui-keyboard] .Box-module__root___f9GwN:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.Box-module__root--overflow-hidden___nfTOM {
  overflow: hidden;
}
.Box-module__root--overflow-auto___0yw6I {
  overflow: auto;
}
.Box-module__root___f9GwN {
  padding: var(--bui_box_padding);
}
.Box-module__root___f9GwN[style*='--bui_box_spaced_padding--s'] {
  --bui_box_padding: calc(var(--bui_box_spaced_padding--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .Box-module__root___f9GwN[style*='--bui_box_spaced_padding--m'] {
    --bui_box_padding: calc(var(--bui_box_spaced_padding--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .Box-module__root___f9GwN[style*='--bui_box_spaced_padding--l'] {
    --bui_box_padding: calc(var(--bui_box_spaced_padding--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .Box-module__root___f9GwN[style*='--bui_box_spaced_padding--xl'] {
    --bui_box_padding: calc(var(--bui_box_spaced_padding--xl) * var(--bui_spacing_1x));
  }
}
.Box-module__root--border-width-100___-a-Xo {
  border: var(--bui_border_width_100) solid;
}
.Box-module__root--border-radius-100___Ey2ns {
  border-radius: var(--bui_border_radius_100);
}
.Box-module__root--border-radius-200___mku8X {
  border-radius: var(--bui_border_radius_200);
}
.Box-module__root--border-radius-300___rouZ- {
  border-radius: var(--bui_border_radius_300);
}
.Box-module__root--border-radius-circle___UnwEi {
  border-radius: 9999px;
}
.Box-module__root--background-color-destructive_alt___B5W9Q {
  background-color: var(--bui_color_destructive_background_alt);
}
.Box-module__root--background-color-constructive_alt___Rcest {
  background-color: var(--bui_color_constructive_background_alt);
}
.Box-module__root--background-color-callout_alt___GuIED {
  background-color: var(--bui_color_callout_background_alt);
}
.Box-module__root--background-color-accent_alt___7J2AT {
  background-color: var(--bui_color_accent_background_alt);
}
.Box-module__root--background-color-action_alt___Zz0Qv {
  background-color: var(--bui_color_action_background_alt);
}
.Box-module__root--background-color-neutral_alt___HcFC6 {
  background-color: var(--bui_color_background_alt);
}
.Box-module__root--background-color-elevation_one___dvETN {
  background-color: var(--bui_color_background_elevation_one);
}
.Box-module__root--background-color-elevation_two___q2uBl {
  background-color: var(--bui_color_background_elevation_two);
}
.Box-module__root--background-color-brand_primary___tr\+TP {
  color: var(--bui_color_on_brand_primary_background);
  background-color: var(--bui_color_brand_primary_background);
}
.Box-module__root--background-color-brand_primary_dynamic___efMaC {
  color: var(--bui_color_on_brand_primary_background_dynamic);
  background-color: var(--bui_color_brand_primary_background_dynamic);
}
.Box-module__root--border-color-action___IZz1p {
  border-color: var(--bui_color_action_border);
}
.Box-module__root--border-color-destructive___UqYSP {
  border-color: var(--bui_color_destructive_border);
}
.Box-module__root--border-color-constructive___0u\+LJ {
  border-color: var(--bui_color_constructive_border);
}
.Box-module__root--border-color-accent___9P4EZ {
  border-color: var(--bui_color_accent_border);
}
.Box-module__root--border-color-callout___EyJzM {
  border-color: var(--bui_color_callout_border);
}
.Box-module__root--border-color-neutral_alt___75LPJ {
  border-color: var(--bui_color_border_alt);
}
.Box-module__root--border-color-neutral___Cyc-k {
  border-color: var(--bui_color_border);
}
.Avatar-module__root___WFoYm {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bui_color_background);
  color: var(--bui_color_on_background);
  text-transform: uppercase;
  box-sizing: border-box;
}
.Avatar-module__imageWrapper___q1A0s {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: var(--bui_color_background_alt);
}
.Avatar-module__image___0SsNN {
  width: 100%;
  height: 100%;
}
.Avatar-module__root--size-small___qFzy8 {
  height: var(--bui_spacing_6x);
  width: var(--bui_spacing_6x);
  font-size: var(--bui_font_small_1_font-size);
  font-weight: var(--bui_font_small_1_font-weight);
  line-height: var(--bui_font_small_1_line-height);
  font-family: var(--bui_font_small_1_font-family);
}
.Avatar-module__root--size-medium___qSsbJ {
  height: var(--bui_spacing_8x);
  width: var(--bui_spacing_8x);
  font-size: var(--bui_font_strong_2_font-size);
  font-weight: var(--bui_font_strong_2_font-weight);
  line-height: var(--bui_font_strong_2_line-height);
  font-family: var(--bui_font_strong_2_font-family);
}
.Avatar-module__root--size-large___b\+vVs {
  height: calc(var(--bui_spacing_1x) * 12);
  width: calc(var(--bui_spacing_1x) * 12);
  font-size: var(--bui_font_headline_3_font-size);
  font-weight: var(--bui_font_headline_3_font-weight);
  line-height: var(--bui_font_headline_3_line-height);
  font-family: var(--bui_font_headline_3_font-family);
}
.Avatar-module__root--size-larger___0gbT5 {
  height: calc(var(--bui_spacing_1x) * 16);
  width: calc(var(--bui_spacing_1x) * 16);
  font-size: var(--bui_font_headline_2_font-size);
  font-weight: var(--bui_font_headline_2_font-weight);
  line-height: var(--bui_font_headline_2_line-height);
  font-family: var(--bui_font_headline_2_font-family);
}
.Avatar-module__root--size-largest___A79FH {
  height: calc(var(--bui_spacing_1x) * 32);
  width: calc(var(--bui_spacing_1x) * 32);
  font-size: var(--bui_font_headline_1_font-size);
  font-weight: var(--bui_font_headline_1_font-weight);
  line-height: var(--bui_font_headline_1_line-height);
  font-family: var(--bui_font_headline_1_font-family);
}
@media (min-width: 576px) {
  .Avatar-module__root--size-small--m___mw4ak {
    height: var(--bui_spacing_6x);
    width: var(--bui_spacing_6x);
    font-size: var(--bui_font_small_1_font-size);
    font-weight: var(--bui_font_small_1_font-weight);
    line-height: var(--bui_font_small_1_line-height);
    font-family: var(--bui_font_small_1_font-family);
  }
  .Avatar-module__root--size-medium--m___zZBvz {
    height: var(--bui_spacing_8x);
    width: var(--bui_spacing_8x);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height);
    font-family: var(--bui_font_strong_2_font-family);
  }
  .Avatar-module__root--size-large--m___OcM-Z {
    height: calc(var(--bui_spacing_1x) * 12);
    width: calc(var(--bui_spacing_1x) * 12);
    font-size: var(--bui_font_headline_3_font-size);
    font-weight: var(--bui_font_headline_3_font-weight);
    line-height: var(--bui_font_headline_3_line-height);
    font-family: var(--bui_font_headline_3_font-family);
  }
  .Avatar-module__root--size-larger--m___y7DNN {
    height: calc(var(--bui_spacing_1x) * 16);
    width: calc(var(--bui_spacing_1x) * 16);
    font-size: var(--bui_font_headline_2_font-size);
    font-weight: var(--bui_font_headline_2_font-weight);
    line-height: var(--bui_font_headline_2_line-height);
    font-family: var(--bui_font_headline_2_font-family);
  }
  .Avatar-module__root--size-largest--m___NpzcY {
    height: calc(var(--bui_spacing_1x) * 32);
    width: calc(var(--bui_spacing_1x) * 32);
    font-size: var(--bui_font_headline_1_font-size);
    font-weight: var(--bui_font_headline_1_font-weight);
    line-height: var(--bui_font_headline_1_line-height);
    font-family: var(--bui_font_headline_1_font-family);
  }
}
@media (min-width: 1024px) {
  .Avatar-module__root--size-small--l___23Wmq {
    height: var(--bui_spacing_6x);
    width: var(--bui_spacing_6x);
    font-size: var(--bui_font_small_1_font-size);
    font-weight: var(--bui_font_small_1_font-weight);
    line-height: var(--bui_font_small_1_line-height);
    font-family: var(--bui_font_small_1_font-family);
  }
  .Avatar-module__root--size-medium--l___CHdJT {
    height: var(--bui_spacing_8x);
    width: var(--bui_spacing_8x);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height);
    font-family: var(--bui_font_strong_2_font-family);
  }
  .Avatar-module__root--size-large--l___HtHIs {
    height: calc(var(--bui_spacing_1x) * 12);
    width: calc(var(--bui_spacing_1x) * 12);
    font-size: var(--bui_font_headline_3_font-size);
    font-weight: var(--bui_font_headline_3_font-weight);
    line-height: var(--bui_font_headline_3_line-height);
    font-family: var(--bui_font_headline_3_font-family);
  }
  .Avatar-module__root--size-larger--l___DvZEQ {
    height: calc(var(--bui_spacing_1x) * 16);
    width: calc(var(--bui_spacing_1x) * 16);
    font-size: var(--bui_font_headline_2_font-size);
    font-weight: var(--bui_font_headline_2_font-weight);
    line-height: var(--bui_font_headline_2_line-height);
    font-family: var(--bui_font_headline_2_font-family);
  }
  .Avatar-module__root--size-largest--l___g85qn {
    height: calc(var(--bui_spacing_1x) * 32);
    width: calc(var(--bui_spacing_1x) * 32);
    font-size: var(--bui_font_headline_1_font-size);
    font-weight: var(--bui_font_headline_1_font-weight);
    line-height: var(--bui_font_headline_1_line-height);
    font-family: var(--bui_font_headline_1_font-family);
  }
}
@media (min-width: 1280px) {
  .Avatar-module__root--size-small--xl___YUUzP {
    height: var(--bui_spacing_6x);
    width: var(--bui_spacing_6x);
    font-size: var(--bui_font_small_1_font-size);
    font-weight: var(--bui_font_small_1_font-weight);
    line-height: var(--bui_font_small_1_line-height);
    font-family: var(--bui_font_small_1_font-family);
  }
  .Avatar-module__root--size-medium--xl___rZwlw {
    height: var(--bui_spacing_8x);
    width: var(--bui_spacing_8x);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height);
    font-family: var(--bui_font_strong_2_font-family);
  }
  .Avatar-module__root--size-large--xl___ygtiX {
    height: calc(var(--bui_spacing_1x) * 12);
    width: calc(var(--bui_spacing_1x) * 12);
    font-size: var(--bui_font_headline_3_font-size);
    font-weight: var(--bui_font_headline_3_font-weight);
    line-height: var(--bui_font_headline_3_line-height);
    font-family: var(--bui_font_headline_3_font-family);
  }
  .Avatar-module__root--size-larger--xl___Jxnw3 {
    height: calc(var(--bui_spacing_1x) * 16);
    width: calc(var(--bui_spacing_1x) * 16);
    font-size: var(--bui_font_headline_2_font-size);
    font-weight: var(--bui_font_headline_2_font-weight);
    line-height: var(--bui_font_headline_2_line-height);
    font-family: var(--bui_font_headline_2_font-family);
  }
  .Avatar-module__root--size-largest--xl___JS9FG {
    height: calc(var(--bui_spacing_1x) * 32);
    width: calc(var(--bui_spacing_1x) * 32);
    font-size: var(--bui_font_headline_1_font-size);
    font-weight: var(--bui_font_headline_1_font-weight);
    line-height: var(--bui_font_headline_1_line-height);
    font-family: var(--bui_font_headline_1_font-family);
  }
}
.Avatar-module__root--color-destructive___nzzHa {
  background-color: var(--bui_color_destructive_background);
  color: var(--bui_color_on_destructive_background);
}
.Avatar-module__root--color-callout___rYfQ8 {
  background-color: var(--bui_color_callout_background);
  color: var(--bui_color_on_callout_background);
}
.Avatar-module__root--color-accent___f5nTF {
  background-color: var(--bui_color_accent_background);
  color: var(--bui_color_on_accent_background);
}
.Avatar-module__root--color-constructive___c9XJ7 {
  background-color: var(--bui_color_constructive_background);
  color: var(--bui_color_on_constructive_background);
}
.Avatar-module__root--color-inherit___u8FCH {
  color: currentcolor;
  background: transparent;
}
.Avatar-module__root--color-inherit___u8FCH:before {
  content: '';
  border-radius: 50%;
  position: absolute;
  inset: 0;
  background-color: currentcolor;
  opacity: 0.15;
}
.Avatar-module__root--border-thin___2KvPO {
  --bui-avatar-border-width: var(--bui_border_width_100);
}
.Avatar-module__root--border-thick___rmahh {
  --bui-avatar-border-width: var(--bui_border_width_200);
}
.Avatar-module__root--outline-white___7kZOO {
  border: var(--bui-avatar-border-width) solid var(--bui_color_white);
}
.Avatar-module__root--outline-accent___wFwNM {
  border: var(--bui-avatar-border-width) solid var(--bui_color_accent_border);
}
.Avatar-module__root--outline-callout___ImVNq {
  border: var(--bui-avatar-border-width) solid var(--bui_color_callout_border);
}
.Avatar-module__root--outline-destructive___NZhzi {
  border: var(--bui-avatar-border-width) solid var(--bui_color_destructive_border);
}
.Avatar-module__root--outline-constructive___Ekolw {
  border: var(--bui-avatar-border-width) solid var(--bui_color_constructive_border);
}
.Hidden-module__root--hide-false___\+lnHW,
.Hidden-module__root___\+KOgn {
  display: contents;
}
.Hidden-module__root--hide-true___1SI-9 {
  display: none;
}
@media (min-width: 576px) {
  .Hidden-module__root--hide-false--m___UkQB2 {
    display: contents;
  }
  .Hidden-module__root--hide-true--m___w-JXX {
    display: none;
  }
}
@media (min-width: 1024px) {
  .Hidden-module__root--hide-false--l___88Dnq {
    display: contents;
  }
  .Hidden-module__root--hide-true--l___F96EF {
    display: none;
  }
}
@media (min-width: 1280px) {
  .Hidden-module__root--hide-false--xl___Kw\+Sz {
    display: contents;
  }
  .Hidden-module__root--hide-true--xl___hze5F {
    display: none;
  }
}
.Badge-module__root___JYwhU {
  background-color: var(--bui_color_background);
  border: var(--bui_border_width_100) solid transparent;
  border-radius: var(--bui_border_radius_100);
  color: var(--bui_color_on_background);
  display: inline-flex;
  align-items: center;
  padding: calc(var(--bui_spacing_half) - var(--bui_border_width_100)) var(--bui_spacing_1x);
}
.Badge-module__root--icon-only___4I8CH {
  padding: calc(var(--bui_spacing_1x) - var(--bui_border_width_100));
}
.Badge-module__text___QDweH {
  margin-inline-start: var(--bui_spacing_1x);
}
.Badge-module__text___QDweH:first-child {
  margin-inline-start: 0;
}
.Badge-module__close-icon___VtIN9 {
  margin-inline-start: var(--bui_spacing_1x);
}
.Badge-module__root--alt___NNJ3r {
  background-color: var(--bui_color_background_alt);
  border-color: transparent;
  color: var(--bui_color_foreground);
}
.Badge-module__root--variant-destructive___3epJU {
  border-color: var(--bui_color_destructive_border);
  background-color: var(--bui_color_destructive_background_dynamic);
  color: var(--bui_color_on_destructive_background_dynamic);
}
.Badge-module__root--variant-callout___TK6rv {
  border-color: var(--bui_color_callout_border);
  background-color: var(--bui_color_callout_background_dynamic);
  color: var(--bui_color_on_callout_background_dynamic);
}
.Badge-module__root--variant-accent___O7phq {
  border-color: var(--bui_color_accent_border);
  background-color: var(--bui_color_accent_background_dynamic);
  color: var(--bui_color_on_accent_background_dynamic);
}
.Badge-module__root--variant-constructive___Z4LHF {
  border-color: transparent;
  background-color: var(--bui_color_constructive_background_dynamic);
  color: var(--bui_color_on_constructive_background_dynamic);
}
.Badge-module__root--variant-brand-primary___ZTqmz {
  border-color: transparent;
  background-color: var(--bui_color_brand_primary_background);
  color: var(--bui_color_on_brand_primary_background);
}
.Badge-module__root--variant-brand-genius-primary___n27MC {
  border-color: transparent;
  background-color: var(--bui_color_brand_genius_primary_background);
  color: var(--bui_color_on_brand_genius_primary_background);
}
.Badge-module__root--variant-outline___FmTK5 {
  border-color: var(--bui_color_border);
  background: var(--bui_color_background_elevation_one);
  color: var(--bui_color_foreground);
}
.Badge-module__root--variant-media___vd7B4 {
  border-color: transparent;
  background: var(--bui_color_background_base);
  color: var(--bui_color_foreground_alt);
}
.Badge-module__root--variant-destructive___3epJU.Badge-module__root--alt___NNJ3r {
  border-color: transparent;
  background-color: var(--bui_color_destructive_background_alt);
  color: var(--bui_color_destructive_foreground);
}
.Badge-module__root--variant-callout___TK6rv.Badge-module__root--alt___NNJ3r {
  border-color: transparent;
  background-color: var(--bui_color_callout_background_alt);
  color: var(--bui_color_callout_foreground);
}
.Badge-module__root--variant-accent___O7phq.Badge-module__root--alt___NNJ3r {
  border-color: transparent;
  background-color: var(--bui_color_accent_background_alt);
  color: var(--bui_color_accent_foreground);
}
.Badge-module__root--variant-constructive___Z4LHF.Badge-module__root--alt___NNJ3r {
  border-color: transparent;
  background-color: var(--bui_color_constructive_background_alt);
  color: var(--bui_color_constructive_foreground);
}
.Badge-module__root--variant-brand-primary___ZTqmz.Badge-module__root--alt___NNJ3r {
  border-color: transparent;
  background-color: var(--bui_color_action_background_alt);
  color: var(--bui_color_brand_primary_foreground);
}
.Badge-module__root--variant-outline___FmTK5.Badge-module__root--alt___NNJ3r {
  border-color: var(--bui_color_border_alt);
  background: var(--bui_color_background_elevation_one);
  color: var(--bui_color_foreground_alt);
}
.Badge-module__root--variant-media___vd7B4.Badge-module__root--alt___NNJ3r {
  border-color: transparent;
  background: var(--bui_color_black_with_alpha);
  color: var(--bui_color_white);
}
.Scrim-module__root___1ctq1,
.Scrim-module__scrim___KRrSz {
  position: absolute;
  inset: 0;
}
.Scrim-module__root___1ctq1 {
  pointer-events: none;
  --bui_scrim_gradient_bg: rgba(0, 0, 0, 0.349) 0%, rgba(0, 0, 0, 0.424) 13.81%, rgba(0, 0, 0, 0.494) 28.27%,
    rgba(0, 0, 0, 0.561) 42.12%, rgba(0, 0, 0, 0.616) 56.28%, rgba(0, 0, 0, 0.663) 70.13%, rgba(0, 0, 0, 0.69) 84.88%,
    rgba(0, 0, 0, 0.702) 100%;
  --bui_scrim_gradient_fade: transparent 0%, rgba(0, 0, 0, 0.012) 13.54%, rgba(0, 0, 0, 0.039) 27.6%,
    rgba(0, 0, 0, 0.082) 42.19%, rgba(0, 0, 0, 0.137) 56.77%, rgba(0, 0, 0, 0.204) 71.35%, rgba(0, 0, 0, 0.275) 85.94%,
    rgba(0, 0, 0, 0.349) 100%;
}
.Scrim-module__scrim___KRrSz {
  padding: var(--bui_spacing_4x);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bui_color_white);
  transform: translateZ(0);
}
.Scrim-module__content___Hm35e {
  pointer-events: all;
}
.Scrim-module__root--centered___DHx\+z .Scrim-module__content___Hm35e {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Scrim-module__root--position-full___hJ24L .Scrim-module__scrim___KRrSz {
  background-color: var(--bui_color_black_with_alpha);
}
.Scrim-module__root--position-full___hJ24L .Scrim-module__content___Hm35e {
  text-align: center;
}
.Scrim-module__root--position-bottom___FrQlK .Scrim-module__content___Hm35e,
.Scrim-module__root--position-full___hJ24L .Scrim-module__content___Hm35e,
.Scrim-module__root--position-top___rsYxh .Scrim-module__content___Hm35e {
  width: 100%;
}
.Scrim-module__root--position-end___EIb97 .Scrim-module__content___Hm35e,
.Scrim-module__root--position-start___4AeJ7 .Scrim-module__content___Hm35e {
  height: 100%;
}
.Scrim-module__root--position-top___rsYxh .Scrim-module__scrim___KRrSz {
  justify-content: start;
  inset-block-end: auto;
  background: linear-gradient(0, var(--bui_scrim_gradient_bg));
}
.Scrim-module__root--position-bottom___FrQlK .Scrim-module__scrim___KRrSz {
  justify-content: start;
  inset-block-start: auto;
  background: linear-gradient(180deg, var(--bui_scrim_gradient_bg));
}
.Scrim-module__root--position-bottom___FrQlK .Scrim-module__scrim___KRrSz:before,
.Scrim-module__root--position-top___rsYxh .Scrim-module__scrim___KRrSz:before {
  content: '';
  position: absolute;
  inset-inline: 0;
  height: calc(var(--bui_spacing_1x) * 13);
}
.Scrim-module__root--position-top___rsYxh .Scrim-module__scrim___KRrSz:before {
  inset-block-start: 100%;
  background: linear-gradient(0, var(--bui_scrim_gradient_fade));
}
.Scrim-module__root--position-bottom___FrQlK .Scrim-module__scrim___KRrSz:before {
  inset-block-end: 100%;
  background: linear-gradient(180deg, var(--bui_scrim_gradient_fade));
}
.Scrim-module__root--position-start___4AeJ7 .Scrim-module__scrim___KRrSz {
  width: auto;
  justify-content: start;
  align-items: start;
  inset-inline-end: auto;
  inset-inline-start: 0;
  background: linear-gradient(-90deg, var(--bui_scrim_gradient_bg));
}
[dir='rtl'] .Scrim-module__root--position-start___4AeJ7 .Scrim-module__scrim___KRrSz {
  background: linear-gradient(90deg, var(--bui_scrim_gradient_bg));
}
.Scrim-module__root--position-end___EIb97 .Scrim-module__scrim___KRrSz {
  width: auto;
  align-items: start;
  justify-content: end;
  inset-inline-end: 0;
  inset-inline-start: auto;
  background: linear-gradient(90deg, var(--bui_scrim_gradient_bg));
}
[dir='rtl'] .Scrim-module__root--position-end___EIb97 .Scrim-module__scrim___KRrSz {
  background: linear-gradient(-90deg, var(--bui_scrim_gradient_bg));
}
.Scrim-module__root--position-end___EIb97 .Scrim-module__scrim___KRrSz:before,
.Scrim-module__root--position-start___4AeJ7 .Scrim-module__scrim___KRrSz:before {
  content: '';
  position: absolute;
  inset-block: 0;
  width: calc(var(--bui_spacing_1x) * 13);
}
.Scrim-module__root--position-start___4AeJ7 .Scrim-module__scrim___KRrSz:before {
  inset-inline-start: 100%;
  inset-inline-end: auto;
  background: linear-gradient(-90deg, var(--bui_scrim_gradient_fade));
}
[dir='rtl'] .Scrim-module__root--position-start___4AeJ7 .Scrim-module__scrim___KRrSz:before {
  background: linear-gradient(90deg, var(--bui_scrim_gradient_fade));
}
.Scrim-module__root--position-end___EIb97 .Scrim-module__scrim___KRrSz:before {
  inset-inline-end: 100%;
  inset-inline-start: auto;
  background: linear-gradient(90deg, var(--bui_scrim_gradient_fade));
}
[dir='rtl'] .Scrim-module__root--position-end___EIb97 .Scrim-module__scrim___KRrSz:before {
  background: linear-gradient(-90deg, var(--bui_scrim_gradient_fade));
}
.Scrim-module__root--fill___Ujj89 .Scrim-module__scrim___KRrSz {
  padding: 0;
}
.Scrim-module__root--with-background___XVuxG {
  position: relative;
  overflow: hidden;
}
.Scrim-module__root--border-radius-100___ymTG\+ {
  border-radius: var(--bui_border_radius_100);
}
.Scrim-module__root--border-radius-200___waPvu {
  border-radius: var(--bui_border_radius_200);
}
.Scrim-module__root--border-radius-300___aexo0 {
  border-radius: var(--bui_border_radius_300);
}
.Banner-module__icon___MlxmQ {
  height: var(--bui_font_strong_1_line-height);
  display: inline-flex;
  align-items: center;
}
.Banner-module__icon--no-title___b7f-o {
  height: var(--bui_font_body_2_line-height);
}
.Banner-module__imageContainer___wgMRb {
  width: calc(var(--bui_spacing_1x) * 16);
}
.Banner-module__text___hnwY2,
.Banner-module__title___-iYH4 {
  margin: 0;
}
.Bubble-module__root___\+5tt5 {
  box-sizing: border-box;
  display: inline-block;
  padding: 0 var(--bui_spacing_1x);
  text-align: center;
  min-width: calc(var(--bui_rem_spacing_1x) * 4.5);
  border-radius: 99px;
  vertical-align: top;
}
.Bubble-module__root--variant-neutral___4Ch1r {
  background-color: var(--bui_color_background);
  color: var(--bui_color_on_background);
}
.Bubble-module__root--variant-destructive___GKCjH {
  background-color: var(--bui_color_destructive_background);
  color: var(--bui_color_on_destructive_background);
}
.Bubble-module__root--variant-action___RjSNi {
  background-color: var(--bui_color_action_background);
  color: var(--bui_color_on_action_background);
}
.Bubble-module__root___\+5tt5:empty {
  width: var(--bui_spacing_2x);
  height: var(--bui_spacing_2x);
  padding: 0;
  min-width: auto;
}
.BubbleContainer-module__root___erLTp {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.BubbleContainer-module__value___uns7R {
  position: absolute;
  inset-block-start: calc(var(--bui_spacing_half) * -1);
  inset-inline-start: 100%;
  transform: translateX(calc(var(--bui_spacing_1x) * -3));
}
[dir='rtl'] .BubbleContainer-module__value___uns7R {
  transform: translateX(calc(var(--bui_spacing_1x) * 3));
}
.BubbleContainer-module__value___uns7R:empty {
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: auto;
  transform: none;
}
.BottomNavigation-module__root___5aTmO {
  border-block-start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
  background: var(--bui_color_background_elevation_one);
  display: flex;
}
.BottomNavigation-module__item___FlygQ {
  text-align: center;
  padding: var(--bui_spacing_2x) 0;
  color: var(--bui_color_foreground_alt);
  flex-grow: 1;
  text-decoration: none;
  min-height: calc(var(--bui_spacing_1x) * 13);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: var(--bui_animation_press);
  transition-property: background-color, color;
}
.BottomNavigation-module__item___FlygQ:hover {
  background-color: var(--bui_color_highlighted_alt);
}
.BottomNavigation-module__item--selected___0vbgA {
  color: var(--bui_color_action_foreground);
}
.BottomNavigation-module__text___aD2j6 {
  margin-block-start: var(--bui_spacing_1x);
  display: block;
}
.Link-module__root___tq2Ux {
  display: inline;
}
.Link-module__root___tq2Ux,
.Link-module__root___tq2Ux:active,
.Link-module__root___tq2Ux:focus,
.Link-module__root___tq2Ux:hover {
  color: var(--bui_color_action_foreground);
  text-decoration: underline;
}
.Link-module__root___tq2Ux:active,
.Link-module__root___tq2Ux:visited {
  color: var(--bui_color_action_foreground);
}
.Link-module__icon--start___59Rmd {
  margin-inline-start: 0;
  margin-inline-end: var(--bui_spacing_2x);
}
.Link-module__icon--end___bSuGQ {
  margin-inline-start: var(--bui_spacing_2x);
  margin-inline-end: 0;
}
.Link-module__root--variant-primary___EMmel,
.Link-module__root--variant-secondary___qPJ5F {
  text-decoration: none;
}
p .Link-module__root--variant-secondary___qPJ5F {
  text-decoration: underline;
}
.Link-module__root--icon___xS6YE {
  display: inline-flex;
  align-items: center;
}
.Link-module__root--variant-secondary___qPJ5F,
.Link-module__root--variant-secondary___qPJ5F:active,
.Link-module__root--variant-secondary___qPJ5F:focus,
.Link-module__root--variant-secondary___qPJ5F:hover,
.Link-module__root--variant-secondary___qPJ5F:visited {
  color: var(--bui_color_foreground);
}
.Link-module__root--disabled___X9xwS,
.Link-module__root--disabled___X9xwS:active,
.Link-module__root--disabled___X9xwS:focus,
.Link-module__root--disabled___X9xwS:hover,
.Link-module__root--disabled___X9xwS:visited {
  color: var(--bui_color_foreground_disabled);
  text-decoration: none;
}
.Breadcrumbs-module__list___h\+L-S {
  padding: 0;
  list-style-type: none;
  margin-block-end: 0;
}
.Breadcrumbs-module__item___\+Ga1U {
  display: inline-flex;
  align-items: center;
}
.Breadcrumbs-module__icon___3jVYQ {
  margin-inline-end: var(--bui_spacing_half);
}
.Calendar-module__root___m1mSB {
  position: relative;
}
.Calendar-module__month___JKG88 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
}
.Calendar-module__root___m1mSB:not(.Calendar-module__root--vertical___9Z41j) .Calendar-module__month___JKG88 {
  line-height: calc(var(--bui_spacing_1x) * 12);
}
.Calendar-module__control___L2a4E {
  position: absolute;
  inset-block-start: 0;
}
.Calendar-module__control--next___dTpqh {
  inset-inline-end: 0;
}
.Calendar-module__control--prev___bBJjd {
  inset-inline-start: 0;
}
.Calendar-module__content___K7BkH {
  display: flex;
  box-sizing: border-box;
}
.Calendar-module__monthWrapper___lq7Zl {
  padding-inline-start: var(--bui_spacing_4x);
}
.Calendar-module__monthWrapper___lq7Zl:first-child {
  padding-inline-start: 0;
}
.Calendar-module__dates___JQ\+BY {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}
.Calendar-module__weekday___KdmEg {
  text-align: center;
  border-block-start: var(--bui_spacing_2x) solid transparent;
  padding: var(--bui_spacing_half);
  border-spacing: 0;
}
.Calendar-module__cell___BMvea {
  height: calc(var(--bui_spacing_1x) * 11);
  padding: 0;
  border-spacing: 0;
  border-block-start: var(--bui_spacing_1x) solid transparent;
  outline: none;
}
.Calendar-module__date___h1CAL {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 var(--bui_spacing_1x);
}
.Calendar-module__date___h1CAL.Calendar-module__date--hoverable___P5cUF:before {
  content: '';
  position: absolute;
  inset: calc(var(--bui_spacing_half) * -1) 0;
}
:not(
    .Calendar-module__date--in-range___\+DC6i
  ).Calendar-module__date___h1CAL.Calendar-module__date--hoverable___P5cUF:hover:after {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--bui_color_highlighted_alt);
  pointer-events: none;
}
[data-bui-keyboard] .Calendar-module__date___h1CAL:focus:not(.Calendar-module__date--disabled___VC2Y1) {
  position: relative;
  z-index: 10;
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.Calendar-module__date--today___U5L1m {
  color: var(--bui_color_action_foreground);
  font-size: var(--bui_font_strong_2_font-size);
  font-weight: var(--bui_font_strong_2_font-weight);
  line-height: var(--bui_font_strong_2_line-height);
  font-family: var(--bui_font_strong_2_font-family);
}
.Calendar-module__date--in-range___\+DC6i {
  background: var(--bui_color_highlighted_alt);
}
.Calendar-module__date--selected___4QN7Y,
.Calendar-module__date--selected___4QN7Y:focus-within,
.Calendar-module__date--selected___4QN7Y:hover {
  color: var(--bui_color_on_action_background);
  background: var(--bui_color_action_background);
}
.Calendar-module__date--selected-start___BFFNz,
.Calendar-module__date--selected-start___BFFNz:after {
  border-top-left-radius: var(--bui_border_radius_100);
  border-bottom-left-radius: var(--bui_border_radius_100);
}
[dir='rtl'] .Calendar-module__date--selected-start___BFFNz,
[dir='rtl'] .Calendar-module__date--selected-start___BFFNz:after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bui_border_radius_100);
  border-bottom-right-radius: var(--bui_border_radius_100);
}
.Calendar-module__date--selected-end___Cz5hV,
.Calendar-module__date--selected-end___Cz5hV:after {
  border-top-right-radius: var(--bui_border_radius_100);
  border-bottom-right-radius: var(--bui_border_radius_100);
}
[dir='rtl'] .Calendar-module__date--selected-end___Cz5hV,
[dir='rtl'] .Calendar-module__date--selected-end___Cz5hV:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--bui_border_radius_100);
  border-bottom-left-radius: var(--bui_border_radius_100);
}
.Calendar-module__date--selected-start___BFFNz.Calendar-module__date--selected-end___Cz5hV,
.Calendar-module__date--selected-start___BFFNz.Calendar-module__date--selected-end___Cz5hV:after {
  border-radius: var(--bui_border_radius_100);
}
.Calendar-module__date--disabled___VC2Y1,
.Calendar-module__date--disabled___VC2Y1:focus,
.Calendar-module__date--disabled___VC2Y1:hover,
.Calendar-module__date--disabled___VC2Y1:hover:after {
  color: var(--bui_color_foreground_disabled);
  background: none;
  cursor: default;
}
.Calendar-module__attachment___pk2i2 {
  max-width: 100%;
}
.Calendar-module__loader___XiU0J {
  margin-block-start: var(--bui_spacing_1x);
}
.Calendar-module__display___9GIT0 {
  border-block-start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
  text-align: center;
  margin-block-start: var(--bui_spacing_2x);
  padding-block-start: var(--bui_spacing_2x);
}
.Calendar-module__display___9GIT0:empty {
  display: none;
}
.Calendar-module__root--vertical___9Z41j {
  overflow: hidden;
  max-height: 340px;
  display: flex;
  flex-direction: column;
}
.Calendar-module__verticalHeader___ycNqU {
  padding: 0 var(--bui_spacing_4x);
  background: var(--bui_color_background_elevation_one);
  border-block-end: var(--bui_border_width_100) solid var(--bui_color_border_alt);
  flex-shrink: 0;
}
.Calendar-module__verticalWeekdays___DmDD6 {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}
.Calendar-module__root--vertical___9Z41j .Calendar-module__weekday___KdmEg {
  border-block-start: 0;
  padding-block: calc(var(--bui_spacing_2x) + var(--bui_spacing_half));
}
.Calendar-module__root--vertical___9Z41j .Calendar-module__content___K7BkH {
  display: block;
  overflow: auto;
  padding: var(--bui_spacing_4x);
  padding-block-start: var(--bui_spacing_8x);
  flex-grow: 1;
}
.Calendar-module__root--vertical___9Z41j .Calendar-module__monthWrapper___lq7Zl {
  padding: 0;
  padding-block-start: var(--bui_spacing_4x);
}
.Calendar-module__root--vertical___9Z41j .Calendar-module__monthWrapper___lq7Zl:first-child {
  padding-block-start: 0;
}
.Calendar-module__root--vertical___9Z41j .Calendar-module__control___L2a4E {
  display: none;
}
.Calendar-module__root--vertical___9Z41j .Calendar-module__month___JKG88 {
  text-align: start;
  justify-content: flex-start;
}
.Calendar-module__root--full-height___aGpmO {
  height: 100%;
  max-height: none;
}
.Calendar-module__date--selected___4QN7Y .Calendar-module__attachment___pk2i2 {
  color: currentcolor;
}
.SkeletonLoader-module__root___DKNd5 {
  display: block;
}
.SkeletonLoader-module__skeleton___5lQNJ {
  display: block;
  position: relative;
  border-radius: var(--bui_border_radius_100);
  opacity: var(--bui_skeleton_loader--opacity);
  height: var(--bui_spacing_4x);
  overflow: hidden;
}
.SkeletonLoader-module__skeleton___5lQNJ:before {
  content: '';
  position: absolute;
  inset: 0;
  opacity: var(--bui_skeleton_loader--color_opacity);
  background-color: var(--bui_skeleton_loader--background_color);
}
.SkeletonLoader-module__skeleton___5lQNJ:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 80px;
  inset-block-start: 0;
  inset-inline-start: -80px;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.8) 40px,
    hsla(0, 0%, 100%, 0) 80px
  );
  opacity: var(--bui_skeleton_loader--color_opacity);
  animation: SkeletonLoader-module__skeleton-animation___vLO\+ 6 1.6s linear infinite;
}
.SkeletonLoader-module__skeleton___5lQNJ + .SkeletonLoader-module__skeleton___5lQNJ {
  margin-block-start: var(--bui_spacing_2x);
}
.SkeletonLoader-module__title___0UrRb {
  width: 70%;
}
.SkeletonLoader-module__line___n9Q-D {
  width: 100%;
}
.SkeletonLoader-module__halfLine___vURYL {
  width: 50%;
}
.SkeletonLoader-module__box___sCFtV {
  width: 100%;
  height: 100%;
  display: block;
}
.SkeletonLoader-module__root--color-neutral___bL61X {
  --bui_skeleton_loader--background_color: var(--bui_color_background);
  --bui_skeleton_loader--opacity: 0.15;
  --bui_skeleton_loader--color_opacity: 1;
}
.SkeletonLoader-module__root--color-inherit___JMm9T {
  --bui_skeleton_loader--background_color: currentcolor;
  --bui_skeleton_loader--opacity: 0.3;
  --bui_skeleton_loader--color_opacity: 0.5;
}
.SkeletonLoader-module__root___DKNd5 {
  width: var(--bui_skeleton_width);
}
.SkeletonLoader-module__root___DKNd5[style*='--bui_skeleton_width--s'] {
  --bui_skeleton_width: var(--bui_skeleton_width--s);
}
.SkeletonLoader-module__root___DKNd5[style*='--bui_skeleton_spaced_width--s'] {
  --bui_skeleton_width: calc(var(--bui_skeleton_spaced_width--s) * var(--bui_spacing_1x));
}
@media (min-width: 576px) {
  .SkeletonLoader-module__root___DKNd5[style*='--bui_skeleton_width--m'] {
    --bui_skeleton_width: var(--bui_skeleton_width--m);
  }
  .SkeletonLoader-module__root___DKNd5[style*='--bui_skeleton_spaced_width--m'] {
    --bui_skeleton_width: calc(var(--bui_skeleton_spaced_width--m) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1024px) {
  .SkeletonLoader-module__root___DKNd5[style*='--bui_skeleton_width--l'] {
    --bui_skeleton_width: var(--bui_skeleton_width--l);
  }
  .SkeletonLoader-module__root___DKNd5[style*='--bui_skeleton_spaced_width--l'] {
    --bui_skeleton_width: calc(var(--bui_skeleton_spaced_width--l) * var(--bui_spacing_1x));
  }
}
@media (min-width: 1280px) {
  .SkeletonLoader-module__root___DKNd5[style*='--bui_skeleton_width--xl'] {
    --bui_skeleton_width: var(--bui_skeleton_width--xl);
  }
  .SkeletonLoader-module__root___DKNd5[style*='--bui_skeleton_spaced_width--xl'] {
    --bui_skeleton_width: calc(var(--bui_skeleton_spaced_width--xl) * var(--bui_spacing_1x));
  }
}
@media (prefers-reduced-motion) {
  .SkeletonLoader-module__skeleton___5lQNJ:after {
    animation-duration: 1ms;
    animation-iteration-count: 1;
  }
}
@keyframes SkeletonLoader-module__skeleton-animation___vLO\+6 {
  0% {
    inset-inline-start: -80px;
  }
  50%,
  to {
    inset-inline-start: 100vw;
  }
}
.Card-module__root--elevated___jRD2u {
  box-shadow: var(--bui_shadow_100);
}
.Slider-module__container___lo5W5 {
  white-space: nowrap;
  overflow-x: scroll;
  backface-visibility: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
}
.Slider-module__container___lo5W5::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.Slider-module__container--scroll-disabled___cX-Vu {
  overflow-x: hidden;
}
.Slider-module__item___9VBNU {
  white-space: normal;
}
.Slider-module__control___wn8X8 {
  cursor: default;
  transition: var(--bui_animation_hover);
  transition-property: opacity;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.Slider-module__control___wn8X8.Slider-module__control--clickable___vWNdF {
  visibility: visible;
}
.Slider-module__control___wn8X8.Slider-module__control--visible___zoyDE {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}
.Slider-module__control___wn8X8.Slider-module__control--visible___zoyDE:active {
  opacity: 1;
}
.Carousel-module__root___\+XH7W {
  position: relative;
  margin-inline: calc(var(--bui_spacing_4x) * -1);
  margin-block: calc(var(--bui_spacing_2x) * -1);
}
.Carousel-module__header___KsiWq {
  margin-block-end: var(--bui_spacing_4x);
}
.Carousel-module__inner___zef9I {
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  padding-inline: var(--bui_spacing_4x);
  padding-block: var(--bui_spacing_2x);
  scroll-padding-left: var(--bui_spacing_4x);
}
[dir='rtl'] .Carousel-module__inner___zef9I {
  scroll-padding-left: 0;
  scroll-padding-right: var(--bui_spacing_4x);
}
.Carousel-module__inner___zef9I::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.Carousel-module__nav___HlO0I {
  display: none;
  pointer-events: none;
}
.Carousel-module__control___ow3Ii {
  pointer-events: all;
}
.Carousel-module__item___hw3sP {
  flex: 0 0 auto;
  box-sizing: border-box;
  margin-inline-end: var(--bui_spacing_3x);
  scroll-snap-align: start;
}
.Carousel-module__item___hw3sP:last-child {
  margin-inline-end: 0;
}
.Carousel-module__root--size-small___5tw3e .Carousel-module__item___hw3sP {
  width: 28%;
}
.Carousel-module__root--size-medium___OffQ0 .Carousel-module__item___hw3sP {
  width: 42%;
}
.Carousel-module__root--size-large___qj5nv .Carousel-module__item___hw3sP {
  width: 64%;
}
.Carousel-module__root--size-larger___tbP52 .Carousel-module__item___hw3sP,
.Carousel-module__root--size-largest___LWfWo .Carousel-module__item___hw3sP {
  width: 89%;
}
@media (min-width: 576px) {
  .Carousel-module__root--size-small___5tw3e .Carousel-module__item___hw3sP {
    width: 18%;
  }
  .Carousel-module__root--size-medium___OffQ0 .Carousel-module__item___hw3sP {
    width: 28%;
  }
  .Carousel-module__root--size-large___qj5nv .Carousel-module__item___hw3sP {
    width: 40%;
  }
  .Carousel-module__root--size-larger___tbP52 .Carousel-module__item___hw3sP,
  .Carousel-module__root--size-largest___LWfWo .Carousel-module__item___hw3sP {
    width: 67%;
  }
}
@media (min-width: 1024px) {
  .Carousel-module__root___\+XH7W {
    margin-inline: calc(var(--bui_spacing_2x) * -1);
  }
  .Carousel-module__inner___zef9I {
    padding-inline: var(--bui_spacing_2x);
    -webkit-mask-image: linear-gradient(
      90deg,
      transparent,
      #000 var(--bui_spacing_2x),
      #000 calc(100% - var(--bui_spacing_2x)),
      transparent
    );
    mask-image: linear-gradient(
      90deg,
      transparent,
      #000 var(--bui_spacing_2x),
      #000 calc(100% - var(--bui_spacing_2x)),
      transparent
    );
    scroll-padding-left: var(--bui_spacing_2x);
  }
  [dir='rtl'] .Carousel-module__inner___zef9I {
    scroll-padding-left: 0;
    scroll-padding-right: var(--bui_spacing_4x);
  }
  .Carousel-module__nav___HlO0I {
    position: absolute;
    inset-block-start: 50%;
    transform: translateY(-50%);
    inset-inline: calc(var(--bui_spacing_2x) * -1);
    display: flex;
    justify-content: space-between;
    z-index: var(--bui_z_index_1);
  }
  .Carousel-module__item___hw3sP {
    margin-inline-end: var(--bui_spacing_4x);
  }
  .Carousel-module__item___hw3sP:last-child {
    margin-inline-end: 0;
  }
  .Carousel-module__root--size-small___5tw3e .Carousel-module__item___hw3sP {
    width: calc(16.66667% - var(--bui_spacing_4x) * 5 / 6);
  }
  .Carousel-module__root--size-medium___OffQ0 .Carousel-module__item___hw3sP {
    width: calc(25% - var(--bui_spacing_4x) * 3 / 4);
  }
  .Carousel-module__root--size-large___qj5nv .Carousel-module__item___hw3sP,
  .Carousel-module__root--size-larger___tbP52 .Carousel-module__item___hw3sP {
    width: calc(33.33333% - var(--bui_spacing_4x) * 2 / 3);
  }
  .Carousel-module__root--size-largest___LWfWo .Carousel-module__item___hw3sP {
    width: calc(50% - var(--bui_spacing_4x) * 1 / 2);
  }
}
.Container-module__root___oMIao {
  box-sizing: border-box;
  position: relative;
  padding: 0 var(--bui_spacing_4x);
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
}
.Container-module__root--full-width___YLybk {
  max-width: 100%;
}
.CollapsedText-module__text___eQQ9Q {
  position: relative;
  white-space: pre-line;
}
.CollapsedText-module__text--collapsed___xsQ6U {
  --bui_collapsed_text_line_percentage: calc(100% / var(--bui_collapsed_text_visible_lines));
  display: -webkit-box;
  -webkit-line-clamp: var(--bui_collapsed_text_visible_lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    0deg,
    transparent,
    #000 calc(var(--bui_collapsed_text_line_percentage) + var(--bui_collapsed_text_line_percentage) / 2)
  );
  mask-image: linear-gradient(
    0deg,
    transparent,
    #000 calc(var(--bui_collapsed_text_line_percentage) + var(--bui_collapsed_text_line_percentage) / 2)
  );
}
.CollapsedText-module__text--non-collapsable___Rl2Tv {
  -webkit-line-clamp: none;
  -webkit-mask-image: none;
  mask-image: none;
}
.DateItem-module__root___0tIuv {
  display: inline-block;
  position: relative;
  text-align: center;
}
.DateItem-module__label___XEVQs {
  margin-block-end: var(--bui_spacing_1x);
}
.DateItem-module__root--actionable___Jwm9\+ .DateItem-module__title___WNUYP {
  color: var(--bui_color_action_foreground);
}
.DateItem-module__root--variant-detailed___PryCr {
  text-align: start;
}
.DateRange-module__root___IY5DG {
  display: flex;
  flex-wrap: nowrap;
}
.DateRange-module__item___bQLC2:first-child {
  padding-inline-end: var(--bui_spacing_4x);
}
.DateRange-module__item___bQLC2:last-child {
  padding-inline-start: var(--bui_spacing_4x);
}
@media (max-width: 575px) {
  .DateRange-module__root--variant-detailed___P\+eNZ .DateRange-module__item___bQLC2 {
    flex-grow: 1;
    width: 50%;
  }
}
.DropdownMenu-module__textSlot___ZOxFq {
  margin-inline-start: var(--bui_spacing_2x);
}
.DropdownMenu-module__items___pBrBv {
  margin: 0;
  padding: var(--bui_spacing_1x) 0;
  list-style-type: none;
}
.DropdownMenu-module__divider___BrfiC {
  margin: var(--bui_spacing_1x) 0;
}
.EmptyState-module__root___3PiwM {
  max-width: calc(var(--bui_spacing_1x) * 175);
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EmptyState-module__title___5UUaC {
  margin: 0;
  margin-block-end: var(--bui_spacing_4x);
}
.EmptyState-module__slot___BHazK,
.EmptyState-module__startSlot___9UkjM {
  max-height: 256px;
}
.EmptyState-module__startSlot___9UkjM {
  margin-block-end: var(--bui_spacing_6x);
}
.EmptyState-module__actions___qREUV {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EmptyState-module__link___TnXLu:not(:first-child) {
  margin-block-start: var(--bui_spacing_2x);
}
@media (min-width: 576px) {
  .EmptyState-module__root--horizontal___\+2eh7 {
    flex-direction: row;
    text-align: start;
  }
  .EmptyState-module__root--horizontal___\+2eh7 .EmptyState-module__content___ZQeQN {
    align-items: flex-start;
  }
  .EmptyState-module__root--horizontal___\+2eh7 .EmptyState-module__actions___qREUV {
    flex-direction: row;
  }
  .EmptyState-module__root--horizontal___\+2eh7 .EmptyState-module__link___TnXLu:not(:first-child) {
    margin-block-start: 0;
    margin-inline-start: var(--bui_spacing_2x);
  }
  .EmptyState-module__startSlot___9UkjM {
    margin-block-end: 0;
    margin-inline-end: var(--bui_spacing_6x);
    max-height: none;
    max-width: 256px;
  }
}
.FormControl-module__root___fX5jj {
  border: 0;
  margin: 0;
  padding: 0;
}
.FormControl-module__label___Qrai1 {
  display: flex;
  align-items: flex-end;
  padding: 0;
  padding-block-end: var(--bui_spacing_1x);
}
.FormControl-module__asterisk___IcMYf,
.FormControl-module__subLabel___sr\+te {
  display: inline-block;
  margin-inline-start: var(--bui_spacing_1x);
}
.FormControl-module__labelEnd___c5VNy {
  flex-grow: 1;
  text-align: end;
}
.FormControl-module__note___4IMKJ {
  margin-block-start: var(--bui_spacing_1x);
}
.Grid-module__root___xp8JA,
.Grid-module__root___xp8JA > * {
  box-sizing: border-box;
  clear: both;
}
.Grid-module__root--columns-1___tfVxw {
  --bui_grid_columns: 1;
}
.Grid-module__root--columns-2___G2rRG {
  --bui_grid_columns: 2;
}
.Grid-module__root--columns-3___oW3la {
  --bui_grid_columns: 3;
}
.Grid-module__root--columns-4___EPQlT {
  --bui_grid_columns: 4;
}
.Grid-module__root--columns-5___uaoo- {
  --bui_grid_columns: 5;
}
.Grid-module__root--columns-6___nJiWE {
  --bui_grid_columns: 6;
}
.Grid-module__root--columns-7___rNBdv {
  --bui_grid_columns: 7;
}
.Grid-module__root--columns-8___ECaEz {
  --bui_grid_columns: 8;
}
.Grid-module__root--columns-9___sOzlM {
  --bui_grid_columns: 9;
}
.Grid-module__root--columns-10___WyRu0 {
  --bui_grid_columns: 10;
}
.Grid-module__root--columns-11___T5Uap {
  --bui_grid_columns: 11;
}
.Grid-module__root--columns-12___Pgqt0 {
  --bui_grid_columns: 12;
}
@media (min-width: 576px) {
  .Grid-module__root--columns-1--m___yuArZ {
    --bui_grid_columns: 1;
  }
  .Grid-module__root--columns-2--m___wXTLH {
    --bui_grid_columns: 2;
  }
  .Grid-module__root--columns-3--m___0hKhm {
    --bui_grid_columns: 3;
  }
  .Grid-module__root--columns-4--m___\+3wv8 {
    --bui_grid_columns: 4;
  }
  .Grid-module__root--columns-5--m___DerjB {
    --bui_grid_columns: 5;
  }
  .Grid-module__root--columns-6--m___ZZGT0 {
    --bui_grid_columns: 6;
  }
  .Grid-module__root--columns-7--m___0CKs6 {
    --bui_grid_columns: 7;
  }
  .Grid-module__root--columns-8--m___blA1h {
    --bui_grid_columns: 8;
  }
  .Grid-module__root--columns-9--m___2esvL {
    --bui_grid_columns: 9;
  }
  .Grid-module__root--columns-10--m___dcyMW {
    --bui_grid_columns: 10;
  }
  .Grid-module__root--columns-11--m___WsJjT {
    --bui_grid_columns: 11;
  }
  .Grid-module__root--columns-12--m___3SJR4 {
    --bui_grid_columns: 12;
  }
}
@media (min-width: 1024px) {
  .Grid-module__root--columns-1--l___xd6Xl {
    --bui_grid_columns: 1;
  }
  .Grid-module__root--columns-2--l___vKnGe {
    --bui_grid_columns: 2;
  }
  .Grid-module__root--columns-3--l___dk9OI {
    --bui_grid_columns: 3;
  }
  .Grid-module__root--columns-4--l___uNmDg {
    --bui_grid_columns: 4;
  }
  .Grid-module__root--columns-5--l___xXZYh {
    --bui_grid_columns: 5;
  }
  .Grid-module__root--columns-6--l___o1woZ {
    --bui_grid_columns: 6;
  }
  .Grid-module__root--columns-7--l___IkS-S {
    --bui_grid_columns: 7;
  }
  .Grid-module__root--columns-8--l___RGVqN {
    --bui_grid_columns: 8;
  }
  .Grid-module__root--columns-9--l___9hJIt {
    --bui_grid_columns: 9;
  }
  .Grid-module__root--columns-10--l___0dxTD {
    --bui_grid_columns: 10;
  }
  .Grid-module__root--columns-11--l___58vFr {
    --bui_grid_columns: 11;
  }
  .Grid-module__root--columns-12--l___GGOie {
    --bui_grid_columns: 12;
  }
}
@media (min-width: 1280px) {
  .Grid-module__root--columns-1--xl___SNg6M {
    --bui_grid_columns: 1;
  }
  .Grid-module__root--columns-2--xl___f58nU {
    --bui_grid_columns: 2;
  }
  .Grid-module__root--columns-3--xl___Lnszz {
    --bui_grid_columns: 3;
  }
  .Grid-module__root--columns-4--xl___\+0pr\+ {
    --bui_grid_columns: 4;
  }
  .Grid-module__root--columns-5--xl___dn2HK {
    --bui_grid_columns: 5;
  }
  .Grid-module__root--columns-6--xl___K62ml {
    --bui_grid_columns: 6;
  }
  .Grid-module__root--columns-7--xl___hgnoX {
    --bui_grid_columns: 7;
  }
  .Grid-module__root--columns-8--xl___YDi\+z {
    --bui_grid_columns: 8;
  }
  .Grid-module__root--columns-9--xl___sySwH {
    --bui_grid_columns: 9;
  }
  .Grid-module__root--columns-10--xl___lIBX1 {
    --bui_grid_columns: 10;
  }
  .Grid-module__root--columns-11--xl___eWxNa {
    --bui_grid_columns: 11;
  }
  .Grid-module__root--columns-12--xl___ZH6h4 {
    --bui_grid_columns: 12;
  }
}
.Grid-module__column--size-1___EwUM4 {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 1);
}
.Grid-module__column--size-2___uY8fT {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 2);
}
.Grid-module__column--size-3___1r5Xn {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 3);
}
.Grid-module__column--size-4___N5PYj {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 4);
}
.Grid-module__column--size-5___cFaxT {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 5);
}
.Grid-module__column--size-6___zWDD2 {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 6);
}
.Grid-module__column--size-half___8l4TS {
  --bui_grid_column_size: 2;
}
.Grid-module__column--size-7___CTqX4 {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 7);
}
.Grid-module__column--size-8___aebs9 {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 8);
}
.Grid-module__column--size-9___sRli8 {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 9);
}
.Grid-module__column--size-10___GFRTm {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 10);
}
.Grid-module__column--size-11___UyRLl {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 11);
}
.Grid-module__column--size-12___5j37M {
  --bui_grid_column_size: calc(var(--bui_grid_columns) / 12);
}
.Grid-module__column--size-full___ouSKG {
  --bui_grid_column_size: 1;
}
@media (min-width: 576px) {
  .Grid-module__column--size-1--m___l8\+ck {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 1);
  }
  .Grid-module__column--size-2--m___P40Uj {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 2);
  }
  .Grid-module__column--size-3--m___G9LYn {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 3);
  }
  .Grid-module__column--size-4--m___sHLxN {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 4);
  }
  .Grid-module__column--size-5--m___vAB\+D {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 5);
  }
  .Grid-module__column--size-6--m___e9Zmx {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 6);
  }
  .Grid-module__column--size-half--m___3bJ5E {
    --bui_grid_column_size: 2;
  }
  .Grid-module__column--size-7--m___3ayAX {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 7);
  }
  .Grid-module__column--size-8--m___JClvD {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 8);
  }
  .Grid-module__column--size-9--m___v\+qFf {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 9);
  }
  .Grid-module__column--size-10--m___fYgwW {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 10);
  }
  .Grid-module__column--size-11--m___IbfxW {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 11);
  }
  .Grid-module__column--size-12--m___sP76B {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 12);
  }
  .Grid-module__column--size-full--m___9A7bC {
    --bui_grid_column_size: 1;
  }
}
@media (min-width: 1024px) {
  .Grid-module__column--size-1--l___mq4q4 {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 1);
  }
  .Grid-module__column--size-2--l___NooTI {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 2);
  }
  .Grid-module__column--size-3--l___7VnEL {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 3);
  }
  .Grid-module__column--size-4--l___ngDM5 {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 4);
  }
  .Grid-module__column--size-5--l___Mi5gQ {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 5);
  }
  .Grid-module__column--size-6--l___yzgRn {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 6);
  }
  .Grid-module__column--size-half--l___EYNq8 {
    --bui_grid_column_size: 2;
  }
  .Grid-module__column--size-7--l___w1z2w {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 7);
  }
  .Grid-module__column--size-8--l___rjE0a {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 8);
  }
  .Grid-module__column--size-9--l___jkrIz {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 9);
  }
  .Grid-module__column--size-10--l___B\+QCq {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 10);
  }
  .Grid-module__column--size-11--l___bOIQ5 {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 11);
  }
  .Grid-module__column--size-12--l___udqzW {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 12);
  }
  .Grid-module__column--size-full--l___WY6c9 {
    --bui_grid_column_size: 1;
  }
}
@media (min-width: 1280px) {
  .Grid-module__column--size-1--xl___t6ozs {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 1);
  }
  .Grid-module__column--size-2--xl___O\+MYf {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 2);
  }
  .Grid-module__column--size-3--xl___6WkMf {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 3);
  }
  .Grid-module__column--size-4--xl___0cG7f {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 4);
  }
  .Grid-module__column--size-5--xl___5TCFL {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 5);
  }
  .Grid-module__column--size-6--xl___TuuH1 {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 6);
  }
  .Grid-module__column--size-half--xl___Teq1n {
    --bui_grid_column_size: 2;
  }
  .Grid-module__column--size-7--xl___rbVb4 {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 7);
  }
  .Grid-module__column--size-8--xl___i94NM {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 8);
  }
  .Grid-module__column--size-9--xl___O4UMq {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 9);
  }
  .Grid-module__column--size-10--xl___uvfZN {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 10);
  }
  .Grid-module__column--size-11--xl___n6mB0 {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 11);
  }
  .Grid-module__column--size-12--xl___1Z-W\+ {
    --bui_grid_column_size: calc(var(--bui_grid_columns) / 12);
  }
  .Grid-module__column--size-full--xl___h6AV0 {
    --bui_grid_column_size: 1;
  }
}
.Grid-module__column--offset-1___6m9Qg {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 1;
}
.Grid-module__column--offset-2___Dgken {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 2;
}
.Grid-module__column--offset-3___Ksqgw {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 3;
}
.Grid-module__column--offset-4___HmKOW {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 4;
}
.Grid-module__column--offset-5___v5AUM {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 5;
}
.Grid-module__column--offset-6___duVVY {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 6;
}
.Grid-module__column--offset-half___Jbt3x {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: calc(var(--bui_grid_columns) * 0.5);
}
.Grid-module__column--offset-7___1aTb9 {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 7;
}
.Grid-module__column--offset-8___gseVG {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 8;
}
.Grid-module__column--offset-9___S8ITG {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 9;
}
.Grid-module__column--offset-10___\+KAs- {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 10;
}
.Grid-module__column--offset-11___KDBUD {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 11;
}
.Grid-module__column--offset-12___uPtxy {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: 12;
}
.Grid-module__column--offset-full___A4VZ0 {
  --bui_grid_column_offset_auto: initial;
  --bui_grid_column_offset: var(--bui_grid_columns);
}
.Grid-module__column--offset-auto___LpFUD {
  --bui_grid_column_offset: initial;
  --bui_grid_column_offset_auto: auto;
}
@media (min-width: 576px) {
  .Grid-module__column--offset-1--m___3MTjc {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 1;
  }
  .Grid-module__column--offset-2--m___AOX-Y {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 2;
  }
  .Grid-module__column--offset-3--m___2J2Cr {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 3;
  }
  .Grid-module__column--offset-4--m___2ECDT {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 4;
  }
  .Grid-module__column--offset-5--m___AtIeO {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 5;
  }
  .Grid-module__column--offset-6--m___0JNa8 {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 6;
  }
  .Grid-module__column--offset-half--m___BROJJ {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: calc(var(--bui_grid_columns) * 0.5);
  }
  .Grid-module__column--offset-7--m___arL2e {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 7;
  }
  .Grid-module__column--offset-8--m___BsYDR {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 8;
  }
  .Grid-module__column--offset-9--m___aZuAh {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 9;
  }
  .Grid-module__column--offset-10--m___awguH {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 10;
  }
  .Grid-module__column--offset-11--m___RR1CC {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 11;
  }
  .Grid-module__column--offset-12--m___-szvq {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 12;
  }
  .Grid-module__column--offset-full--m___ed-ZO {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: var(--bui_grid_columns);
  }
  .Grid-module__column--offset-auto--m___S4hg3 {
    --bui_grid_column_offset: initial;
    --bui_grid_column_offset_auto: auto;
  }
}
@media (min-width: 1024px) {
  .Grid-module__column--offset-1--l___qjOmz {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 1;
  }
  .Grid-module__column--offset-2--l___KtgoD {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 2;
  }
  .Grid-module__column--offset-3--l___XJ43P {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 3;
  }
  .Grid-module__column--offset-4--l___\+\+j0L {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 4;
  }
  .Grid-module__column--offset-5--l___TW0o7 {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 5;
  }
  .Grid-module__column--offset-6--l___q1O0Y {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 6;
  }
  .Grid-module__column--offset-half--l___Y8eGa {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: calc(var(--bui_grid_columns) * 0.5);
  }
  .Grid-module__column--offset-7--l___9c86Z {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 7;
  }
  .Grid-module__column--offset-8--l___P\+tvs {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 8;
  }
  .Grid-module__column--offset-9--l___PAnPp {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 9;
  }
  .Grid-module__column--offset-10--l___y410r {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 10;
  }
  .Grid-module__column--offset-11--l___QvFlz {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 11;
  }
  .Grid-module__column--offset-12--l___Nv2x4 {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 12;
  }
  .Grid-module__column--offset-full--l___aVpBl {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: var(--bui_grid_columns);
  }
  .Grid-module__column--offset-auto--l___MWEuj {
    --bui_grid_column_offset: initial;
    --bui_grid_column_offset_auto: auto;
  }
}
@media (min-width: 1280px) {
  .Grid-module__column--offset-1--xl___sHtxi {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 1;
  }
  .Grid-module__column--offset-2--xl___Pqq\+c {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 2;
  }
  .Grid-module__column--offset-3--xl___5-SNU {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 3;
  }
  .Grid-module__column--offset-4--xl___AP-f3 {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 4;
  }
  .Grid-module__column--offset-5--xl___CYCYU {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 5;
  }
  .Grid-module__column--offset-6--xl___8enfF {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 6;
  }
  .Grid-module__column--offset-half--xl___CKRy2 {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: calc(var(--bui_grid_columns) * 0.5);
  }
  .Grid-module__column--offset-7--xl___bYrdq {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 7;
  }
  .Grid-module__column--offset-8--xl___hbFAK {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 8;
  }
  .Grid-module__column--offset-9--xl___vXXF\+ {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 9;
  }
  .Grid-module__column--offset-10--xl___NdXSb {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 10;
  }
  .Grid-module__column--offset-11--xl___BwL-r {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 11;
  }
  .Grid-module__column--offset-12--xl___QYbab {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: 12;
  }
  .Grid-module__column--offset-full--xl___1g93I {
    --bui_grid_column_offset_auto: initial;
    --bui_grid_column_offset: var(--bui_grid_columns);
  }
  .Grid-module__column--offset-auto--xl___04uyX {
    --bui_grid_column_offset: initial;
    --bui_grid_column_offset_auto: auto;
  }
}
.Grid-module__column___LZ4Pp {
  --bui_grid_number_of_gaps: calc(var(--bui_grid_column_size) - 1);
  --bui_grid_column_gap_width: var(--bui_stack_gap);
  --bui_grid_column_width: calc(
    100% / var(--bui_grid_column_size) - var(--bui_grid_column_gap_width) * var(--bui_grid_number_of_gaps) /
      var(--bui_grid_column_size)
  );
  width: var(--bui_grid_column_width);
  --bui_grid_column_offset_fraction: calc(var(--bui_grid_column_offset, 0) / var(--bui_grid_columns));
  margin-inline-start: var(
    --bui_grid_column_offset_auto,
    calc(
      var(--bui_grid_column_offset_fraction) * 100% + var(--bui_grid_column_offset_fraction) *
        var(--bui_grid_column_gap_width)
    )
  );
}
.HiddenVisually-module__root___8a2fR {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
.InputCheckbox-module__root___9JUa- {
  position: relative;
}
.InputCheckbox-module__root___9JUa-:active .InputCheckbox-module__field___DMcQP {
  transform: scale(0.94);
}
.InputCheckbox-module__input___g0cPV {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  opacity: 0;
  inset-block-start: 0;
  inset-inline-start: 0;
  border: 0;
  margin: 0;
}
.InputCheckbox-module__hitbox___6fRkf {
  display: none;
}
@media (max-width: 575px) {
  .InputCheckbox-module__hitbox___6fRkf {
    display: block;
    position: absolute;
    inset-block-start: 50%;
    transform: translateY(-50%);
    height: 44px;
    width: 100%;
  }
}
.InputCheckbox-module__container___zwlzU {
  cursor: pointer;
}
.InputCheckbox-module__icon___YyGWo {
  align-items: center;
  display: inline-flex;
  height: var(--bui_font_body_2_line-height);
}
.InputCheckbox-module__field___DMcQP {
  height: calc(var(--bui_spacing_1x) * 5);
  width: calc(var(--bui_spacing_1x) * 5);
  background-color: var(--bui_color_background_elevation_one);
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  border-radius: var(--bui_border_radius_100);
  position: relative;
  color: var(--bui_color_on_action_background);
  box-sizing: border-box;
  flex-shrink: 0;
  transition: var(--bui_animation_press);
  transition-property: background-color, border-color;
}
.InputCheckbox-module__checkbox-icon___2UmzN {
  position: absolute;
  left: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity var(--bui_animation_press);
}
.InputCheckbox-module__bottom___DQSks {
  margin-block-start: var(--bui_spacing_1x);
}
.InputCheckbox-module__root--error___ovusM .InputCheckbox-module__field___DMcQP {
  border-color: var(--bui_color_destructive_border);
  border-width: var(--bui_border_width_200);
}
.InputCheckbox-module__input___g0cPV:indeterminate
  ~ .InputCheckbox-module__container___zwlzU
  .InputCheckbox-module__field___DMcQP {
  border-color: var(--bui_color_action_background);
  background-color: var(--bui_color_action_background);
}
.InputCheckbox-module__input___g0cPV:indeterminate
  ~ .InputCheckbox-module__container___zwlzU
  .InputCheckbox-module__field___DMcQP:before {
  content: '';
  position: absolute;
  left: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
  width: var(--bui_spacing_3x);
  height: var(--bui_spacing_half);
  background: var(--bui_color_on_action_background);
  border-radius: 1px;
}
.InputCheckbox-module__input___g0cPV:checked:focus
  ~ .InputCheckbox-module__container___zwlzU
  .InputCheckbox-module__field___DMcQP,
.InputCheckbox-module__input___g0cPV:checked
  ~ .InputCheckbox-module__container___zwlzU
  .InputCheckbox-module__field___DMcQP {
  border-color: var(--bui_color_action_background);
  background: var(--bui_color_action_background);
}
.InputCheckbox-module__input___g0cPV:checked
  ~ .InputCheckbox-module__container___zwlzU
  .InputCheckbox-module__checkbox-icon___2UmzN {
  opacity: 1;
}
.InputCheckbox-module__input___g0cPV:disabled
  ~ .InputCheckbox-module__container___zwlzU
  .InputCheckbox-module__field___DMcQP {
  border-color: var(--bui_color_border_disabled);
  border-width: var(--bui_border_width_100);
  background-color: var(--bui_color_background_disabled_alt);
}
.InputCheckbox-module__input___g0cPV:disabled:checked
  ~ .InputCheckbox-module__container___zwlzU
  .InputCheckbox-module__field___DMcQP,
.InputCheckbox-module__input___g0cPV:disabled:indeterminate ~ .InputCheckbox-module__field___DMcQP {
  background-color: var(--bui_color_background_disabled);
  border-color: var(--bui_color_background_disabled);
}
.InputCheckbox-module__input___g0cPV:disabled ~ .InputCheckbox-module__container___zwlzU {
  cursor: not-allowed;
}
[data-bui-keyboard]
  .InputCheckbox-module__input___g0cPV:focus
  ~ .InputCheckbox-module__container___zwlzU
  .InputCheckbox-module__field___DMcQP {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.InputCard-module__root___AIEpL {
  display: block;
  transition: var(--bui_animation_press);
  transition-property: box-shadow;
}
.InputCard-module__root___AIEpL:hover {
  cursor: pointer;
}
.InputCard-module__root--checked___pzWHi {
  border-color: transparent;
  box-shadow: 0 0 0 var(--bui_border_width_200) var(--bui_color_action_border);
}
.InputCard-module__root--error___9bYju {
  border-color: transparent;
  box-shadow: 0 0 0 var(--bui_border_width_200) var(--bui_color_destructive_border);
}
.InputCard-module__root--disabled___mQEBs {
  border-color: var(--bui_color_border_disabled);
  background-color: var(--bui_color_background_disabled_alt);
  box-shadow: none;
}
.InputCard-module__root--disabled___mQEBs:hover {
  cursor: not-allowed;
}
.InputCard-module__root--elevated___mMG6U.InputCard-module__root--checked___pzWHi {
  box-shadow: var(--bui_shadow_100), 0 0 0 var(--bui_border_width_200) var(--bui_color_action_border);
}
.InputCard-module__root--elevated___mMG6U.InputCard-module__root--error___9bYju {
  box-shadow: var(--bui_shadow_100), 0 0 0 var(--bui_border_width_200) var(--bui_color_destructive_border);
}
.InputCard-module__root--elevated___mMG6U.InputCard-module__root--disabled___mQEBs {
  box-shadow: var(--bui_shadow_100);
}
.Chip-module__root___Xf3d7 {
  display: inline-flex;
  vertical-align: top;
}
.Chip-module__root___Xf3d7:active .Chip-module__trigger___-3qDa {
  transform: scale(0.98);
}
.Chip-module__trigger___-3qDa {
  padding: calc(var(--bui_spacing_2x) - var(--bui_border_width_100))
    calc(var(--bui_spacing_3x) - var(--bui_border_width_100));
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  background: var(--bui_color_background_elevation_one);
  color: var(--bui_color_foreground);
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--bui_spacing_2x);
  vertical-align: top;
  overflow: hidden;
  border-radius: 999px;
  transition: var(--bui_animation_press);
  transition-property: transform, border-color, color;
}
.Chip-module__trigger___-3qDa:before {
  content: '';
  position: absolute;
  inset: 0;
  pointer-events: none;
  transition: background-color var(--bui_animation_press);
}
.Chip-module__input___3DLzE[data-bui-focus] + .Chip-module__trigger___-3qDa:before,
.Chip-module__trigger___-3qDa:hover:before,
.Chip-module__trigger___-3qDa[data-bui-focus]:before {
  background-color: var(--bui_color_highlighted_alt);
}
.Chip-module__root--wide___dpCby,
.Chip-module__root--wide___dpCby .Chip-module__trigger___-3qDa {
  width: 100%;
}
.Chip-module__root--elevated___dnuGJ .Chip-module__trigger___-3qDa {
  box-shadow: var(--bui_shadow_100);
  background: var(--bui_color_background_elevation_two);
  border-color: transparent;
}
.Chip-module__input___3DLzE:checked + .Chip-module__trigger___-3qDa,
.Chip-module__root--selected___cwbT5 .Chip-module__trigger___-3qDa {
  color: var(--bui_color_action_foreground);
  border-color: var(--bui_color_action_border);
}
.Chip-module__input___3DLzE:checked + .Chip-module__trigger___-3qDa:before,
.Chip-module__input___3DLzE:checked + .Chip-module__trigger___-3qDa:hover:before,
.Chip-module__input___3DLzE[data-bui-focus]:checked + .Chip-module__trigger___-3qDa:before,
.Chip-module__root--selected___cwbT5 .Chip-module__trigger___-3qDa:before,
.Chip-module__root--selected___cwbT5 .Chip-module__trigger___-3qDa:hover:before,
.Chip-module__root--selected___cwbT5 .Chip-module__trigger___-3qDa[data-bui-focus]:before {
  background-color: var(--bui_color_action_highlighted_alt);
}
.Chip-module__root--disabled___6xm6F .Chip-module__input___3DLzE:checked + .Chip-module__trigger___-3qDa,
.Chip-module__root--disabled___6xm6F .Chip-module__trigger___-3qDa {
  cursor: not-allowed;
  background-color: var(--bui_color_background_disabled_alt);
  border-color: var(--bui_color_border_disabled);
  color: var(--bui_color_foreground_disabled);
}
.Chip-module__root--disabled___6xm6F .Chip-module__input___3DLzE:checked + .Chip-module__trigger___-3qDa:before,
.Chip-module__root--disabled___6xm6F .Chip-module__input___3DLzE:checked + .Chip-module__trigger___-3qDa:hover:before,
.Chip-module__root--disabled___6xm6F
  .Chip-module__input___3DLzE[data-bui-focus]:checked
  + .Chip-module__trigger___-3qDa:before,
.Chip-module__root--disabled___6xm6F .Chip-module__trigger___-3qDa:before,
.Chip-module__root--disabled___6xm6F .Chip-module__trigger___-3qDa:hover:before,
.Chip-module__root--disabled___6xm6F .Chip-module__trigger___-3qDa[data-bui-focus]:before {
  background: transparent;
}
[data-bui-keyboard] .Chip-module__input___3DLzE:focus + .Chip-module__trigger___-3qDa {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.InputSelect-module__wrapper___FcKCf {
  position: relative;
  z-index: 0;
}
.InputSelect-module__field___Dej7y {
  color: var(--bui_color_foreground);
  background: var(--bui_color_background_elevation_one);
  border-radius: var(--bui_border_radius_100);
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  display: block;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: start;
  text-overflow: ellipsis;
  z-index: var(--bui_z_index_1);
  outline: none;
  font-size: var(--bui_font_body_1_font-size);
  font-weight: var(--bui_font_body_1_font-weight);
  line-height: var(--bui_font_body_1_line-height);
  font-family: var(--bui_font_body_1_font-family);
  padding: calc(var(--bui_spacing_1x) - var(--bui_border_width_100));
  min-height: calc(var(--bui_spacing_1x) * 9);
  line-height: calc(var(--bui_spacing_1x) * 7) !important;
}
.InputSelect-module__field___Dej7y::-ms-expand {
  display: none;
}
[data-bui-keyboard] .InputSelect-module__field___Dej7y:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.InputSelect-module__start-content___v2CL- {
  position: absolute;
  inset-block-start: 50%;
  vertical-align: middle;
  fill: var(--bui_color_foreground);
  pointer-events: none;
  transform: translateY(-50%);
}
.InputSelect-module__start-avatar-wrapper___MvV4G {
  height: calc(var(--bui_spacing_1x) * 5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.InputSelect-module__start-avatar___LXabO {
  flex-shrink: 0;
}
.InputSelect-module__endIconContainer___BadfH {
  position: absolute;
  inset-block-start: 50%;
  display: flex;
  align-items: center;
  pointer-events: none;
  transform: translateY(-50%);
  z-index: var(--bui_z_index_1);
}
.InputSelect-module__root--borderless___yWX-T .InputSelect-module__field___Dej7y {
  border-color: transparent;
}
.InputSelect-module__arrowIcon___sMyiX {
  margin-inline-start: var(--bui_spacing_3x);
}
.InputSelect-module__root--size-medium___DZl\+9 .InputSelect-module__field___Dej7y {
  padding-inline-end: calc(var(--bui_spacing_8x) + var(--bui_spacing_1x));
  padding-inline-start: var(--bui_spacing_2x);
}
.InputSelect-module__root--size-medium___DZl\+9 .InputSelect-module__start-content___v2CL- {
  inset-inline-start: var(--bui_spacing_2x);
}
.InputSelect-module__root--size-medium___DZl\+9 .InputSelect-module__endIconContainer___BadfH {
  inset-inline-end: var(--bui_spacing_2x);
}
.InputSelect-module__root--size-large___dN7LJ .InputSelect-module__field___Dej7y {
  padding-inline-end: calc(var(--bui_spacing_1x) * 11);
  padding-inline-start: var(--bui_spacing_3x);
  min-height: calc(var(--bui_spacing_1x) * 12);
  line-height: calc(var(--bui_spacing_1x) * 10) !important;
}
.InputSelect-module__root--size-large___dN7LJ .InputSelect-module__start-content___v2CL- {
  inset-inline-start: var(--bui_spacing_3x);
}
.InputSelect-module__root--size-large___dN7LJ .InputSelect-module__endIconContainer___BadfH {
  inset-inline-end: var(--bui_spacing_3x);
}
.InputSelect-module__root--disabled___t1irT .InputSelect-module__arrowIcon___sMyiX {
  fill: var(--bui_color_foreground_disabled);
}
.InputSelect-module__field___Dej7y[disabled],
.InputSelect-module__root--disabled___t1irT .InputSelect-module__field___Dej7y {
  border-color: var(--bui_color_border_disabled);
  background-color: var(--bui_color_background_disabled_alt);
  cursor: not-allowed;
  color: var(--bui_color_foreground_disabled);
}
.InputSelect-module__root--borderless___yWX-T.InputSelect-module__field___Dej7y[disabled],
.InputSelect-module__root--borderless___yWX-T.InputSelect-module__root--disabled___t1irT
  .InputSelect-module__field___Dej7y {
  border-color: transparent;
}
.InputSelect-module__root--disabled___t1irT .InputSelect-module__start-icon___z8F0q {
  fill: var(--bui_color_foreground_disabled);
}
.InputSelect-module__root--size-medium___DZl\+9.InputSelect-module__root--status-error___B0s4E
  .InputSelect-module__field___Dej7y,
.InputSelect-module__root--size-medium___DZl\+9.InputSelect-module__root--status-success___evHbB
  .InputSelect-module__field___Dej7y {
  padding-inline-end: calc(var(--bui_spacing_1x) * 17);
}
.InputSelect-module__root--size-large___dN7LJ.InputSelect-module__root--status-error___B0s4E
  .InputSelect-module__field___Dej7y,
.InputSelect-module__root--size-large___dN7LJ.InputSelect-module__root--status-success___evHbB
  .InputSelect-module__field___Dej7y {
  padding-inline-end: calc(var(--bui_spacing_1x) * 19);
}
.InputSelect-module__root--placeholder___cI\+09 .InputSelect-module__field___Dej7y {
  color: var(--bui_color_foreground_alt);
}
.InputSelect-module__root--status-error___B0s4E .InputSelect-module__field___Dej7y {
  border-color: var(--bui_color_destructive_border);
}
.InputSelect-module__root--size-medium___DZl\+9.InputSelect-module__root--has-start-content___dCpMO:not(
    .InputSelect-module__root--hide-value___dNj2G
  )
  .InputSelect-module__field___Dej7y {
  padding-inline-start: calc(var(--bui_spacing_8x) + var(--bui_spacing_2x));
}
.InputSelect-module__root--size-large___dN7LJ.InputSelect-module__root--has-start-content___dCpMO:not(
    .InputSelect-module__root--hide-value___dNj2G
  )
  .InputSelect-module__field___Dej7y {
  padding-inline-start: calc(var(--bui_spacing_8x) + var(--bui_spacing_3x));
}
.InputSelect-module__root--hide-value___dNj2G .InputSelect-module__field___Dej7y {
  font-size: 0;
  width: 100%;
  padding-inline-start: 0;
  padding-inline-end: 0;
}
.InputSelect-module__root--hide-value___dNj2G.InputSelect-module__root--size-medium___DZl\+9.InputSelect-module__root--has-start-content___dCpMO {
  width: calc(var(--bui_spacing_1x) * 18);
}
.InputSelect-module__root--hide-value___dNj2G.InputSelect-module__root--size-medium___DZl\+9.InputSelect-module__root--has-start-content___dCpMO.InputSelect-module__root--status-error___B0s4E {
  width: calc(var(--bui_spacing_1x) * 26);
}
.InputSelect-module__root--hide-value___dNj2G.InputSelect-module__root--size-large___dN7LJ.InputSelect-module__root--has-start-content___dCpMO {
  width: calc(var(--bui_spacing_1x) * 20);
}
.InputSelect-module__root--hide-value___dNj2G.InputSelect-module__root--size-large___dN7LJ.InputSelect-module__root--has-start-content___dCpMO.InputSelect-module__root--status-error___B0s4E {
  width: calc(var(--bui_spacing_1x) * 28);
}
@media (min-width: 576px) {
  .InputSelect-module__field___Dej7y {
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height);
    font-family: var(--bui_font_body_2_font-family);
  }
}
.InputMultiselect-module__fields___BPnlg {
  padding: var(--bui_spacing_4x);
  max-height: calc(var(--bui_spacing_1x) * 50);
  overflow: auto;
}
.InputText-module__root___evpDy {
  position: relative;
}
.InputText-module__root___evpDy:focus-within {
  z-index: var(--bui_z_index_1);
}
.InputText-module__content___Wxp3D {
  display: flex;
  position: relative;
  isolation: isolate;
}
.InputText-module__field___51pWV {
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 100%;
}
.InputText-module__addon___C1yas {
  color: var(--bui_color_foreground_alt);
  padding: 0 var(--bui_spacing_2x);
  text-align: center;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.InputText-module__addon___C1yas:after {
  content: '';
  position: absolute;
  width: var(--bui_border_width_100);
  inset-block: var(--bui_spacing_2x);
  background: var(--bui_color_border_alt);
}
.InputText-module__addon___C1yas:first-child:after {
  inset-inline-end: 0;
}
.InputText-module__addon___C1yas:last-child:after {
  inset-inline-start: 0;
}
.InputText-module__decorator___7Jheu {
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  background-color: var(--bui_color_background_elevation_one);
  border-radius: var(--bui_border_radius_100);
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  transition: var(--bui_animation_press);
  transition-property: box-shadow, border-color;
}
.InputText-module__control___0nwCm {
  position: relative;
  z-index: 5;
  border-radius: var(--bui_border_radius_100);
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  background: none;
  display: block;
  flex-grow: 1;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
  height: calc(var(--bui_spacing_1x) * 9);
  outline: none;
  font-size: var(--bui_font_body_1_font-size);
  font-weight: var(--bui_font_body_1_font-weight);
  line-height: var(--bui_font_body_1_line-height);
  font-family: var(--bui_font_body_1_font-family);
}
.InputText-module__control___0nwCm::-ms-reveal {
  display: none;
}
.InputText-module__control___0nwCm:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--bui_color_foreground);
}
.InputText-module__side___uE8wZ {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}
.InputText-module__side___uE8wZ:empty {
  display: none;
}
.InputText-module__side--start___sLVBe {
  padding-inline-start: var(--bui_spacing_2x);
}
.InputText-module__side--end___ElDLe {
  padding-inline-end: var(--bui_spacing_2x);
  margin-inline-start: var(--bui_spacing_1x);
}
[data-bui-keyboard] .InputText-module__control___0nwCm:focus + .InputText-module__decorator___7Jheu {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
[data-bui-keyboard] .InputText-module__decorator___7Jheu {
  transition-property: none;
}
body:not([data-bui-keyboard])
  .InputText-module__root___evpDy:not(.InputText-module__root--borderless___Koi7q)
  .InputText-module__control___0nwCm:focus
  + .InputText-module__decorator___7Jheu {
  box-shadow: 0 0 0 var(--bui_border_width_200) var(--bui_color_action_border);
  border-color: transparent;
}
.InputText-module__control___0nwCm::-moz-placeholder {
  color: var(--bui_color_foreground_alt);
}
.InputText-module__control___0nwCm::placeholder {
  color: var(--bui_color_foreground_alt);
}
.InputText-module__control___0nwCm:disabled::-moz-placeholder {
  color: var(--bui_color_foreground_disabled);
}
.InputText-module__control___0nwCm:disabled::placeholder {
  color: var(--bui_color_foreground_disabled);
}
.InputText-module__control___0nwCm:disabled {
  cursor: not-allowed;
  color: var(--bui_color_foreground_disabled);
  -webkit-text-fill-color: var(--bui_color_foreground_disabled);
}
.InputText-module__control___0nwCm:-webkit-autofill:disabled {
  -webkit-text-fill-color: var(--bui_color_foreground_disabled);
}
.InputText-module__control___0nwCm:disabled + .InputText-module__decorator___7Jheu {
  border-color: var(--bui_color_border_disabled);
  background-color: var(--bui_color_background_disabled_alt);
  cursor: not-allowed;
}
.InputText-module__root--size-large___J0vxF .InputText-module__addon___C1yas {
  padding: 0 var(--bui_spacing_3x);
}
.InputText-module__root--size-large___J0vxF .InputText-module__control___0nwCm {
  height: calc(var(--bui_spacing_1x) * 12);
  padding: calc(var(--bui_spacing_2x) + var(--bui_spacing_half)) var(--bui_spacing_3x);
}
.InputText-module__root--size-large___J0vxF .InputText-module__side--start___sLVBe {
  padding-inline-start: var(--bui_spacing_3x);
}
.InputText-module__root--size-large___J0vxF .InputText-module__side--end___ElDLe {
  padding-inline-end: var(--bui_spacing_3x);
  margin-inline-start: var(--bui_spacing_2x);
}
.InputText-module__root--size-large___J0vxF .InputText-module__addon___C1yas:after {
  inset-block: var(--bui_spacing_3x);
}
.InputText-module__root--borderless___Koi7q.InputText-module__root--status-error___3urWx
  .InputText-module__decorator___7Jheu,
.InputText-module__root--status-error___3urWx .InputText-module__decorator___7Jheu {
  border-color: var(--bui_color_destructive_border);
}
.InputText-module__root--disabled___sZ2O\+ .InputText-module__addon___C1yas {
  color: var(--bui_color_foreground_disabled);
}
.InputText-module__root--borderless___Koi7q
  .InputText-module__control___0nwCm:disabled
  + .InputText-module__decorator___7Jheu,
.InputText-module__root--borderless___Koi7q .InputText-module__decorator___7Jheu {
  border-color: transparent;
}
@media (min-width: 576px) {
  .InputText-module__control___0nwCm {
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height);
    font-family: var(--bui_font_body_2_font-family);
  }
}
.InputRadio-module__root___OoDwu {
  position: relative;
}
.InputRadio-module__root___OoDwu:active .InputRadio-module__field___6QysP {
  transform: scale(0.94);
}
.InputRadio-module__input___sjxUA {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  opacity: 0;
  inset-inline-start: 0;
  inset-block-start: 0;
  border: 0;
  margin: 0;
}
.InputRadio-module__hitbox___jXe9Z {
  display: none;
}
@media (max-width: 575px) {
  .InputRadio-module__hitbox___jXe9Z {
    display: block;
    position: absolute;
    inset-block-start: 50%;
    transform: translateY(-50%);
    height: 44px;
    width: 100%;
  }
}
.InputRadio-module__container___rpZRV {
  cursor: pointer;
}
.InputRadio-module__icon___hoXd- {
  align-items: center;
  display: inline-flex;
  height: var(--bui_font_body_2_line-height);
}
.InputRadio-module__field___6QysP {
  height: calc(var(--bui_spacing_1x) * 5);
  width: calc(var(--bui_spacing_1x) * 5);
  background-color: var(--bui_color_background_elevation_one);
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  transition: var(--bui_animation_press);
  transition-property: background-color, border-color, transform;
}
.InputRadio-module__field___6QysP:before {
  content: '';
  position: absolute;
  height: calc(var(--bui_spacing_2x) + var(--bui_spacing_half));
  width: calc(var(--bui_spacing_2x) + var(--bui_spacing_half));
  inset-block-start: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--bui_color_on_action_background);
  opacity: 0;
  border-radius: 50%;
  transition: opacity var(--bui_animation_press);
}
.InputRadio-module__input___sjxUA:checked:focus
  ~ .InputRadio-module__container___rpZRV
  .InputRadio-module__field___6QysP,
.InputRadio-module__input___sjxUA:checked ~ .InputRadio-module__container___rpZRV .InputRadio-module__field___6QysP {
  border-color: var(--bui_color_action_background);
  border-width: var(--bui_border_width_200);
}
.InputRadio-module__input___sjxUA:checked
  ~ .InputRadio-module__container___rpZRV
  .InputRadio-module__field___6QysP:before {
  background-color: var(--bui_color_action_foreground);
  opacity: 1;
}
.InputRadio-module__root--error___1a-6a .InputRadio-module__field___6QysP {
  border-color: var(--bui_color_destructive_border);
  border-width: var(--bui_border_width_200);
}
.InputRadio-module__input___sjxUA:disabled ~ .InputRadio-module__container___rpZRV {
  cursor: not-allowed;
}
.InputRadio-module__input___sjxUA:disabled ~ .InputRadio-module__container___rpZRV .InputRadio-module__field___6QysP {
  border-color: var(--bui_color_border_disabled);
  border-width: var(--bui_border_width_100);
  background-color: var(--bui_color_background_disabled_alt);
}
.InputRadio-module__input___sjxUA:disabled
  ~ .InputRadio-module__container___rpZRV
  .InputRadio-module__field___6QysP:before {
  background-color: var(--bui_color_border_disabled);
}
[data-bui-keyboard]
  .InputRadio-module__input___sjxUA:focus
  ~ .InputRadio-module__container___rpZRV
  .InputRadio-module__field___6QysP {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.InputSlider-module__input___n0jS\+ {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  border: 0;
  overflow: hidden;
}
.InputSlider-module__bar___97APu {
  width: 100%;
  padding: var(--bui_spacing_4x) 0;
  border-radius: var(--bui_border_radius_100);
  position: relative;
  cursor: grab;
}
.InputSlider-module__bar___97APu:before {
  width: 100%;
  background-color: var(--bui_color_border_alt);
  content: '';
}
.InputSlider-module__selected-area___SiLqX {
  inset-inline: 0;
  z-index: 1;
  background-color: var(--bui_color_action_background);
}
.InputSlider-module__bar___97APu:before,
.InputSlider-module__selected-area___SiLqX {
  height: var(--bui_spacing_1x);
  margin-block-start: calc(var(--bui_spacing_half) * -1);
  border-radius: var(--bui_border_radius_100);
  position: absolute;
}
.InputSlider-module__handle___zW4jE {
  position: absolute;
  transform: translateY(-50%);
  inset-block-start: 50%;
  height: 0;
  width: 0;
  z-index: 2;
}
.InputSlider-module__handle___zW4jE:after,
.InputSlider-module__handle___zW4jE:before {
  content: '';
  display: block;
  height: var(--bui_spacing_4x);
  width: var(--bui_spacing_4x);
  position: absolute;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  transition: var(--bui_animation_hover);
  transition-property: transform, box-shadow;
  border-radius: 50%;
  inset-block-start: 50%;
  left: 50%;
}
.InputSlider-module__handle___zW4jE:before {
  background-color: var(--bui_color_action_background);
  outline: none;
  z-index: 1;
}
.InputSlider-module__handle___zW4jE:after {
  transform: translate(-50%, -50%) scale(0);
  box-shadow: 0 0 0 var(--bui_spacing_3x) var(--bui_color_action_background);
  opacity: 0.24;
}
.InputSlider-module__handle--active___J-Zqa {
  z-index: 3;
}
.InputSlider-module__handle--active___J-Zqa:before {
  background-color: var(--bui_color_action_highlighted);
  transform: translate(-50%, -50%) scale(1.25);
}
.InputSlider-module__handle--active___J-Zqa:after {
  transform: translate(-50%, -50%) scale(1);
}
.InputSlider-module__input___n0jS\+:focus + .InputSlider-module__handle___zW4jE:before {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.InputSlider-module__input___n0jS\+:focus + .InputSlider-module__handle___zW4jE:after {
  content: none;
}
.InputSlider-module__display___UZ9fZ {
  line-height: 20px;
  font-variant-numeric: tabular-nums;
}
.InputSlider-module__handle-tooltip___pN7q0 {
  position: absolute;
  transform: translate(-50%, calc(var(--bui_spacing_1x) * 5));
  padding-block-end: calc(var(--bui_spacing_1x) * 10);
  left: 50%;
  inset-block-end: 100%;
  opacity: 0;
  visibility: hidden;
}
.InputSlider-module__handle-tooltip--hidden-content___usdOz .InputSlider-module__handle-tooltip-content___Oq7q1 {
  opacity: 0;
}
.InputSlider-module__handle-tooltip-content___Oq7q1 {
  background: var(--bui_color_background_inverted);
  color: var(--bui_color_foreground_inverted);
  display: inline-block;
  border-radius: var(--bui_border_radius_100);
  padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
  max-width: calc(var(--bui_spacing_8x) * 10);
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
  z-index: var(--bui_z_index_1);
  box-shadow: var(--bui_shadow_100);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: var(--bui_font_body_2_font-size);
  font-weight: var(--bui_font_body_2_font-weight);
  line-height: var(--bui_font_body_2_line-height);
  font-family: var(--bui_font_body_2_font-family);
}
.InputSlider-module__handle-tooltip___pN7q0:before {
  content: '';
  position: absolute;
  inset-inline: 0;
  inset-block-end: calc(var(--bui_spacing_1x) * 9);
  margin: auto;
  width: 0;
  height: 0;
  border: calc(var(--bui_spacing_3x) / 2) solid var(--bui_color_background_inverted);
  border-left-color: transparent;
  border-top-color: transparent;
  transform: rotate(45deg);
}
.InputSlider-module__handle--visible___uU0pS .InputSlider-module__handle-tooltip___pN7q0,
.InputSlider-module__handle___zW4jE:hover .InputSlider-module__handle-tooltip___pN7q0,
.InputSlider-module__input___n0jS\+:focus
  + .InputSlider-module__handle___zW4jE
  .InputSlider-module__handle-tooltip___pN7q0 {
  display: inherit;
  opacity: 1;
  visibility: visible;
}
.InputSlider-module__input___n0jS\+[disabled] ~ .InputSlider-module__selected-area___SiLqX {
  background-color: var(--bui_color_foreground_disabled_alt);
}
.InputSlider-module__input___n0jS\+[disabled] ~ .InputSlider-module__handle___zW4jE,
.InputSlider-module__input___n0jS\+[disabled] ~ .InputSlider-module__handle___zW4jE:active {
  cursor: not-allowed;
  background-color: var(--bui_color_foreground_disabled_alt);
}
.InputSlider-module__input___n0jS\+[disabled]
  ~ .InputSlider-module__handle___zW4jE:hover
  .InputSlider-module__handle-tooltip___pN7q0 {
  display: none;
}
.InputSlider-module__root--tooltip___cSHP- .InputSlider-module__bar___97APu {
  margin-block-start: calc(var(--bui_spacing_1x) * 7);
}
.InputStepper-module__root___1tPHA {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.InputStepper-module__input___q5kTk {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
}
.InputStepper-module__input___q5kTk:focus ~ .InputStepper-module__wrapper___72AMP {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.InputStepper-module__add___9WQEF,
.InputStepper-module__subtract___nnVZc {
  width: calc(var(--bui_spacing_8x) + var(--bui_spacing_2x));
  height: calc(var(--bui_spacing_8x) + var(--bui_spacing_2x));
  margin-inline-end: 0;
  min-width: auto;
}
.InputStepper-module__wrapper___72AMP {
  display: flex;
  align-items: center;
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  border-radius: var(--bui_border_radius_100);
}
.InputStepper-module__value___O7D6D {
  font-size: var(--bui_font_emphasized_2_font-size);
  font-weight: var(--bui_font_emphasized_2_font-weight);
  line-height: var(--bui_font_emphasized_2_line-height);
  font-family: var(--bui_font_emphasized_2_font-family);
  display: block;
  text-align: center;
  min-width: calc(var(--bui_spacing_8x) + var(--bui_spacing_1x));
  margin: 0 var(--bui_spacing_1x);
}
.InputStepper-module__label-wrapper___k5HFO {
  margin-inline-end: var(--bui_spacing_2x);
}
.InputStepper-module__label___7Gnnm {
  font-size: var(--bui_font_emphasized_2_font-size);
  font-weight: var(--bui_font_emphasized_2_font-weight);
  line-height: var(--bui_font_emphasized_2_line-height);
  font-family: var(--bui_font_emphasized_2_font-family);
}
.InputStepper-module__input___q5kTk[disabled] ~ .InputStepper-module__wrapper___72AMP {
  background-color: var(--bui_color_background_disabled_alt);
  border: var(--bui_border_width_100) solid var(--bui_color_border_disabled);
}
.InputStepper-module__input___q5kTk[disabled]
  ~ .InputStepper-module__label-wrapper___k5HFO
  .InputStepper-module__helper-text___MucWN,
.InputStepper-module__input___q5kTk[disabled]
  ~ .InputStepper-module__label-wrapper___k5HFO
  .InputStepper-module__label___7Gnnm {
  color: var(--bui_color_foreground_disabled);
}
.InputStepper-module__input___q5kTk[disabled]
  ~ .InputStepper-module__wrapper___72AMP
  .InputStepper-module__value___O7D6D {
  cursor: not-allowed;
  color: var(--bui_color_foreground_disabled);
}
.InputSwitch-module__root___540tt {
  --bui-input-switch-min-tabbable-area: 48px;
  display: flex;
  position: relative;
  z-index: 0;
}
.InputSwitch-module__label___124dY {
  margin-inline-start: var(--bui_spacing_3x);
}
.InputSwitch-module__trigger___NfwTT {
  position: absolute;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
}
.InputSwitch-module__inner___fJYdo {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
}
.InputSwitch-module__hitbox___Sn\+3k {
  display: none;
}
@media (hover: none) and (pointer: coarse) {
  .InputSwitch-module__hitbox___Sn\+3k {
    position: absolute;
    display: inline-block;
    inset-block-start: 50%;
    left: 50%;
    width: max(var(--bui-input-switch-min-tabbable-area), 100%);
    height: max(var(--bui-input-switch-min-tabbable-area), 100%);
    transform: translate(-50%, -50%);
  }
}
.InputSwitch-module__indicator___R\+\+z4 {
  background: var(--bui_color_border);
  position: relative;
  cursor: pointer;
  border-radius: 99px;
  transition: background var(--bui_animation_press);
  height: calc(var(--bui_spacing_1x) * 5);
  width: calc(var(--bui_spacing_1x) * 9);
  min-width: calc(var(--bui_spacing_1x) * 9);
}
.InputSwitch-module__indicator___R\+\+z4:before {
  content: '';
  position: absolute;
  display: block;
  background-color: var(--bui_color_white);
  border-radius: 50%;
  transition: var(--bui_animation_press);
  transition-property: background, transform;
  z-index: var(--bui_z_index_1);
  box-shadow: var(--bui_shadow_100);
  height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half));
  width: calc(var(--bui_spacing_3x) + var(--bui_spacing_half));
  inset-block-start: calc(var(--bui_spacing_1x) * 0.75);
  inset-inline-start: calc(var(--bui_spacing_1x) * 0.75);
}
.InputSwitch-module__trigger___NfwTT:checked
  + .InputSwitch-module__inner___fJYdo
  .InputSwitch-module__indicator___R\+\+z4:before {
  transform: translateX(var(--bui_spacing_4x));
  background-color: var(--bui_color_white);
}
[dir='rtl']
  .InputSwitch-module__trigger___NfwTT:checked
  + .InputSwitch-module__inner___fJYdo
  .InputSwitch-module__indicator___R\+\+z4:before {
  transform: translateX(calc(var(--bui_spacing_4x) * -1));
}
.InputSwitch-module__trigger___NfwTT:checked
  + .InputSwitch-module__inner___fJYdo
  .InputSwitch-module__indicator___R\+\+z4 {
  background-color: var(--bui_color_action_background);
}
[data-bui-keyboard]
  .InputSwitch-module__trigger___NfwTT:focus
  + .InputSwitch-module__inner___fJYdo
  .InputSwitch-module__indicator___R\+\+z4 {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.InputSwitch-module__trigger___NfwTT[disabled] + .InputSwitch-module__inner___fJYdo {
  cursor: not-allowed;
  opacity: 0.5;
}
.InputSwitch-module__trigger___NfwTT[disabled]
  + .InputSwitch-module__inner___fJYdo
  .InputSwitch-module__indicator___R\+\+z4 {
  cursor: inherit;
}
.InputSwitch-module__trigger___NfwTT[disabled] + .InputSwitch-module__inner___fJYdo .InputSwitch-module__label___124dY {
  color: var(--bui_color_foreground_disabled);
  cursor: not-allowed;
}
.InputSwitch-module__root--reversed___GjdiC .InputSwitch-module__inner___fJYdo {
  flex-direction: row-reverse;
}
.InputSwitch-module__root--reversed___GjdiC .InputSwitch-module__label___124dY {
  margin-inline-start: 0;
  margin-inline-end: var(--bui_spacing_3x);
}
.InputTextarea-module__wrapper___AzoPC {
  position: relative;
  isolation: isolate;
}
.InputTextarea-module__field___OCUQv {
  color: var(--bui_color_foreground);
  background: transparent;
  border: none;
  position: relative;
  z-index: 5;
  display: block;
  padding: var(--bui_spacing_2x);
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: start;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  outline: none;
  font-size: var(--bui_font_body_1_font-size);
  font-weight: var(--bui_font_body_1_font-weight);
  line-height: var(--bui_font_body_1_line-height);
  font-family: var(--bui_font_body_1_font-family);
}
.InputTextarea-module__decorator___6LMOh {
  content: '';
  position: absolute;
  inset: 0;
  pointer-events: none;
  border-radius: var(--bui_border_radius_100);
  background-color: var(--bui_color_background_elevation_one);
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  transition: border-width var(--bui_animation_press);
  transition-property: background-color, border-color;
}
[data-bui-keyboard] .InputTextarea-module__field___OCUQv:focus + .InputTextarea-module__decorator___6LMOh {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
body:not([data-bui-keyboard]) .InputTextarea-module__field___OCUQv:focus + .InputTextarea-module__decorator___6LMOh {
  border-width: var(--bui_border_width_200);
  border-color: var(--bui_color_action_border);
}
.InputTextarea-module__root--size-large___\+IjgW .InputTextarea-module__field___OCUQv {
  padding: calc(var(--bui_spacing_2x) + var(--bui_spacing_half)) var(--bui_spacing_3x);
}
.InputTextarea-module__field___OCUQv[disabled] {
  cursor: not-allowed;
  color: var(--bui_color_foreground_disabled);
}
.InputTextarea-module__field___OCUQv[disabled] + .InputTextarea-module__decorator___6LMOh {
  border-color: var(--bui_color_border_disabled);
  background-color: var(--bui_color_background_disabled_alt);
}
.InputTextarea-module__field___OCUQv::-moz-placeholder {
  color: var(--bui_color_foreground_alt);
}
.InputTextarea-module__field___OCUQv::placeholder {
  color: var(--bui_color_foreground_alt);
}
.InputTextarea-module__field___OCUQv[disabled]::-moz-placeholder {
  color: var(--bui_color_foreground_disabled);
}
.InputTextarea-module__field___OCUQv[disabled]::placeholder {
  color: var(--bui_color_foreground_disabled);
}
.InputTextarea-module__root___FmsJJ.InputTextarea-module__root--status-error___y94HD
  .InputTextarea-module__field___OCUQv
  + .InputTextarea-module__decorator___6LMOh {
  border-color: var(--bui_color_destructive_border);
}
@media (min-width: 576px) {
  .InputTextarea-module__field___OCUQv {
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height);
    font-family: var(--bui_font_body_2_font-family);
  }
}
.List-module__root___XDOiM {
  padding-inline-end: var(--bui_spacing_4x);
  margin: 0;
  margin-block-end: var(--bui_spacing_4x);
}
.List-module__root___XDOiM:last-child {
  margin-block-end: 0;
}
.List-module__item--spacing-small___IZfXq {
  margin-block-end: var(--bui_spacing_1x);
}
.List-module__item--spacing-medium___uh\+3z {
  margin-block-end: var(--bui_spacing_2x);
}
.List-module__item--spacing-large___d1bzA {
  margin-block-end: var(--bui_spacing_3x);
}
.List-module__item--spacing-none___RgW1q,
.List-module__item___uRFQM:last-child {
  margin-block-end: 0;
}
.List-module__root--variant-unordered___7YnXh {
  list-style-type: disc;
}
.List-module__root--variant-ordered___qCSiE {
  list-style-type: decimal;
}
.List-module__root--variant-upper-alpha___xz3Zc {
  list-style-type: upper-alpha;
}
.List-module__root--variant-ordered___qCSiE .List-module__item___uRFQM,
.List-module__root--variant-unordered___7YnXh .List-module__item___uRFQM,
.List-module__root--variant-upper-alpha___xz3Zc .List-module__item___uRFQM {
  display: list-item;
}
.List-module__root--variant-text___2\+2hl {
  list-style-type: none;
  padding-inline-start: 0;
}
.List-module__root--divided___tgTZt > .List-module__item___uRFQM {
  margin: 0;
  padding: var(--bui_spacing_4x) 0;
  border-block-end: var(--bui_border_width_100) solid var(--bui_color_border_alt);
}
.List-module__root--divided___tgTZt > .List-module__item___uRFQM:first-child {
  padding-block-start: 0;
}
.List-module__root--divided___tgTZt > .List-module__item___uRFQM:last-child {
  border-block-end: 0;
  padding-block-end: 0;
}
.List-react-module__icon___SEvpB {
  margin-inline-end: var(--bui_spacing_4x);
}
.List-react-module__main___P3Fn1 {
  flex: 1;
}
.List-react-module__body___Va5pf {
  margin: 0;
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.List-react-module__side___WX2-t {
  margin-inline-start: var(--bui_spacing_4x);
}
.List-react-module__item___DIW3e {
  display: flex;
  align-items: center;
}
.List-react-module__item--text-only___NEwsf {
  display: block;
}
@media (max-width: 575px) {
  .List-react-module__item--with-title___2SLas {
    align-items: flex-start;
  }
  .List-react-module__body___Va5pf {
    flex-direction: column;
    align-items: flex-start;
  }
  .List-react-module__side___WX2-t {
    margin-inline-start: 0;
    margin-block-start: var(--bui_spacing_2x);
  }
}
.PaginationIndicator-module__root___hf8SZ {
  line-height: 0;
}
.PaginationIndicator-module__container___OnnIy {
  display: inline-flex;
  position: relative;
  transition: transform var(--bui_animation_press);
}
.PaginationIndicator-module__container___OnnIy:after,
.PaginationIndicator-module__container___OnnIy:before {
  content: '';
  position: absolute;
  background: var(--bui_color_border);
  inset-inline-start: calc(var(--bui_spacing_4x) * -1);
  inset-block-start: 50%;
  transform: translateY(-50%) scale(0);
}
.PaginationIndicator-module__container___OnnIy:after,
.PaginationIndicator-module__container___OnnIy:before,
.PaginationIndicator-module__item___7IaqO {
  width: var(--bui_spacing_2x);
  height: var(--bui_spacing_2x);
  border-radius: 50%;
  transition: var(--bui_animation_press);
  transition-property: transform, opacity, background-color;
}
.PaginationIndicator-module__item___7IaqO {
  margin-inline-start: var(--bui_spacing_2x);
}
.PaginationIndicator-module__item___7IaqO:first-child {
  margin-inline-start: 0;
}
.PaginationIndicator-module__item--active___sjPBX {
  transform: scale(1.25);
}
.PaginationIndicator-module__item--size-small___P5TYU {
  transform: scale(0.75);
}
.PaginationIndicator-module__item--size-smaller___cPwN- {
  transform: scale(0.5);
}
.PaginationIndicator-module__item--hidden___wZA14 {
  transform: scale(0);
}
.PaginationIndicator-module__root--variant-primary___K05Rr .PaginationIndicator-module__item___7IaqO {
  background: var(--bui_color_border);
}
.PaginationIndicator-module__root--variant-primary___K05Rr .PaginationIndicator-module__item--active___sjPBX {
  background: var(--bui_color_action_border);
}
.PaginationIndicator-module__root--variant-white___ESNO2 .PaginationIndicator-module__item___7IaqO {
  background: var(--bui_color_white);
  opacity: 0.5;
}
.PaginationIndicator-module__root--variant-white___ESNO2 .PaginationIndicator-module__item--active___sjPBX {
  opacity: 1;
}
.PaginationIndicator-module__root--ghost-start___j7M15 .PaginationIndicator-module__container___OnnIy {
  transform: translateX(var(--bui_spacing_4x));
}
.PaginationIndicator-module__root--ghost-end___7-FrE .PaginationIndicator-module__container___OnnIy {
  transform: translateX(calc(var(--bui_spacing_4x) * -1));
}
.PaginationIndicator-module__root--ghost-end___7-FrE .PaginationIndicator-module__container___OnnIy:after,
.PaginationIndicator-module__root--ghost-start___j7M15 .PaginationIndicator-module__container___OnnIy:before {
  transform: translateY(-50%) scale(0.5);
}
.PaginationIndicator-module__root--ghost-start___j7M15 .PaginationIndicator-module__container___OnnIy:before {
  inset-inline-end: auto;
  inset-inline-start: calc(var(--bui_spacing_4x) * -1);
}
.PaginationIndicator-module__root--ghost-end___7-FrE .PaginationIndicator-module__container___OnnIy:after {
  inset-inline-start: auto;
  inset-inline-end: calc(var(--bui_spacing_4x) * -1);
}
.PaginationIndicator-module__root--ghost-end___7-FrE.PaginationIndicator-module__root--variant-white___ESNO2:after,
.PaginationIndicator-module__root--ghost-start___j7M15.PaginationIndicator-module__root--variant-white___ESNO2:before {
  opacity: 0.5;
}
.PaginationIndicator-module__root--not-animated___n\+JlA .PaginationIndicator-module__container___OnnIy,
.PaginationIndicator-module__root--not-animated___n\+JlA .PaginationIndicator-module__container___OnnIy:after,
.PaginationIndicator-module__root--not-animated___n\+JlA .PaginationIndicator-module__container___OnnIy:before,
.PaginationIndicator-module__root--not-animated___n\+JlA .PaginationIndicator-module__item___7IaqO {
  transition: none;
}
.SliderContainer-module__root___Oqv36 {
  position: relative;
  overflow: hidden;
}
[data-bui-keyboard] .SliderContainer-module__root--focused___t2LxP {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.SliderContainer-module__items___sjvkq {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.SliderContainer-module__item___I\+0pk {
  width: 100%;
  height: 100%;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.SliderContainer-module__content___05\+ip {
  height: 100%;
  background-size: cover;
}
.SliderContainer-module__control___TJoOE {
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
  transition: var(--bui_animation_hover);
  transition-property: opacity, visibility;
}
.SliderContainer-module__previous-control___OM00f {
  inset-inline-start: var(--bui_spacing_2x);
}
.SliderContainer-module__next-control___NXQmq {
  inset-inline-end: var(--bui_spacing_2x);
}
.SliderContainer-module__pagination-indicator-container___oSCuD {
  display: flex;
  justify-content: center;
}
.SliderContainer-module__root--variant-media___fBK13 {
  background: var(--bui_color_background_alt);
  color: var(--bui_color_white);
  padding-block-start: 66.66%;
}
.SliderContainer-module__root--variant-media___fBK13 .SliderContainer-module__inner___SEM1o {
  position: absolute;
  inset: 0;
}
.SliderContainer-module__root--variant-media___fBK13 .SliderContainer-module__items___sjvkq {
  background: var(--bui_color_background_alt);
}
.SliderContainer-module__root--border-radius-100___m0xJg {
  border-radius: var(--bui_border_radius_100);
}
.SliderContainer-module__root--border-radius-200___r4KFt {
  border-radius: var(--bui_border_radius_200);
}
.SliderContainer-module__root--border-radius-300___SWOa2 {
  border-radius: var(--bui_border_radius_300);
}
.SliderContainer-module__root--hide-controls___dDSRT .SliderContainer-module__control___TJoOE {
  visibility: hidden;
  opacity: 0;
}
.SliderContainer-module__root--hide-controls___dDSRT:hover .SliderContainer-module__control___TJoOE {
  visibility: visible;
  opacity: 1;
}
.NavigationProgress-module__items___I0t3R {
  margin: 0;
  padding: 0;
  list-style: none;
}
.NavigationProgress-module__item___uJmQg {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.NavigationProgress-module__item___uJmQg:last-child {
  flex-grow: 0;
}
.NavigationProgress-module__mobile-progress___bw2tK {
  padding-block-start: var(--bui_spacing_2x);
}
.NavigationProgress-module__indicator___g5NJs {
  border-radius: 50%;
  background: var(--bui_color_action_background);
  color: var(--bui_color_on_action_background);
  border: var(--bui_border_width_200) solid var(--bui_color_action_background);
  box-sizing: border-box;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: var(--bui_spacing_6x);
  height: var(--bui_spacing_6x);
}
.NavigationProgress-module__title___0QwTE {
  margin-inline-start: var(--bui_spacing_2x);
}
.NavigationProgress-module__item--disabled___75pFt {
  color: var(--bui_color_foreground_alt);
}
.NavigationProgress-module__item--disabled___75pFt .NavigationProgress-module__indicator___g5NJs {
  background: transparent;
  border-color: var(--bui_color_border);
  color: var(--bui_color_foreground_alt);
}
.NavigationProgress-module__divider___LEhY6 {
  flex: 1;
  height: var(--bui_border_width_100);
  background: var(--bui_color_border);
  margin: 0 var(--bui_spacing_2x);
  min-width: var(--bui_spacing_4x);
}
.NavigationProgress-module__root--variant-vertical___cYlJV .NavigationProgress-module__item___uJmQg {
  display: block;
  padding-block-end: var(--bui_spacing_8x);
  position: relative;
  width: 100%;
}
.NavigationProgress-module__root--variant-vertical___cYlJV
  .NavigationProgress-module__item--active___iKbIG:not(:last-child)
  .NavigationProgress-module__content___FZ8YI {
  margin-block-end: calc(var(--bui_spacing_4x) * -1);
}
.NavigationProgress-module__root--variant-vertical___cYlJV .NavigationProgress-module__item___uJmQg:last-child {
  padding-block-end: 0;
}
.NavigationProgress-module__root--variant-vertical___cYlJV .NavigationProgress-module__header___liH4g {
  display: flex;
  align-items: center;
}
.NavigationProgress-module__root--variant-vertical___cYlJV .NavigationProgress-module__content___FZ8YI {
  display: block;
  margin-block-start: var(--bui_spacing_4x);
  margin-inline-start: var(--bui_spacing_8x);
}
.NavigationProgress-module__root--variant-vertical___cYlJV .NavigationProgress-module__divider___LEhY6 {
  display: none;
}
.NavigationProgress-module__root--variant-vertical___cYlJV .NavigationProgress-module__indicator___g5NJs {
  position: relative;
}
.NavigationProgress-module__root--variant-vertical___cYlJV .NavigationProgress-module__item___uJmQg:before {
  content: '';
  background: var(--bui_color_border);
  position: absolute;
  inset-inline-start: calc(var(--bui_spacing_3x) - 0.5px);
  inset-block-start: var(--bui_spacing_8x);
  inset-block-end: var(--bui_spacing_2x);
  width: var(--bui_border_width_100);
}
.NavigationProgress-module__root--variant-vertical___cYlJV .NavigationProgress-module__item___uJmQg:last-child:before {
  content: none;
}
.Overlay-module__root___A5kbs {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: var(--bui_z_index_2);
  box-sizing: border-box;
  border: none;
  padding: 0;
  position: fixed;
  background: transparent;
  inset: 0;
  transition: var(--bui_animation_fade_in);
  transition-property: background-color, visibility;
  outline: none;
  visibility: hidden;
}
.Overlay-module__content___xykzp {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.Overlay-module__root--visible___f7rBa {
  transition-timing-function: var(--bui_animation_fade_in_timing_function);
  transition-duration: var(--bui_animation_fade_in_duration);
  background-color: var(--bui_color_black_with_alpha);
  visibility: visible;
  transition-property: background-color;
}
.Overlay-module__root--hide-overlay___7Xpkv {
  background-color: transparent;
  pointer-events: none;
}
.Overlay-module__root--enter-from___4E\+9z {
  background: transparent;
  visibility: hidden;
}
.Pagination-module__root___vZTZG {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--bui_spacing_1x) 0;
  font-size: var(--bui_font_body_2_font-size);
  font-weight: var(--bui_font_body_2_font-weight);
  line-height: var(--bui_font_body_2_line-height);
  font-family: var(--bui_font_body_2_font-family);
}
.Pagination-module__nav___bnqIm {
  flex: 1;
}
.Pagination-module__list___8S1eY {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.Pagination-module__pages___OxE\+S {
  display: flex;
}
.Pagination-module__currentPage___VqmOf {
  padding: 0 var(--bui_spacing_4x);
  flex-shrink: 0;
  flex-grow: 1;
  text-align: center;
}
.Pagination-module__direction___FOBsa {
  margin: 0 var(--bui_spacing_1x);
}
.Pagination-module__direction___FOBsa:first-child {
  margin-inline-start: 0;
  margin-inline-end: var(--bui_spacing_1x);
}
.Pagination-module__direction___FOBsa:last-child {
  margin-inline-end: 0;
  margin-inline-start: var(--bui_spacing_1x);
}
.Pagination-module__navButton___T\+96v {
  font-size: var(--bui_font_emphasized_2_font-size);
  font-weight: var(--bui_font_emphasized_2_font-weight);
  line-height: var(--bui_font_emphasized_2_line-height);
  font-family: var(--bui_font_emphasized_2_font-family);
}
.Pagination-module__prevArrow___xHDZ5 {
  display: inline-flex;
  align-items: center;
  margin-inline-end: var(--bui_spacing_1x);
}
.Pagination-module__item___QO44b + .Pagination-module__item___QO44b {
  margin-inline-start: var(--bui_spacing_2x);
}
.Pagination-module__nextArrow___oBHnj {
  margin-inline-start: var(--bui_spacing_1x);
}
.Pagination-module__link___ivPkp,
.Pagination-module__link___ivPkp:link,
.Pagination-module__link___ivPkp:visited,
.Pagination-module__separator___xsxe4 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bui_color_action_foreground);
  min-width: var(--bui_spacing_6x);
  height: var(--bui_spacing_6x);
  padding: 0 var(--bui_spacing_1x);
  border: var(--bui_border_width_100) solid transparent;
  border-radius: var(--bui_border_radius_100);
}
.Pagination-module__separator___xsxe4 {
  color: var(--bui_color_foreground_disabled);
}
.Pagination-module__item___QO44b:hover .Pagination-module__link___ivPkp {
  border-color: transparent;
  background-color: var(--bui_color_action_highlighted_alt);
}
.Pagination-module__item--active___I7bdA .Pagination-module__link___ivPkp,
.Pagination-module__item--active___I7bdA:hover .Pagination-module__link___ivPkp {
  border-color: var(--bui_color_border);
  background-color: transparent;
  color: var(--bui_color_foreground);
  cursor: default;
}
.Pagination-module__item--disabled___C5\+1t .Pagination-module__link___ivPkp,
.Pagination-module__item--disabled___C5\+1t:hover .Pagination-module__link___ivPkp {
  border-color: transparent;
  background-color: transparent;
  fill: var(--bui_color_foreground_disabled);
  color: var(--bui_color_foreground_disabled);
}
.Pagination-module__control___pbcpI {
  border-color: transparent;
}
.Pagination-module__controls___lp8y9 {
  display: flex;
  align-items: center;
}
.Pagination-module__controls-separator___48BgF {
  width: 1px;
  height: var(--bui_spacing_6x);
  background-color: var(--bui_color_border_alt);
  margin: 0 var(--bui_spacing_4x);
}
.Pagination-module__controls-info-label___X6SVt {
  padding-block: var(--bui_spacing_2x);
}
.Pagination-module__controls-info-label___X6SVt:last-child {
  padding-inline-end: var(--bui_spacing_4x);
}
.Pagination-module__controls-separator___48BgF + .Pagination-module__select___auPC5 {
  margin-inline-start: calc(var(--bui_spacing_2x) * -1);
}
@media (min-width: 1024px) {
  .Pagination-module__list___8S1eY {
    justify-content: flex-start;
  }
  .Pagination-module__nav___bnqIm {
    flex: 0;
  }
  .Pagination-module__root___vZTZG {
    justify-content: space-between;
  }
}
.Print-module__root___qbey1:not(.Print-module__root--hidden___SVo0Z) {
  display: none;
}
@media print {
  .Print-module__root___qbey1:not(.Print-module__root--hidden___SVo0Z) {
    display: block;
  }
  .Print-module__root--hidden___SVo0Z {
    display: none !important;
  }
}
.Rating-module__root___uEDpk {
  display: flex;
}
.Rating-module__item___T0dIv {
  fill: var(--bui_color_accent_background);
  margin-inline-start: var(--bui_spacing_half);
  margin-inline-end: 0;
}
.Rating-module__item___T0dIv:first-child {
  margin-inline-start: 0;
}
.ReviewScore-module__badge___Pv\+1d {
  background: var(--bui_color_brand_primary_background);
  color: var(--bui_color_on_brand_primary_background);
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: baseline;
  border-radius: calc(var(--bui_spacing_8x) / 5.5) calc(var(--bui_spacing_8x) / 5.5) calc(var(--bui_spacing_8x) / 5.5) 0;
  height: var(--bui_spacing_8x);
  width: var(--bui_spacing_8x);
  min-width: var(--bui_spacing_8x);
}
.ReviewScore-module__root--size-small___WFC4w .ReviewScore-module__badge___Pv\+1d {
  width: calc(var(--bui_spacing_1x) * 7);
  min-width: calc(var(--bui_spacing_1x) * 7);
  height: calc(var(--bui_spacing_1x) * 7);
  border-radius: calc(var(--bui_spacing_1x) * 7 / 5.5) calc(var(--bui_spacing_1x) * 7 / 5.5)
    calc(var(--bui_spacing_1x) * 7 / 5.5) 0;
}
.ReviewScore-module__root--size-smaller___LHm7f .ReviewScore-module__badge___Pv\+1d {
  width: var(--bui_spacing_6x);
  min-width: var(--bui_spacing_6x);
  height: var(--bui_spacing_6x);
  border-radius: calc(var(--bui_spacing_6x) / 5.5) calc(var(--bui_spacing_6x) / 5.5) calc(var(--bui_spacing_6x) / 5.5) 0;
}
.ReviewScore-module__root--adjusted-text-gap___t0uuL .ReviewScore-module__text___g3mf9 {
  margin-block-start: calc(var(--bui_spacing_1x) * -1);
}
.ReviewScore-module__root--adjusted-text-gap___t0uuL.ReviewScore-module__root--size-small___WFC4w
  .ReviewScore-module__text___g3mf9,
.ReviewScore-module__root--adjusted-text-gap___t0uuL.ReviewScore-module__root--size-smaller___LHm7f
  .ReviewScore-module__text___g3mf9 {
  margin-block-start: calc(var(--bui_spacing_half) * -1);
}
.ReviewScore-module__root--variant-outline___u3ajD .ReviewScore-module__badge___Pv\+1d {
  background: transparent;
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  color: var(--bui_color_foreground);
}
.ReviewScore-module__root--variant-inverse___1wVwP .ReviewScore-module__badge___Pv\+1d {
  background: transparent;
  border: var(--bui_border_width_100) solid var(--bui_color_white);
  color: var(--bui_color_white);
}
.ReviewScore-module__root--variant-inverse___1wVwP .ReviewScore-module__text___g3mf9,
.ReviewScore-module__root--variant-inverse___1wVwP .ReviewScore-module__title___5LYqk {
  color: var(--bui_color_white);
}
.ReviewScore-module__root--alignment-end___vqIbI .ReviewScore-module__content___y7o9m {
  text-align: end;
}
.ScoreBar-module__root___Lv3ax {
  background: var(--bui_color_background_alt);
  border-radius: 999px;
  overflow: hidden;
}
.ScoreBar-module__value___Bfpxt {
  background: var(--bui_color_brand_primary_background);
  display: block;
  height: var(--bui_spacing_2x);
  border-radius: 999px;
}
.ScoreBar-module__root--color-destructive___QMdhW .ScoreBar-module__value___Bfpxt {
  background-color: var(--bui_color_destructive_background);
}
.ScoreBar-module__root--color-constructive___Nefz3 .ScoreBar-module__value___Bfpxt {
  background-color: var(--bui_color_constructive_background);
}
.ScoreBar-module__root--color-accent___RCUgs .ScoreBar-module__value___Bfpxt {
  background-color: var(--bui_color_accent_background);
}
.ScoreBar-module__root--color-callout___DGjti .ScoreBar-module__value___Bfpxt {
  background-color: var(--bui_color_callout_background);
}
.ScoreBar-module__root--color-action___21jBA .ScoreBar-module__value___Bfpxt {
  background-color: var(--bui_color_action_background);
}
.SegmentedControl-module__root___qvl\+o {
  --bui-segmented-control-padding: calc(var(--bui_spacing_1x) - var(--bui_border_width_100));
}
.SegmentedControl-module__label___6wkV6 {
  display: block;
  margin-block-end: var(--bui_spacing_1x);
}
.SegmentedControl-module__list___lSBRf {
  list-style: none;
  margin: 0;
  display: inline-flex;
  align-items: stretch;
  vertical-align: top;
  border-radius: 999px;
  background-color: var(--bui_color_background_alt);
  border: var(--bui_border_width_100) solid var(--bui_color_border_alt);
  padding: var(--bui-segmented-control-padding);
  position: relative;
}
.SegmentedControl-module__list___lSBRf:before {
  content: '';
  position: absolute;
  box-sizing: border-box;
  border-radius: 999px;
  width: var(--bui-segmented-control-active-scale-x, 0);
  height: calc(100% - var(--bui-segmented-control-padding) * 2);
  transition: var(--bui_animation_press);
  transition-property: width, transform;
  z-index: var(--bui_z_index_1);
  background-color: var(--bui_color_background_elevation_one);
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  inset-block-start: var(--bui-segmented-control-padding);
  left: 0;
  transform: translateX(var(--bui-segmented-control-active-transform-x, 0));
  visibility: hidden;
}
.SegmentedControl-module__button___EyV0C {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  padding: calc(var(--bui_spacing_1x) - var(--bui_border_width_100))
    calc(var(--bui_spacing_3x) - var(--bui_border_width_100));
  position: relative;
  z-index: var(--bui_z_index_2);
  border-radius: 999px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  border: var(--bui_border_width_100) solid transparent;
  margin-inline-end: var(--bui_spacing_1x);
}
.SegmentedControl-module__button___EyV0C:hover {
  background: var(--bui_color_highlighted_alt);
}
.SegmentedControl-module__button___EyV0C:last-child {
  margin-inline-end: 0;
}
.SegmentedControl-module__button___EyV0C:after {
  content: '';
  width: var(--bui_border_width_100);
  background: var(--bui_color_border_alt);
  position: absolute;
  inset-inline-start: calc(var(--bui_spacing_half) * -1);
  inset-block: calc(var(--bui_spacing_1x) + var(--bui_spacing_half));
}
.SegmentedControl-module__button-text___VbbGA {
  position: relative;
  z-index: var(--bui_z_index_2);
}
.SegmentedControl-module__trigger___9Vjkr {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  border: 0;
}
.SegmentedControl-module__root___qvl\+o:not(.SegmentedControl-module__root--animated___4dLcB)
  .SegmentedControl-module__trigger___9Vjkr:checked
  + .SegmentedControl-module__button___EyV0C,
.SegmentedControl-module__root___qvl\+o:not(.SegmentedControl-module__root--animated___4dLcB)
  .SegmentedControl-module__trigger___9Vjkr:checked
  + .SegmentedControl-module__button___EyV0C:hover {
  background-color: var(--bui_color_background_elevation_one);
  border-color: var(--bui_color_border);
}
[data-bui-keyboard] .SegmentedControl-module__trigger___9Vjkr:focus + .SegmentedControl-module__button___EyV0C {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
  z-index: var(--bui_z_index_1);
}
.SegmentedControl-module__button___EyV0C:hover
  + .SegmentedControl-module__trigger___9Vjkr
  + .SegmentedControl-module__button___EyV0C:after,
.SegmentedControl-module__button___EyV0C:hover:after,
.SegmentedControl-module__trigger___9Vjkr:checked
  + .SegmentedControl-module__button___EyV0C
  + .SegmentedControl-module__trigger___9Vjkr
  + .SegmentedControl-module__button___EyV0C:after,
.SegmentedControl-module__trigger___9Vjkr:checked + .SegmentedControl-module__button___EyV0C:after,
.SegmentedControl-module__trigger___9Vjkr:first-child + .SegmentedControl-module__button___EyV0C:after {
  opacity: 0;
  transition: none;
}
.SegmentedControl-module__root--fill-equally___GqB9U .SegmentedControl-module__list___lSBRf {
  display: flex;
}
.SegmentedControl-module__root--fill-equally___GqB9U .SegmentedControl-module__button___EyV0C,
.SegmentedControl-module__root--fill-equally___GqB9U .SegmentedControl-module__item___B6Y7x {
  width: 100%;
  justify-content: center;
}
.SegmentedControl-module__root--animated___4dLcB .SegmentedControl-module__list___lSBRf:before {
  visibility: visible;
}
.SegmentedControl-module__root--animated___4dLcB .SegmentedControl-module__button___EyV0C {
  background: transparent;
}
.Title-module__root___8mO1J {
  display: flex;
  flex-direction: column;
}
.Title-module__subtitle___UduiK,
.Title-module__title___nOZqy {
  margin: 0;
  padding: 0;
}
.Title-module__root--reversed___8zLmz {
  flex-direction: column-reverse;
}
.Title-module__root--color-inherit___jXBnw .Title-module__subtitle___UduiK {
  opacity: 0.8;
}
.Title-module__root--variant-headline_3___TvTRs .Title-module__subtitle___UduiK {
  margin-block-start: var(--bui_spacing_half);
}
.Title-module__root--variant-headline_3___TvTRs.Title-module__root--reversed___8zLmz .Title-module__subtitle___UduiK {
  margin-block-start: 0;
  margin-block-end: var(--bui_spacing_half);
}
.Title-module__root--variant-headline_2___CYeL7 .Title-module__subtitle___UduiK {
  margin-block-start: var(--bui_spacing_1x);
}
.Title-module__root--variant-headline_2___CYeL7.Title-module__root--reversed___8zLmz .Title-module__subtitle___UduiK {
  margin-block-start: 0;
  margin-block-end: var(--bui_spacing_1x);
}
.Title-module__root--variant-display_3___jLiRd .Title-module__subtitle___UduiK,
.Title-module__root--variant-headline_1___wezqs .Title-module__subtitle___UduiK {
  margin-block-start: var(--bui_spacing_2x);
}
.Title-module__root--variant-display_3___jLiRd.Title-module__root--reversed___8zLmz .Title-module__subtitle___UduiK,
.Title-module__root--variant-headline_1___wezqs.Title-module__root--reversed___8zLmz .Title-module__subtitle___UduiK {
  margin-block-start: 0;
  margin-block-end: var(--bui_spacing_2x);
}
.SheetContainer-module__root___BlluP {
  --bui_sheet_container_padding: var(--bui_spacing_4x);
  --bui_sheet_container_width_side_preset: calc(var(--bui_spacing_1x) * 80);
  --bui_sheet_container_width_center_preset: calc(var(--bui_spacing_1x) * 144);
  pointer-events: none;
  box-sizing: border-box;
  text-align: start;
  transition: var(--bui_animation_fade_out);
  transition-property: opacity, transform;
  will-change: opacity, transform;
  width: 100%;
  display: flex;
  z-index: var(--bui_z_index_4);
  transform: var(--bui_sheet_container_transform);
}
[dir='rtl'] .SheetContainer-module__root___BlluP {
  transform: var(--bui_sheet_container_transform_rtl);
}
.SheetContainer-module__inner___5ucYg {
  pointer-events: all;
  background: var(--bui_color_background_elevation_one);
  box-shadow: var(--bui_shadow_100);
  outline: none;
  display: flex;
  flex-direction: column;
  position: relative;
  height: var(--bui_sheet_container_height);
  width: var(--bui_sheet_container_width);
  max-height: 100%;
  border-radius: var(--bui_sheet_container_radius);
}
[dir='rtl'] .SheetContainer-module__inner___5ucYg {
  border-radius: var(--bui_sheet_container_radius_rtl);
}
.SheetContainer-module__content___jVf51 {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-block-end: var(--bui_sheet_container_padding);
}
.SheetContainer-module__subtitle___SIWGf {
  word-break: break-word;
}
.SheetContainer-module__close___9ByRC {
  inset-block-start: calc(var(--bui_sheet_container_padding) + var(--bui_spacing_half));
  inset-inline-end: var(--bui_sheet_container_padding);
}
.SheetContainer-module__header___cdT7E {
  margin-block-end: calc(var(--bui_sheet_container_padding) - var(--bui_spacing_2x));
  padding-block-start: var(--bui_sheet_container_padding);
  padding-inline-start: var(--bui_sheet_container_padding);
}
.SheetContainer-module__header--sticky___eYfRK {
  position: sticky;
  inset-block-start: 0;
  background: var(--bui_color_background_elevation_one);
  margin-block-end: 0;
  padding-block-end: var(--bui_sheet_container_padding);
  z-index: var(--bui_z_index_1);
}
.SheetContainer-module__header--preserve-space___tQe0B {
  min-height: var(--bui_font_headline_3_line-height);
}
.SheetContainer-module__body___6WfGe {
  flex-grow: 1;
  height: var(--bui_sheet_container_body_height);
  padding: var(--bui_sheet_container_padding);
  padding-block-end: 0;
}
.SheetContainer-module__header___cdT7E + .SheetContainer-module__body___6WfGe {
  padding-block-start: 0;
}
.SheetContainer-module__footer___EZxHa {
  flex-shrink: 0;
  background: var(--bui_color_background_elevation_one);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  position: var(--bui_sheet_container_footer_position);
  inset-block-end: 0;
  overflow: hidden;
}
.SheetContainer-module__root--position-bottom___anx7b {
  inset-inline-start: auto;
  padding-inline-end: 0;
  inset-inline-end: auto;
  padding-inline-start: 0;
  max-width: none;
  max-height: none;
  width: auto;
  inset-block: auto;
  justify-content: normal;
  opacity: 1;
  inset-block-start: auto;
  position: fixed;
  inset: 0;
  padding: 0;
  padding-block-start: var(--bui_spacing_8x);
  height: 100%;
  align-items: flex-end;
  --bui_sheet_container_width: 100%;
  --bui_sheet_container_height: var(--bui_sheet_container_size, auto);
  --bui_sheet_container_radius: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
  --bui_sheet_container_radius_rtl: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
  --bui_sheet_container_transform: translateY(100%);
  --bui_sheet_container_transform_rtl: translateY(100%);
  --bui_sheet_container_footer_position: static;
  --bui_sheet_container_body_height: auto;
}
.SheetContainer-module__root--position-start___lEoHd {
  inset: auto;
  padding: initial;
  padding-block-start: 0;
  inset-inline-end: auto;
  padding-inline-start: 0;
  inset-block: auto;
  inset-block-start: auto;
  inset-inline-start: 0;
  padding-inline-end: var(--bui_spacing_8x);
  --bui_sheet_container_radius: 0 var(--bui_border_radius_300) var(--bui_border_radius_300) 0;
  --bui_sheet_container_radius_rtl: var(--bui_border_radius_300) 0 0 var(--bui_border_radius_300);
  --bui_sheet_container_transform: translateX(-100%);
  --bui_sheet_container_transform_rtl: translateX(100%);
}
.SheetContainer-module__root--position-end___WuQMp,
.SheetContainer-module__root--position-start___lEoHd {
  position: static;
  height: auto;
  align-items: normal;
  --bui_sheet_container_width: initial;
  --bui_sheet_container_height: initial;
  --bui_sheet_container_footer_position: initial;
  --bui_sheet_container_body_height: initial;
  max-width: none;
  max-height: none;
  width: auto;
  justify-content: normal;
  opacity: 1;
}
.SheetContainer-module__root--position-end___WuQMp {
  inset: auto;
  padding: initial;
  padding-block-start: 0;
  inset-inline-start: auto;
  padding-inline-end: 0;
  inset-block: auto;
  inset-block-start: auto;
  inset-inline-end: 0;
  padding-inline-start: var(--bui_spacing_8x);
  --bui_sheet_container_radius: var(--bui_border_radius_300) 0 0 var(--bui_border_radius_300);
  --bui_sheet_container_radius_rtl: 0 var(--bui_border_radius_300) var(--bui_border_radius_300) 0;
  --bui_sheet_container_transform: translateX(100%);
  --bui_sheet_container_transform_rtl: translateX(-100%);
}
.SheetContainer-module__root--position-end___WuQMp,
.SheetContainer-module__root--position-start___lEoHd {
  position: fixed;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  inset-block: 0;
  --bui_sheet_container_width: var(--bui_sheet_container_size, var(--bui_sheet_container_width_side_preset));
  --bui_sheet_container_height: 100%;
  --bui_sheet_container_footer_position: static;
  --bui_sheet_container_body_height: auto;
}
.SheetContainer-module__root--position-center___kxQWo {
  inset: auto;
  padding-block-start: 0;
  height: auto;
  inset-inline-start: auto;
  padding-inline-end: 0;
  inset-inline-end: auto;
  padding-inline-start: 0;
  width: auto;
  inset-block: auto;
  inset-block-start: auto;
  justify-content: center;
  position: relative;
  padding: var(--bui_spacing_6x);
  max-width: 100vw;
  --bui_sheet_container_width: var(--bui_sheet_container_size, var(--bui_sheet_container_width_center_preset));
  --bui_sheet_container_height: auto;
  --bui_sheet_container_radius: var(--bui_border_radius_300);
  --bui_sheet_container_radius_rtl: var(--bui_border_radius_300);
  --bui_sheet_container_footer_position: sticky;
  --bui_sheet_container_body_height: auto;
}
.SheetContainer-module__root--position-center___kxQWo,
.SheetContainer-module__root--position-fullScreen___VGxQb {
  align-items: normal;
  max-height: none;
  opacity: 0;
  --bui_sheet_container_transform: translateY(var(--bui_spacing_6x));
  --bui_sheet_container_transform_rtl: translateY(var(--bui_spacing_6x));
}
.SheetContainer-module__root--position-fullScreen___VGxQb {
  inset: auto;
  padding-block-start: 0;
  padding-inline-end: 0;
  inset-inline-end: auto;
  padding-inline-start: 0;
  max-width: none;
  inset-block: auto;
  justify-content: normal;
  padding: 0;
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  height: 100%;
  width: 100%;
  --bui_sheet_container_radius: 0;
  --bui_sheet_container_radius_rtl: 0;
  --bui_sheet_container_width: 100%;
  --bui_sheet_container_height: 100%;
  --bui_sheet_container_footer_position: static;
  --bui_sheet_container_body_height: 0;
}
@media (min-width: 576px) {
  .SheetContainer-module__root--position-bottom--m___fWYI5 {
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    max-width: none;
    max-height: none;
    width: auto;
    inset-block: auto;
    justify-content: normal;
    opacity: 1;
    inset-block-start: auto;
    position: fixed;
    inset: 0;
    padding: 0;
    padding-block-start: var(--bui_spacing_8x);
    height: 100%;
    align-items: flex-end;
    --bui_sheet_container_width: 100%;
    --bui_sheet_container_height: var(--bui_sheet_container_size, auto);
    --bui_sheet_container_radius: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
    --bui_sheet_container_transform: translateY(100%);
    --bui_sheet_container_transform_rtl: translateY(100%);
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-start--m___A-6yd {
    inset: auto;
    padding: initial;
    padding-block-start: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    inset-block: auto;
    inset-block-start: auto;
    inset-inline-start: 0;
    padding-inline-end: var(--bui_spacing_8x);
    --bui_sheet_container_radius: 0 var(--bui_border_radius_300) var(--bui_border_radius_300) 0;
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300) 0 0 var(--bui_border_radius_300);
    --bui_sheet_container_transform: translateX(-100%);
    --bui_sheet_container_transform_rtl: translateX(100%);
  }
  .SheetContainer-module__root--position-end--m___58DIw,
  .SheetContainer-module__root--position-start--m___A-6yd {
    position: static;
    height: auto;
    align-items: normal;
    --bui_sheet_container_width: initial;
    --bui_sheet_container_height: initial;
    --bui_sheet_container_footer_position: initial;
    --bui_sheet_container_body_height: initial;
    max-width: none;
    max-height: none;
    width: auto;
    justify-content: normal;
    opacity: 1;
  }
  .SheetContainer-module__root--position-end--m___58DIw {
    inset: auto;
    padding: initial;
    padding-block-start: 0;
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-block: auto;
    inset-block-start: auto;
    inset-inline-end: 0;
    padding-inline-start: var(--bui_spacing_8x);
    --bui_sheet_container_radius: var(--bui_border_radius_300) 0 0 var(--bui_border_radius_300);
    --bui_sheet_container_radius_rtl: 0 var(--bui_border_radius_300) var(--bui_border_radius_300) 0;
    --bui_sheet_container_transform: translateX(100%);
    --bui_sheet_container_transform_rtl: translateX(-100%);
  }
  .SheetContainer-module__root--position-end--m___58DIw,
  .SheetContainer-module__root--position-start--m___A-6yd {
    position: fixed;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    inset-block: 0;
    --bui_sheet_container_width: var(--bui_sheet_container_size, var(--bui_sheet_container_width_side_preset));
    --bui_sheet_container_height: 100%;
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-center--m___Wn2AG {
    inset: auto;
    padding-block-start: 0;
    height: auto;
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    width: auto;
    inset-block: auto;
    inset-block-start: auto;
    justify-content: center;
    position: relative;
    padding: var(--bui_spacing_6x);
    max-width: 100vw;
    --bui_sheet_container_width: var(--bui_sheet_container_size, var(--bui_sheet_container_width_center_preset));
    --bui_sheet_container_height: auto;
    --bui_sheet_container_radius: var(--bui_border_radius_300);
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300);
    --bui_sheet_container_footer_position: sticky;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-center--m___Wn2AG,
  .SheetContainer-module__root--position-fullScreen--m___-q\+j1 {
    align-items: normal;
    max-height: none;
    opacity: 0;
    --bui_sheet_container_transform: translateY(var(--bui_spacing_6x));
    --bui_sheet_container_transform_rtl: translateY(var(--bui_spacing_6x));
  }
  .SheetContainer-module__root--position-fullScreen--m___-q\+j1 {
    inset: auto;
    padding-block-start: 0;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    max-width: none;
    inset-block: auto;
    justify-content: normal;
    padding: 0;
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    height: 100%;
    width: 100%;
    --bui_sheet_container_radius: 0;
    --bui_sheet_container_radius_rtl: 0;
    --bui_sheet_container_width: 100%;
    --bui_sheet_container_height: 100%;
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: 0;
  }
}
@media (min-width: 1024px) {
  .SheetContainer-module__root--position-bottom--l___hZAe2 {
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    max-width: none;
    max-height: none;
    width: auto;
    inset-block: auto;
    justify-content: normal;
    opacity: 1;
    inset-block-start: auto;
    position: fixed;
    inset: 0;
    padding: 0;
    padding-block-start: var(--bui_spacing_8x);
    height: 100%;
    align-items: flex-end;
    --bui_sheet_container_width: 100%;
    --bui_sheet_container_height: var(--bui_sheet_container_size, auto);
    --bui_sheet_container_radius: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
    --bui_sheet_container_transform: translateY(100%);
    --bui_sheet_container_transform_rtl: translateY(100%);
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-start--l___CihPw {
    inset: auto;
    padding: initial;
    padding-block-start: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    inset-block: auto;
    inset-block-start: auto;
    inset-inline-start: 0;
    padding-inline-end: var(--bui_spacing_8x);
    --bui_sheet_container_radius: 0 var(--bui_border_radius_300) var(--bui_border_radius_300) 0;
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300) 0 0 var(--bui_border_radius_300);
    --bui_sheet_container_transform: translateX(-100%);
    --bui_sheet_container_transform_rtl: translateX(100%);
  }
  .SheetContainer-module__root--position-end--l___cYZEW,
  .SheetContainer-module__root--position-start--l___CihPw {
    position: static;
    height: auto;
    align-items: normal;
    --bui_sheet_container_width: initial;
    --bui_sheet_container_height: initial;
    --bui_sheet_container_footer_position: initial;
    --bui_sheet_container_body_height: initial;
    max-width: none;
    max-height: none;
    width: auto;
    justify-content: normal;
    opacity: 1;
  }
  .SheetContainer-module__root--position-end--l___cYZEW {
    inset: auto;
    padding: initial;
    padding-block-start: 0;
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-block: auto;
    inset-block-start: auto;
    inset-inline-end: 0;
    padding-inline-start: var(--bui_spacing_8x);
    --bui_sheet_container_radius: var(--bui_border_radius_300) 0 0 var(--bui_border_radius_300);
    --bui_sheet_container_radius_rtl: 0 var(--bui_border_radius_300) var(--bui_border_radius_300) 0;
    --bui_sheet_container_transform: translateX(100%);
    --bui_sheet_container_transform_rtl: translateX(-100%);
  }
  .SheetContainer-module__root--position-end--l___cYZEW,
  .SheetContainer-module__root--position-start--l___CihPw {
    position: fixed;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    inset-block: 0;
    --bui_sheet_container_width: var(--bui_sheet_container_size, var(--bui_sheet_container_width_side_preset));
    --bui_sheet_container_height: 100%;
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-center--l___T\+R\+H {
    inset: auto;
    padding-block-start: 0;
    height: auto;
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    width: auto;
    inset-block: auto;
    inset-block-start: auto;
    justify-content: center;
    position: relative;
    padding: var(--bui_spacing_6x);
    max-width: 100vw;
    --bui_sheet_container_width: var(--bui_sheet_container_size, var(--bui_sheet_container_width_center_preset));
    --bui_sheet_container_height: auto;
    --bui_sheet_container_radius: var(--bui_border_radius_300);
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300);
    --bui_sheet_container_footer_position: sticky;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-center--l___T\+R\+H,
  .SheetContainer-module__root--position-fullScreen--l___Q5PhY {
    align-items: normal;
    max-height: none;
    opacity: 0;
    --bui_sheet_container_transform: translateY(var(--bui_spacing_6x));
    --bui_sheet_container_transform_rtl: translateY(var(--bui_spacing_6x));
  }
  .SheetContainer-module__root--position-fullScreen--l___Q5PhY {
    inset: auto;
    padding-block-start: 0;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    max-width: none;
    inset-block: auto;
    justify-content: normal;
    padding: 0;
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    height: 100%;
    width: 100%;
    --bui_sheet_container_radius: 0;
    --bui_sheet_container_radius_rtl: 0;
    --bui_sheet_container_width: 100%;
    --bui_sheet_container_height: 100%;
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: 0;
  }
}
@media (min-width: 1280px) {
  .SheetContainer-module__root--position-bottom--xl___fWbgS {
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    max-width: none;
    max-height: none;
    width: auto;
    inset-block: auto;
    justify-content: normal;
    opacity: 1;
    inset-block-start: auto;
    position: fixed;
    inset: 0;
    padding: 0;
    padding-block-start: var(--bui_spacing_8x);
    height: 100%;
    align-items: flex-end;
    --bui_sheet_container_width: 100%;
    --bui_sheet_container_height: var(--bui_sheet_container_size, auto);
    --bui_sheet_container_radius: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
    --bui_sheet_container_transform: translateY(100%);
    --bui_sheet_container_transform_rtl: translateY(100%);
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-start--xl___1bsQe {
    inset: auto;
    padding: initial;
    padding-block-start: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    inset-block: auto;
    inset-block-start: auto;
    inset-inline-start: 0;
    padding-inline-end: var(--bui_spacing_8x);
    --bui_sheet_container_radius: 0 var(--bui_border_radius_300) var(--bui_border_radius_300) 0;
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300) 0 0 var(--bui_border_radius_300);
    --bui_sheet_container_transform: translateX(-100%);
    --bui_sheet_container_transform_rtl: translateX(100%);
  }
  .SheetContainer-module__root--position-end--xl___9qZGl,
  .SheetContainer-module__root--position-start--xl___1bsQe {
    position: static;
    height: auto;
    align-items: normal;
    --bui_sheet_container_width: initial;
    --bui_sheet_container_height: initial;
    --bui_sheet_container_footer_position: initial;
    --bui_sheet_container_body_height: initial;
    max-width: none;
    max-height: none;
    width: auto;
    justify-content: normal;
    opacity: 1;
  }
  .SheetContainer-module__root--position-end--xl___9qZGl {
    inset: auto;
    padding: initial;
    padding-block-start: 0;
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-block: auto;
    inset-block-start: auto;
    inset-inline-end: 0;
    padding-inline-start: var(--bui_spacing_8x);
    --bui_sheet_container_radius: var(--bui_border_radius_300) 0 0 var(--bui_border_radius_300);
    --bui_sheet_container_radius_rtl: 0 var(--bui_border_radius_300) var(--bui_border_radius_300) 0;
    --bui_sheet_container_transform: translateX(100%);
    --bui_sheet_container_transform_rtl: translateX(-100%);
  }
  .SheetContainer-module__root--position-end--xl___9qZGl,
  .SheetContainer-module__root--position-start--xl___1bsQe {
    position: fixed;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    inset-block: 0;
    --bui_sheet_container_width: var(--bui_sheet_container_size, var(--bui_sheet_container_width_side_preset));
    --bui_sheet_container_height: 100%;
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-center--xl___\+5cbg {
    inset: auto;
    padding-block-start: 0;
    height: auto;
    inset-inline-start: auto;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    width: auto;
    inset-block: auto;
    inset-block-start: auto;
    justify-content: center;
    position: relative;
    padding: var(--bui_spacing_6x);
    max-width: 100vw;
    --bui_sheet_container_width: var(--bui_sheet_container_size, var(--bui_sheet_container_width_center_preset));
    --bui_sheet_container_height: auto;
    --bui_sheet_container_radius: var(--bui_border_radius_300);
    --bui_sheet_container_radius_rtl: var(--bui_border_radius_300);
    --bui_sheet_container_footer_position: sticky;
    --bui_sheet_container_body_height: auto;
  }
  .SheetContainer-module__root--position-center--xl___\+5cbg,
  .SheetContainer-module__root--position-fullScreen--xl___7Z0l1 {
    align-items: normal;
    max-height: none;
    opacity: 0;
    --bui_sheet_container_transform: translateY(var(--bui_spacing_6x));
    --bui_sheet_container_transform_rtl: translateY(var(--bui_spacing_6x));
  }
  .SheetContainer-module__root--position-fullScreen--xl___7Z0l1 {
    inset: auto;
    padding-block-start: 0;
    padding-inline-end: 0;
    inset-inline-end: auto;
    padding-inline-start: 0;
    max-width: none;
    inset-block: auto;
    justify-content: normal;
    padding: 0;
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    height: 100%;
    width: 100%;
    --bui_sheet_container_radius: 0;
    --bui_sheet_container_radius_rtl: 0;
    --bui_sheet_container_width: 100%;
    --bui_sheet_container_height: 100%;
    --bui_sheet_container_footer_position: static;
    --bui_sheet_container_body_height: 0;
  }
}
.SheetContainer-module__root--fill___ty6Tt {
  --bui_sheet_container_padding: 0px;
}
.SheetContainer-module__root--size-large___8dsm- {
  --bui_sheet_container_width_center_preset: calc(var(--bui_spacing_1x) * 195);
  --bui_sheet_container_width_side_preset: calc(var(--bui_spacing_1x) * 195);
}
@media (min-width: 576px) {
  .SheetContainer-module__root--padding-large___dJtH7 {
    --bui_sheet_container_padding: var(--bui_spacing_6x);
  }
  .SheetContainer-module__root--padding-large___dJtH7.SheetContainer-module__root--fill___ty6Tt {
    --bui_sheet_container_padding: 0px;
  }
}
.SheetContainer-module__root--overflow-hidden___qq6o\+ .SheetContainer-module__inner___5ucYg {
  overflow: hidden;
}
.SheetContainer-module__root--active___ukg45,
[dir='rtl'] .SheetContainer-module__root--active___ukg45 {
  opacity: 1;
  transform: translate(0);
  transition-timing-function: var(--bui_animation_fade_in_timing_function);
  transition-duration: var(--bui_animation_fade_in_duration);
}
.Tab-module__trigger-list___HtkeH {
  position: relative;
}
.Tab-module__trigger-list--animated___ZiQN7 .Tab-module__nav___w6Z7P:after {
  visibility: visible;
}
.Tab-module__nav___w6Z7P {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
.Tab-module__nav___w6Z7P::-webkit-scrollbar {
  display: none;
}
.Tab-module__nav___w6Z7P:after {
  content: '';
  position: absolute;
  inset-block-end: var(--bui_border_width_100);
  height: var(--bui_border_width_200);
  background: var(--bui_color_action_border);
  display: block;
  width: var(--bui-tab-underline-scale-x, 0);
  left: 0;
  transform: translateX(var(--bui-tab-underline-transform-x, 0));
  transition: var(--bui_animation_press);
  transition-property: width, transform;
  visibility: hidden;
}
.Tab-module__item___yBrXf {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
  color: var(--bui_color_foreground);
  position: relative;
}
.Tab-module__item-panel___vOSu1 {
  display: none;
}
.Tab-module__item-panel--active___9Vihl {
  display: block;
}
.Tab-module__item___yBrXf:not(.Tab-module__item--more___HvWwt) .Tab-module__link___oLbA4:after {
  content: '';
  position: absolute;
  inset-inline: 0;
  inset-block-end: calc(var(--bui_border_width_100) * -1);
  height: var(--bui_border_width_200);
  background: transparent;
}
.Tab-module__item--hidden___OZ7C- {
  display: none;
}
.Tab-module__item--more___HvWwt .Tab-module__link___oLbA4 {
  margin: 0;
}
.Tab-module__item--more___HvWwt {
  display: none;
  position: relative;
  margin: 0;
}
.Tab-module__item--selected___QottC {
  color: var(--bui_color_action_foreground);
}
.Tab-module__item--more-active___R2Ltu {
  display: inline-block;
}
.Tab-module__bubble___dDke8 {
  margin-inline-start: var(--bui_spacing_2x);
}
.Tab-module__bubble___dDke8:empty {
  vertical-align: middle;
  margin-block-end: 2px;
}
.Tab-module__link___oLbA4 {
  font-size: var(--bui_font_emphasized_2_font-size);
  font-weight: var(--bui_font_emphasized_2_font-weight);
  line-height: var(--bui_font_emphasized_2_line-height);
  font-family: var(--bui_font_emphasized_2_font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  color: currentcolor;
  padding: var(--bui_spacing_4x);
  text-decoration: none;
  max-width: none;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  outline: none;
  text-align: center;
}
.Tab-module__link___oLbA4:hover {
  background-color: var(--bui_color_highlighted_alt);
  text-decoration: none;
  cursor: pointer;
}
.Tab-module__link___oLbA4:link {
  font-size: var(--bui_font_emphasized_2_font-size);
  font-weight: var(--bui_font_emphasized_2_font-weight);
  line-height: var(--bui_font_emphasized_2_line-height);
  font-family: var(--bui_font_emphasized_2_font-family);
  text-decoration: none;
}
[data-bui-keyboard] .Tab-module__link___oLbA4:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
.Tab-module__title___vdIvw {
  font-size: var(--bui_font_strong_2_font-size);
  font-weight: var(--bui_font_strong_2_font-weight);
  line-height: var(--bui_font_strong_2_line-height);
  font-family: var(--bui_font_strong_2_font-family);
}
.Tab-module__icon___TjeZw,
.Tab-module__title___vdIvw {
  display: inline-block;
  vertical-align: middle;
}
.Tab-module__icon-more--vertical___7rzYt {
  margin-block-end: var(--bui_spacing_1x);
}
.Tab-module__icon-more--horizontal___VUGcI {
  margin-inline-start: var(--bui_spacing_1x);
}
.Tab-module__root--vertical___i0g2Y .Tab-module__link___oLbA4 {
  flex-direction: column;
}
.Tab-module__root--vertical___i0g2Y .Tab-module__icon___TjeZw,
.Tab-module__root--vertical___i0g2Y .Tab-module__title___vdIvw {
  display: block;
}
.Tab-module__root--borderless___Dl7P8 .Tab-module__trigger-list___HtkeH:before {
  content: none;
}
.Tab-module__root--equal___gOJiw .Tab-module__nav___w6Z7P {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.Tab-module__root--equal___gOJiw .Tab-module__item___yBrXf {
  display: flex;
  align-self: stretch;
}
.Tab-module__root--equal___gOJiw .Tab-module__item--more___HvWwt {
  display: none;
}
.Tab-module__trigger-list--variant-underlined___WegWa {
  margin-block-end: calc(var(--bui_border_width_100) * -1);
}
.Tab-module__trigger-list--variant-underlined___WegWa:before {
  content: '';
  position: absolute;
  inset-block-end: var(--bui_border_width_100);
  inset-inline: 0;
  background: var(--bui_color_border_alt);
  height: var(--bui_border_width_100);
}
.Tab-module__trigger-list--variant-underlined___WegWa .Tab-module__nav___w6Z7P {
  padding: 0;
  padding-block-end: var(--bui_border_width_200);
}
.Tab-module__trigger-list--variant-underlined___WegWa:not(.Tab-module__trigger-list--animated___ZiQN7)
  .Tab-module__item___yBrXf:not(.Tab-module__item--more___HvWwt).Tab-module__item--selected___QottC
  .Tab-module__link___oLbA4:after {
  background-color: var(--bui_color_action_border);
}
.Tab-module__trigger-list--variant-rounded___mH93J {
  margin-block-end: calc(var(--bui_border_width_100) * -1);
}
.Tab-module__trigger-list--variant-rounded___mH93J .Tab-module__nav___w6Z7P {
  padding-block-end: var(--bui_border_width_100);
}
.Tab-module__root___fOnSS:not(.Tab-module__root--equal___gOJiw) .Tab-module__trigger-list--variant-rounded___mH93J {
  margin-inline: calc(var(--bui_spacing_4x) * -1);
}
.Tab-module__root___fOnSS:not(.Tab-module__root--equal___gOJiw)
  .Tab-module__trigger-list--variant-rounded___mH93J
  .Tab-module__nav___w6Z7P {
  padding-inline: var(--bui_spacing_4x);
}
.Tab-module__trigger-list--variant-rounded___mH93J .Tab-module__item___yBrXf:not(:first-child) {
  padding-inline-start: var(--bui_spacing_1x);
}
.Tab-module__trigger-list--variant-rounded___mH93J .Tab-module__item___yBrXf .Tab-module__link___oLbA4 {
  padding: calc(var(--bui_spacing_3x) - var(--bui_border_width_100)) var(--bui_spacing_4x);
  border: var(--bui_border_width_100) solid transparent;
  border-radius: 100px;
  transition: var(--bui_animation_press);
  transition-property: border-color, background-color;
}
.Tab-module__root--color-inherit___rUe-8 .Tab-module__item--selected___QottC .Tab-module__link___oLbA4,
.Tab-module__root--color-inherit___rUe-8 .Tab-module__item___yBrXf .Tab-module__link___oLbA4:hover {
  background-color: transparent;
}
.Tab-module__root--color-inherit___rUe-8 .Tab-module__item--selected___QottC,
.Tab-module__root--color-inherit___rUe-8 .Tab-module__item___yBrXf {
  color: currentcolor;
}
.Tab-module__root--color-inherit___rUe-8
  .Tab-module__trigger-list--variant-underlined___WegWa
  .Tab-module__item___yBrXf:not(.Tab-module__item--more___HvWwt).Tab-module__item--selected___QottC
  .Tab-module__link___oLbA4:after,
.Tab-module__root--color-inherit___rUe-8
  .Tab-module__trigger-list--variant-underlined___WegWa
  .Tab-module__nav___w6Z7P[style*='--bui-tab-underline-scale-x']:after {
  background-color: currentcolor;
}
.Tab-module__root--color-inherit___rUe-8 .Tab-module__link___oLbA4:hover:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  inset-inline-start: 0;
  background-color: currentcolor;
  opacity: 0.06;
}
.Tab-module__trigger-list--variant-rounded___mH93J .Tab-module__item--selected___QottC .Tab-module__link___oLbA4 {
  background-color: var(--bui_color_action_highlighted_alt);
  border-color: var(--bui_color_action_border);
}
.Tab-module__root--color-inherit___rUe-8
  .Tab-module__trigger-list--variant-rounded___mH93J
  .Tab-module__item--selected___QottC
  .Tab-module__link___oLbA4:before,
.Tab-module__root--color-inherit___rUe-8
  .Tab-module__trigger-list--variant-rounded___mH93J
  .Tab-module__item___yBrXf
  .Tab-module__link___oLbA4:hover:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  inset-inline-start: 0;
  background-color: currentcolor;
  opacity: 0.1;
  border-radius: 999px;
}
.Tab-module__root--color-inherit___rUe-8
  .Tab-module__trigger-list--variant-rounded___mH93J
  .Tab-module__item--selected___QottC
  .Tab-module__link___oLbA4 {
  border-color: currentcolor;
  background-color: transparent;
}
.Tab-module__root--color-inherit___rUe-8
  .Tab-module__trigger-list--variant-rounded___mH93J
  .Tab-module__item--selected___QottC
  .Tab-module__link___oLbA4,
.Tab-module__root--color-inherit___rUe-8 .Tab-module__trigger-list--variant-rounded___mH93J .Tab-module__link___oLbA4 {
  color: currentcolor;
}
.Tab-module__root--edge-gradient___DA\+2h .Tab-module__nav___w6Z7P {
  -webkit-mask-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.25) 50px, #000 100px),
    linear-gradient(90deg, #000 calc(100% - 100px), rgba(0, 0, 0, 0.25) calc(100% - 50px), transparent),
    linear-gradient(90deg, #000, #000);
  mask-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.25) 50px, #000 100px),
    linear-gradient(90deg, #000 calc(100% - 100px), rgba(0, 0, 0, 0.25) calc(100% - 50px), transparent),
    linear-gradient(90deg, #000, #000);
  -webkit-mask-size: 100px 100%, 100px 100%, var(--bui-tab-main-mask-width) 100%;
  mask-size: 100px 100%, 100px 100%, var(--bui-tab-main-mask-width) 100%;
  -webkit-mask-position: var(--bui-tab-start-mask-offset) 0, var(--bui-tab-end-mask-offset) 0,
    var(--bui-tab-main-mask-offset) 0;
  mask-position: var(--bui-tab-start-mask-offset) 0, var(--bui-tab-end-mask-offset) 0, var(--bui-tab-main-mask-offset) 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  will-change: mask-size, mask-position;
  transition: var(--bui_animation_fade_out);
  transition-property: -webkit-mask-size, -webkit-mask-position;
  transition-property: mask-size, mask-position;
  transition-property: mask-size, mask-position, -webkit-mask-size, -webkit-mask-position;
}
.Tab-module__root--edge-gradient___DA\+2h .Tab-module__trigger-list--mask-both___LHccF .Tab-module__nav___w6Z7P,
.Tab-module__root--edge-gradient___DA\+2h .Tab-module__trigger-list--mask-end___o0fw0 .Tab-module__nav___w6Z7P,
.Tab-module__root--edge-gradient___DA\+2h .Tab-module__trigger-list--mask-start___mZ1Vq .Tab-module__nav___w6Z7P {
  transition-duration: 0ms;
}
.Tab-module__root--edge-gradient___DA\+2h .Tab-module__trigger-list___HtkeH {
  --bui-tab-main-mask-width: calc(100% + 200px);
  --bui-tab-main-mask-offset: 0px;
  --bui-tab-start-mask-offset: -100px;
  --bui-tab-end-mask-offset: calc(100% + 100px);
}
.Tab-module__root--edge-gradient___DA\+2h .Tab-module__trigger-list--mask-start___mZ1Vq {
  --bui-tab-main-mask-width: calc(100% - 100px);
  --bui-tab-main-mask-offset: 100px;
  --bui-tab-start-mask-offset: 0px;
  --bui-tab-end-mask-offset: calc(100% + 100px);
}
.Tab-module__root--edge-gradient___DA\+2h .Tab-module__trigger-list--mask-end___o0fw0 {
  --bui-tab-main-mask-width: calc(100% - 100px);
  --bui-tab-main-mask-offset: 0px;
  --bui-tab-start-mask-offset: -100px;
  --bui-tab-end-mask-offset: 100%;
}
.Tab-module__root--edge-gradient___DA\+2h .Tab-module__trigger-list--mask-both___LHccF {
  --bui-tab-main-mask-width: calc(100% - 200px);
  --bui-tab-main-mask-offset: 100px;
  --bui-tab-start-mask-offset: 0px;
  --bui-tab-end-mask-offset: 100%;
}
@media (max-width: 575px), (min-width: 576px) and (hover: none) {
  .Tab-module__root___fOnSS:not(.Tab-module__root--equal___gOJiw) .Tab-module__trigger-list___HtkeH {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
  }
  .Tab-module__root___fOnSS:not(.Tab-module__root--equal___gOJiw) .Tab-module__nav___w6Z7P {
    overflow-x: auto;
  }
  .Tab-module__root___fOnSS:not(.Tab-module__root--equal___gOJiw) .Tab-module__item--more___HvWwt {
    display: none;
  }
}
@media (min-width: 576px) and (hover: none) {
  .Tab-module__root___fOnSS:not(.Tab-module__root--equal___gOJiw) .Tab-module__item--hidden___OZ7C- {
    display: inline-block;
  }
}
.Table-module__root___6G3d5 {
  position: relative;
}
.Table-module__scroll___lUUo1 {
  overflow: auto;
  margin: 0 calc(var(--bui_spacing_4x) * -1);
  padding: 0 var(--bui_spacing_4x);
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent,
    #000 var(--bui_spacing_4x),
    #000 calc(100% - var(--bui_spacing_4x)),
    transparent
  );
  mask-image: linear-gradient(
    90deg,
    transparent,
    #000 var(--bui_spacing_4x),
    #000 calc(100% - var(--bui_spacing_4x)),
    transparent
  );
}
.Table-module__table___hv86o {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: var(--bui_border_radius_200);
  border: var(--bui_border_width_100) solid var(--bui_color_border_alt);
  background: var(--bui_color_background_elevation_one);
}
.Table-module__head___zn4xe .Table-module__cell___AAg\+j {
  border-block-end: var(--bui_border_width_100) solid var(--bui_color_border_alt);
}
.Table-module__row___6YK6A {
  transition: background-color var(--bui_animation_press);
}
.Table-module__row--active___-TBC7 {
  background-color: var(--bui_color_action_background_alt);
}
.Table-module__row--expanded-content___3vu9K .Table-module__cell___AAg\+j {
  border-block-start: none !important;
  padding-block-start: 0;
}
.Table-module__row--vertical-align-center___dbwiF .Table-module__cell___AAg\+j {
  vertical-align: middle;
}
.Table-module__cell___AAg\+j {
  box-sizing: border-box;
  padding: var(--bui_spacing_4x);
  text-align: start;
  vertical-align: top;
}
.Table-module__cell___AAg\+j:not(:last-child) {
  padding-inline-end: 0;
}
.Table-module__row___6YK6A:not(:first-child) .Table-module__cell___AAg\+j {
  border-block-start: 1px solid var(--bui_color_border_alt);
}
.Table-module__cell--align-center___I3atn {
  text-align: center;
}
.Table-module__cell--align-end___bTVnH {
  text-align: end;
}
.Table-module__root--borderless___6dntI .Table-module__table___hv86o {
  border: 0;
  background: transparent;
}
.Table-module__root--borderless___6dntI .Table-module__cell___AAg\+j:first-child {
  padding-inline-start: 0;
}
.Table-module__root--borderless___6dntI .Table-module__cell___AAg\+j:last-child {
  padding-inline-end: 0;
}
.Table-module__root--compact___TvhW1 .Table-module__cell___AAg\+j {
  padding: var(--bui_spacing_2x);
}
.Table-module__root--compact___TvhW1.Table-module__root--borderless___6dntI .Table-module__cell___AAg\+j:first-child {
  padding-inline-start: 0;
}
.Table-module__root--compact___TvhW1.Table-module__root--borderless___6dntI .Table-module__cell___AAg\+j:last-child {
  padding-inline-end: 0;
}
.Table-module__root--sticky-header___u4oIh .Table-module__head___zn4xe {
  position: sticky;
  inset-block-start: 0;
  z-index: var(--bui_z_index_4);
}
.Table-module__root--sticky-header___u4oIh .Table-module__head___zn4xe .Table-module__cell___AAg\+j {
  background: var(--bui_color_background_elevation_two);
}
.Table-module__root--sticky-header___u4oIh .Table-module__head___zn4xe .Table-module__cell___AAg\+j:first-child {
  border-start-start-radius: var(--bui_border_radius_200);
}
.Table-module__root--sticky-header___u4oIh .Table-module__head___zn4xe .Table-module__cell___AAg\+j:last-child {
  border-start-end-radius: var(--bui_border_radius_200);
}
@media (min-width: 1024px) {
  .Table-module__scroll___lUUo1 {
    overflow: visible;
  }
}
.Timeline-module__root___K1b-r {
  margin: 0;
  padding: 0;
  list-style: none;
}
.Timeline-module__marker___3HzlC {
  position: absolute;
  inset-inline-start: 0;
  inset-block: 0;
  width: var(--bui_spacing_6x);
  padding: var(--bui_spacing_1x) 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Timeline-module__marker___3HzlC:empty:before {
  content: '';
  display: block;
  height: var(--bui_spacing_3x);
  width: var(--bui_spacing_3x);
  border-radius: 50%;
  border: var(--bui_border_width_200) solid;
  box-sizing: border-box;
}
.Timeline-module__marker___3HzlC:after {
  content: '';
  flex-grow: 1;
  margin-block-start: var(--bui_spacing_2x);
  border-inline-start: var(--bui_border_width_100) solid var(--bui_color_border);
  position: relative;
}
.Timeline-module__item___-pvfZ {
  position: relative;
  padding-block-end: var(--bui_spacing_6x);
  padding-inline-start: calc(var(--bui_spacing_1x) * 10);
}
.Timeline-module__item___-pvfZ:last-child {
  padding-block-end: 0;
}
.Timeline-module__item___-pvfZ:last-child .Timeline-module__marker___3HzlC:after {
  content: none;
}
.Timeline-module__item--line-variant-dashed___fSv-t .Timeline-module__marker___3HzlC:after {
  border-inline-start-style: dashed;
}
.Toast-module__root___SSWcb {
  position: fixed;
  inset-inline-start: var(--bui_spacing_6x);
  inset-block-end: var(--bui_spacing_6x);
  background: var(--bui_color_background_inverted);
  border-radius: var(--bui_border_radius_200);
  color: var(--bui_color_foreground_inverted);
  padding: var(--bui_spacing_4x);
  width: calc(var(--bui_spacing_8x) * 10);
  z-index: var(--bui_z_index_4);
  box-shadow: var(--bui_shadow_100);
  font-size: var(--bui_font_body_2_font-size);
  font-weight: var(--bui_font_body_2_font-weight);
  line-height: var(--bui_font_body_2_line-height);
  font-family: var(--bui_font_body_2_font-family);
}
.Toast-module__button___JapP3 {
  color: var(--bui_color_action_foreground_inverted);
  margin-inline-start: auto;
}
@media (max-width: 575px) {
  .Toast-module__root___SSWcb {
    width: auto;
    inset-inline: var(--bui_spacing_4x);
    inset-block-end: var(--bui_spacing_4x);
  }
}
.Toast-module__root--enter-from___\+2jzi {
  transform: translateY(100%);
  opacity: 0;
}
.Toast-module__root--enter-settings___nnrxb {
  transition: var(--bui_animation_enter);
  transition-property: opacity, transform;
}
.Toast-module__container___6ay4Y:not(:last-child),
.Toast-module__root--exit-to___B68V0 {
  transform: scale(0.84);
  opacity: 0;
  pointer-events: none;
}
.Toast-module__container___6ay4Y:not(:last-child),
.Toast-module__root--exit-settings___5Ir2j {
  transition: var(--bui_animation_exit);
  transition-property: opacity, transform;
}
.Toast-module__container___6ay4Y:not(:last-child) {
  transition-duration: 1ms;
}
.Tooltip-module__root___Ug-TX {
  font-size: var(--bui_font_body_2_font-size);
  font-weight: var(--bui_font_body_2_font-weight);
  line-height: var(--bui_font_body_2_line-height);
  font-family: var(--bui_font_body_2_font-family);
  box-shadow: var(--bui_shadow_100);
  background: var(--bui_color_background_inverted);
  color: var(--bui_color_foreground_inverted);
  display: inline-block;
  border-radius: var(--bui_border_radius_100);
  padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
  pointer-events: none;
  position: relative;
  box-sizing: border-box;
}
.Tooltip-module__arrow--hide___wHP7b {
  display: none;
}
@media (max-width: 575px) {
  .Tooltip-module__arrow___70HyB {
    display: none;
  }
}
@media (min-width: 576px) {
  .Tooltip-module__root___Ug-TX {
    max-width: calc(var(--bui_spacing_8x) * 10);
  }
}
:root {
  --bui_z_index_0: 0;
  --bui_z_index_1: 100;
  --bui_z_index_2: 200;
  --bui_z_index_3: 300;
  --bui_z_index_4: 400;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: var(--bui_color_foreground);
  margin: 0;
}
body {
  font-size: var(--bui_font_body_2_font-size);
  font-weight: var(--bui_font_body_2_font-weight);
  line-height: var(--bui_font_body_2_line-height);
  font-family: var(--bui_font_body_2_font-family);
  color: var(--bui_color_foreground);
  margin: 0;
  background-color: var(--bui_color_background_base);
}
h1 {
  font-size: var(--bui_font_headline_2_font-size);
  font-weight: var(--bui_font_headline_2_font-weight);
  line-height: var(--bui_font_headline_2_line-height);
  font-family: var(--bui_font_headline_2_font-family);
}
h2 {
  font-size: var(--bui_font_headline_3_font-size);
  font-weight: var(--bui_font_headline_3_font-weight);
  line-height: var(--bui_font_headline_3_line-height);
  font-family: var(--bui_font_headline_3_font-family);
}
h3 {
  font-size: var(--bui_font_strong_1_font-size);
  font-weight: var(--bui_font_strong_1_font-weight);
  line-height: var(--bui_font_strong_1_line-height);
  font-family: var(--bui_font_strong_1_font-family);
}
h4,
h5,
h6 {
  font-size: var(--bui_font_strong_2_font-size);
  font-weight: var(--bui_font_strong_2_font-weight);
  line-height: var(--bui_font_strong_2_line-height);
  font-family: var(--bui_font_strong_2_font-family);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-end: 0.67em;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child {
  margin-block-start: 0;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child {
  margin-block-end: 0;
}
[data-bui-trap-focus-placeholder]:focus + iframe {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
}
[data-bui-theme='traveller_ex-light'] {
  --bui_color_border: #868686;
  --bui_color_border_alt: #e7e7e7;
  --bui_color_action_border: #006ce4;
  --bui_color_border_disabled: #d9d9d9;
  --bui_color_destructive_border: #d4111e;
  --bui_color_constructive_border: #008234;
  --bui_color_foreground: #1a1a1a;
  --bui_color_foreground_alt: #595959;
  --bui_color_foreground_inverted: #f5f5f5;
  --bui_color_accent_foreground: #946800;
  --bui_color_action_foreground: #006ce4;
  --bui_color_callout_foreground: #923e01;
  --bui_color_foreground_disabled: #a2a2a2;
  --bui_color_destructive_foreground: #d4111e;
  --bui_color_constructive_foreground: #008234;
  --bui_color_foreground_disabled_alt: #d9d9d9;
  --bui_color_brand_primary_foreground: #003b95;
  --bui_color_action_foreground_inverted: #57a6f4;
  --bui_color_action_focus: rgba(0, 108, 228, 0.24);
  --bui_color_highlighted_alt: rgba(26, 26, 26, 0.06);
  --bui_color_action_highlighted_alt: rgba(0, 108, 228, 0.06);
  --bui_color_destructive_highlighted_alt: rgba(212, 17, 30, 0.06);
  --bui_color_highlighted: #cecece;
  --bui_color_destructive_focus: rgba(212, 17, 30, 0.24);
  --bui_color_on_background: #fff;
  --bui_color_background_inverted: #1a1a1a;
  --bui_color_background_inverted_rgb: 26, 26, 26;
  --bui_color_background: #595959;
  --bui_color_background_rgb: 89, 89, 89;
  --bui_color_background_alt: #f5f5f5;
  --bui_color_background_alt_rgb: 245, 245, 245;
  --bui_color_background_base: #fff;
  --bui_color_background_base_rgb: 255, 255, 255;
  --bui_color_background_base_alt: #f5f5f5;
  --bui_color_background_base_alt_rgb: 245, 245, 245;
  --bui_color_background_disabled: #d9d9d9;
  --bui_color_background_disabled_rgb: 217, 217, 217;
  --bui_color_accent_background_alt: #fffaec;
  --bui_color_accent_background_alt_rgb: 255, 250, 236;
  --bui_color_action_background_alt: #f0f6ff;
  --bui_color_action_background_alt_rgb: 240, 246, 255;
  --bui_color_callout_background_alt: #fef3ec;
  --bui_color_callout_background_alt_rgb: 254, 243, 236;
  --bui_color_background_disabled_alt: #f5f5f5;
  --bui_color_background_disabled_alt_rgb: 245, 245, 245;
  --bui_color_destructive_background_alt: #fff5f5;
  --bui_color_destructive_background_alt_rgb: 255, 245, 245;
  --bui_color_constructive_background_alt: #f1fef6;
  --bui_color_constructive_background_alt_rgb: 241, 254, 246;
  --bui_color_on_accent_background_dynamic: #242424;
  --bui_color_on_callout_background_dynamic: #242424;
  --bui_color_on_destructive_background_dynamic: #fff;
  --bui_color_on_constructive_background_dynamic: #fff;
  --bui_color_background_elevation_one: #fff;
  --bui_color_background_elevation_two: #fff;
  --bui_color_background_elevation_two_rgb: 255, 255, 255;
  --bui_color_accent_background_dynamic: #ffb700;
  --bui_color_callout_background_dynamic: #f56700;
  --bui_color_destructive_background_dynamic: #d4111e;
  --bui_color_constructive_background_dynamic: #008234;
  --bui_color_brand_primary_background_dynamic: #003b95;
}
[data-bui-theme='traveller_ex-dark'] {
  --bui_color_border: #a2a2a2;
  --bui_color_border_alt: #595959;
  --bui_color_action_border: #57a6f4;
  --bui_color_border_disabled: #868686;
  --bui_color_destructive_border: #e56b74;
  --bui_color_constructive_border: #24a85b;
  --bui_color_foreground: #f5f5f5;
  --bui_color_foreground_alt: #d9d9d9;
  --bui_color_foreground_inverted: #1a1a1a;
  --bui_color_accent_foreground: #ffb700;
  --bui_color_action_foreground: #57a6f4;
  --bui_color_callout_foreground: #f56700;
  --bui_color_foreground_disabled: #868686;
  --bui_color_destructive_foreground: #e56b74;
  --bui_color_constructive_foreground: #24a85b;
  --bui_color_foreground_disabled_alt: #595959;
  --bui_color_brand_primary_foreground: #cee6ff;
  --bui_color_action_foreground_inverted: #006ce4;
  --bui_color_action_focus: rgba(87, 166, 244, 0.24);
  --bui_color_highlighted_alt: hsla(0, 0%, 100%, 0.12);
  --bui_color_action_highlighted_alt: rgba(87, 166, 244, 0.12);
  --bui_color_destructive_highlighted_alt: rgba(229, 107, 116, 0.12);
  --bui_color_highlighted: #4b4b4b;
  --bui_color_destructive_focus: rgba(229, 107, 116, 0.24);
  --bui_color_on_background: #242424;
  --bui_color_background_inverted: #f5f5f5;
  --bui_color_background_inverted_rgb: 245, 245, 245;
  --bui_color_background: #d9d9d9;
  --bui_color_background_rgb: 217, 217, 217;
  --bui_color_background_alt: #2b2b2b;
  --bui_color_background_alt_rgb: 43, 43, 43;
  --bui_color_background_base: #1a1a1a;
  --bui_color_background_base_rgb: 26, 26, 26;
  --bui_color_background_base_alt: #1a1a1a;
  --bui_color_background_base_alt_rgb: 26, 26, 26;
  --bui_color_background_disabled: #595959;
  --bui_color_background_disabled_rgb: 89, 89, 89;
  --bui_color_accent_background_alt: #463301;
  --bui_color_accent_background_alt_rgb: 70, 51, 1;
  --bui_color_action_background_alt: #041b43;
  --bui_color_action_background_alt_rgb: 4, 27, 67;
  --bui_color_callout_background_alt: #411b01;
  --bui_color_callout_background_alt_rgb: 65, 27, 1;
  --bui_color_background_disabled_alt: #2b2b2b;
  --bui_color_background_disabled_alt_rgb: 43, 43, 43;
  --bui_color_destructive_background_alt: #420a0e;
  --bui_color_destructive_background_alt_rgb: 66, 10, 14;
  --bui_color_constructive_background_alt: #083019;
  --bui_color_constructive_background_alt_rgb: 8, 48, 25;
  --bui_color_on_accent_background_dynamic: #ffb700;
  --bui_color_on_callout_background_dynamic: #f56700;
  --bui_color_on_destructive_background_dynamic: #e56b74;
  --bui_color_on_constructive_background_dynamic: #24a85b;
  --bui_color_background_elevation_one: #242424;
  --bui_color_background_elevation_two: #2b2b2b;
  --bui_color_background_elevation_two_rgb: 43, 43, 43;
  --bui_color_accent_background_dynamic: #2b2b2b;
  --bui_color_callout_background_dynamic: #2b2b2b;
  --bui_color_destructive_background_dynamic: #2b2b2b;
  --bui_color_constructive_background_dynamic: #2b2b2b;
  --bui_color_brand_primary_background_dynamic: #2b2b2b;
}
[data-bui-theme='traveller_ex-dark'],
[data-bui-theme='traveller_ex-light'] {
  --bui_color_accent_border: #ffb700;
  --bui_color_callout_border: #f56700;
  --bui_color_brand_genius_secondary_foreground: #febb02;
  --bui_color_cta_highlighted: #0057b8;
  --bui_color_action_highlighted: #0057b8;
  --bui_color_destructive_highlighted: #af0e19;
  --bui_color_on_accent_background: #242424;
  --bui_color_on_callout_background: #242424;
  --bui_color_on_cta_background: #fff;
  --bui_color_on_action_background: #fff;
  --bui_color_on_destructive_background: #fff;
  --bui_color_on_constructive_background: #fff;
  --bui_color_on_brand_primary_background: #fff;
  --bui_color_on_brand_genius_primary_background: #fff;
  --bui_color_transparent: rgba(26, 26, 26, 0);
  --bui_color_cta_background: #006ce4;
  --bui_color_cta_background_rgb: 0, 108, 228;
  --bui_color_black_with_alpha: rgba(26, 26, 26, 0.5);
  --bui_color_accent_background: #ffb700;
  --bui_color_accent_background_rgb: 255, 183, 0;
  --bui_color_action_background: #006ce4;
  --bui_color_action_background_rgb: 0, 108, 228;
  --bui_color_callout_background: #f56700;
  --bui_color_callout_background_rgb: 245, 103, 0;
  --bui_color_destructive_background: #d4111e;
  --bui_color_destructive_background_rgb: 212, 17, 30;
  --bui_color_constructive_background: #008234;
  --bui_color_constructive_background_rgb: 0, 130, 52;
  --bui_color_brand_primary_background: #003b95;
  --bui_color_brand_primary_background_rgb: 0, 59, 149;
  --bui_color_brand_genius_primary_background: #003b95;
  --bui_color_brand_genius_primary_background_rgb: 0, 59, 149;
  --bui_color_on_brand_primary_background_dynamic: #fff;
  --bui_color_black: #1a1a1a;
  --bui_color_black_rgb: 26, 26, 26;
  --bui_color_white: #fff;
  --bui_color_white_rgb: 255, 255, 255;
  --bui_shadow_100: 0px 2px 8px 0px rgba(26, 26, 26, 0.16);
  --bui_shadow_200: 0px 2px 16px 0px rgba(26, 26, 26, 0.24);
  --bui_shadow_300: 0px 4px 16px 0px rgba(26, 26, 26, 0.32);
  --bui_spacing_1x: 4px;
  --bui_spacing_2x: 8px;
  --bui_spacing_3x: 12px;
  --bui_spacing_4x: 16px;
  --bui_spacing_6x: 24px;
  --bui_spacing_8x: 32px;
  --bui_spacing_12x: 48px;
  --bui_spacing_16x: 64px;
  --bui_spacing_24x: 96px;
  --bui_spacing_half: 2px;
  --bui_border_width_100: 1px;
  --bui_border_width_200: 2px;
  --bui_border_radius_100: 4px;
  --bui_border_radius_200: 8px;
  --bui_border_radius_300: 12px;
  --bui_rem_pixel: 0.0625rem;
  --bui_rem_spacing_1x: calc(var(--bui_rem_pixel) * 4);
  --bui_animation_exit_duration: var(--bui_animation_reduced_motion_duration, 0.12s);
  --bui_animation_exit_timing_function: cubic-bezier(0.2, 0, 1, 0.8);
  --bui_animation_exit: var(--bui_animation_exit_duration) var(--bui_animation_exit_timing_function);
  --bui_animation_enter_duration: var(--bui_animation_reduced_motion_duration, 0.12s);
  --bui_animation_enter_timing_function: cubic-bezier(0, 0, 0.4, 0.8);
  --bui_animation_enter: var(--bui_animation_enter_duration) var(--bui_animation_enter_timing_function);
  --bui_animation_hover_duration: var(--bui_animation_reduced_motion_duration, 0.12s);
  --bui_animation_hover_timing_function: cubic-bezier(0.2, 0, 0.4, 0.8);
  --bui_animation_hover: var(--bui_animation_hover_duration) var(--bui_animation_hover_timing_function);
  --bui_animation_press_duration: var(--bui_animation_reduced_motion_duration, 0.12s);
  --bui_animation_press_timing_function: cubic-bezier(0.2, 0, 0.4, 0.8);
  --bui_animation_press: var(--bui_animation_press_duration) var(--bui_animation_press_timing_function);
  --bui_animation_appear_duration: var(--bui_animation_reduced_motion_duration, 0.24s);
  --bui_animation_appear_timing_function: cubic-bezier(0, 0, 0.4, 1);
  --bui_animation_appear: var(--bui_animation_appear_duration) var(--bui_animation_appear_timing_function);
  --bui_animation_fade_in_duration: var(--bui_animation_reduced_motion_duration, 0.24s);
  --bui_animation_fade_in_timing_function: cubic-bezier(0, 0, 0.4, 1);
  --bui_animation_fade_in: var(--bui_animation_fade_in_duration) var(--bui_animation_fade_in_timing_function);
  --bui_animation_fade_out_duration: var(--bui_animation_reduced_motion_duration, 0.12s);
  --bui_animation_fade_out_timing_function: cubic-bezier(1, 0, 1, 1);
  --bui_animation_fade_out: var(--bui_animation_fade_out_duration) var(--bui_animation_fade_out_timing_function);
  --bui_animation_disappear_duration: var(--bui_animation_reduced_motion_duration, 0.12s);
  --bui_animation_disappear_timing_function: cubic-bezier(0.2, 0, 1, 0.8);
  --bui_animation_disappear: var(--bui_animation_disappear_duration) var(--bui_animation_disappear_timing_function);
  --bui_animation_page_transition_exit_duration: var(--bui_animation_reduced_motion_duration, 0.48s);
  --bui_animation_page_transition_exit_timing_function: cubic-bezier(1, 0, 1, 1);
  --bui_animation_page_transition_exit: var(--bui_animation_page_transition_exit_duration)
    var(--bui_animation_page_transition_exit_timing_function);
  --bui_animation_page_transition_enter_duration: var(--bui_animation_reduced_motion_duration, 0.48s);
  --bui_animation_page_transition_enter_timing_function: cubic-bezier(0, 0, 0.4, 1);
  --bui_animation_page_transition_enter: var(--bui_animation_page_transition_enter_duration)
    var(--bui_animation_page_transition_enter_timing_function);
}
@media (prefers-reduced-motion) {
  [data-bui-theme='traveller_ex-dark'],
  [data-bui-theme='traveller_ex-light'] {
    --bui_animation_reduced_motion_duration: 1ms;
  }
}
[data-bui-theme='traveller_ex-dark'],
[data-bui-theme='traveller_ex-light'] {
  --bui_font_body_1_font-size: calc(var(--bui_rem_pixel) * 16);
  --bui_font_body_1_font-weight: 400;
  --bui_font_body_1_line-height: calc(var(--bui_rem_pixel) * 24);
  --bui_font_body_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --bui_font_body_2_font-size: calc(var(--bui_rem_pixel) * 14);
  --bui_font_body_2_font-weight: 400;
  --bui_font_body_2_line-height: calc(var(--bui_rem_pixel) * 20);
  --bui_font_body_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --bui_font_small_1_font-size: calc(var(--bui_rem_pixel) * 12);
  --bui_font_small_1_font-weight: 400;
  --bui_font_small_1_line-height: calc(var(--bui_rem_pixel) * 18);
  --bui_font_small_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --bui_font_small_2_font-size: calc(var(--bui_rem_pixel) * 10);
  --bui_font_small_2_font-weight: 500;
  --bui_font_small_2_line-height: calc(var(--bui_rem_pixel) * 16);
  --bui_font_small_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --bui_font_strong_1_font-size: calc(var(--bui_rem_pixel) * 16);
  --bui_font_strong_1_font-weight: 700;
  --bui_font_strong_1_line-height: calc(var(--bui_rem_pixel) * 24);
  --bui_font_strong_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --bui_font_strong_2_font-size: calc(var(--bui_rem_pixel) * 14);
  --bui_font_strong_2_font-weight: 700;
  --bui_font_strong_2_line-height: calc(var(--bui_rem_pixel) * 20);
  --bui_font_strong_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --bui_font_display_1_font-size: calc(var(--bui_rem_pixel) * 40);
  --bui_font_display_1_font-weight: 800;
  --bui_font_display_1_line-height: calc(var(--bui_rem_pixel) * 52);
  --bui_font_display_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_display_2_font-size: calc(var(--bui_rem_pixel) * 36);
  --bui_font_display_2_font-weight: 800;
  --bui_font_display_2_line-height: calc(var(--bui_rem_pixel) * 48);
  --bui_font_display_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_display_3_font-size: calc(var(--bui_rem_pixel) * 32);
  --bui_font_display_3_font-weight: 800;
  --bui_font_display_3_line-height: calc(var(--bui_rem_pixel) * 40);
  --bui_font_display_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_featured_1_font-size: calc(var(--bui_rem_pixel) * 20);
  --bui_font_featured_1_font-weight: 400;
  --bui_font_featured_1_line-height: calc(var(--bui_rem_pixel) * 28);
  --bui_font_featured_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_featured_2_font-size: calc(var(--bui_rem_pixel) * 20);
  --bui_font_featured_2_font-weight: 400;
  --bui_font_featured_2_line-height: calc(var(--bui_rem_pixel) * 28);
  --bui_font_featured_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_featured_3_font-size: calc(var(--bui_rem_pixel) * 20);
  --bui_font_featured_3_font-weight: 400;
  --bui_font_featured_3_line-height: calc(var(--bui_rem_pixel) * 28);
  --bui_font_featured_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_headline_1_font-size: calc(var(--bui_rem_pixel) * 28);
  --bui_font_headline_1_font-weight: 700;
  --bui_font_headline_1_line-height: calc(var(--bui_rem_pixel) * 36);
  --bui_font_headline_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_headline_2_font-size: calc(var(--bui_rem_pixel) * 24);
  --bui_font_headline_2_font-weight: 700;
  --bui_font_headline_2_line-height: calc(var(--bui_rem_pixel) * 32);
  --bui_font_headline_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_headline_3_font-size: calc(var(--bui_rem_pixel) * 20);
  --bui_font_headline_3_font-weight: 700;
  --bui_font_headline_3_line-height: calc(var(--bui_rem_pixel) * 28);
  --bui_font_headline_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif;
  --bui_font_emphasized_1_font-size: calc(var(--bui_rem_pixel) * 16);
  --bui_font_emphasized_1_font-weight: 500;
  --bui_font_emphasized_1_line-height: calc(var(--bui_rem_pixel) * 24);
  --bui_font_emphasized_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --bui_font_emphasized_2_font-size: calc(var(--bui_rem_pixel) * 14);
  --bui_font_emphasized_2_font-weight: 500;
  --bui_font_emphasized_2_line-height: calc(var(--bui_rem_pixel) * 20);
  --bui_font_emphasized_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
}
@media (min-width: 576px) {
  [data-bui-theme='traveller_ex-dark'],
  [data-bui-theme='traveller_ex-light'] {
    --bui_font_body_1_font-size: calc(var(--bui_rem_pixel) * 16);
    --bui_font_body_1_font-weight: 400;
    --bui_font_body_1_line-height: calc(var(--bui_rem_pixel) * 24);
    --bui_font_body_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_body_2_font-size: calc(var(--bui_rem_pixel) * 14);
    --bui_font_body_2_font-weight: 400;
    --bui_font_body_2_line-height: calc(var(--bui_rem_pixel) * 20);
    --bui_font_body_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_small_1_font-size: calc(var(--bui_rem_pixel) * 12);
    --bui_font_small_1_font-weight: 400;
    --bui_font_small_1_line-height: calc(var(--bui_rem_pixel) * 18);
    --bui_font_small_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_small_2_font-size: calc(var(--bui_rem_pixel) * 12);
    --bui_font_small_2_font-weight: 500;
    --bui_font_small_2_line-height: calc(var(--bui_rem_pixel) * 18);
    --bui_font_small_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_strong_1_font-size: calc(var(--bui_rem_pixel) * 16);
    --bui_font_strong_1_font-weight: 700;
    --bui_font_strong_1_line-height: calc(var(--bui_rem_pixel) * 24);
    --bui_font_strong_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_strong_2_font-size: calc(var(--bui_rem_pixel) * 14);
    --bui_font_strong_2_font-weight: 700;
    --bui_font_strong_2_line-height: calc(var(--bui_rem_pixel) * 20);
    --bui_font_strong_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_display_1_font-size: calc(var(--bui_rem_pixel) * 64);
    --bui_font_display_1_font-weight: 800;
    --bui_font_display_1_line-height: calc(var(--bui_rem_pixel) * 72);
    --bui_font_display_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
    --bui_font_display_2_font-size: calc(var(--bui_rem_pixel) * 56);
    --bui_font_display_2_font-weight: 800;
    --bui_font_display_2_line-height: calc(var(--bui_rem_pixel) * 64);
    --bui_font_display_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
    --bui_font_display_3_font-size: calc(var(--bui_rem_pixel) * 48);
    --bui_font_display_3_font-weight: 800;
    --bui_font_display_3_line-height: calc(var(--bui_rem_pixel) * 62);
    --bui_font_display_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
    --bui_font_featured_1_font-size: calc(var(--bui_rem_pixel) * 32);
    --bui_font_featured_1_font-weight: 400;
    --bui_font_featured_1_line-height: calc(var(--bui_rem_pixel) * 40);
    --bui_font_featured_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_featured_2_font-size: calc(var(--bui_rem_pixel) * 24);
    --bui_font_featured_2_font-weight: 400;
    --bui_font_featured_2_line-height: calc(var(--bui_rem_pixel) * 32);
    --bui_font_featured_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_featured_3_font-size: calc(var(--bui_rem_pixel) * 20);
    --bui_font_featured_3_font-weight: 400;
    --bui_font_featured_3_line-height: calc(var(--bui_rem_pixel) * 28);
    --bui_font_featured_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_headline_1_font-size: calc(var(--bui_rem_pixel) * 32);
    --bui_font_headline_1_font-weight: 700;
    --bui_font_headline_1_line-height: calc(var(--bui_rem_pixel) * 40);
    --bui_font_headline_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_headline_2_font-size: calc(var(--bui_rem_pixel) * 24);
    --bui_font_headline_2_font-weight: 700;
    --bui_font_headline_2_line-height: calc(var(--bui_rem_pixel) * 32);
    --bui_font_headline_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_headline_3_font-size: calc(var(--bui_rem_pixel) * 20);
    --bui_font_headline_3_font-weight: 700;
    --bui_font_headline_3_line-height: calc(var(--bui_rem_pixel) * 28);
    --bui_font_headline_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_emphasized_1_font-size: calc(var(--bui_rem_pixel) * 16);
    --bui_font_emphasized_1_font-weight: 500;
    --bui_font_emphasized_1_line-height: calc(var(--bui_rem_pixel) * 24);
    --bui_font_emphasized_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
    --bui_font_emphasized_2_font-size: calc(var(--bui_rem_pixel) * 14);
    --bui_font_emphasized_2_font-weight: 500;
    --bui_font_emphasized_2_line-height: calc(var(--bui_rem_pixel) * 20);
    --bui_font_emphasized_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
  }
}
@media (min-width: 1024px) {
  [data-bui-theme='traveller_ex-dark'],
  [data-bui-theme='traveller_ex-light'] {
    --bui_font_body_1_font-size: calc(var(--bui_rem_pixel) * 16);
    --bui_font_body_1_font-weight: 400;
    --bui_font_body_1_line-height: calc(var(--bui_rem_pixel) * 24);
    --bui_font_body_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_body_2_font-size: calc(var(--bui_rem_pixel) * 14);
    --bui_font_body_2_font-weight: 400;
    --bui_font_body_2_line-height: calc(var(--bui_rem_pixel) * 20);
    --bui_font_body_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_small_1_font-size: calc(var(--bui_rem_pixel) * 12);
    --bui_font_small_1_font-weight: 400;
    --bui_font_small_1_line-height: calc(var(--bui_rem_pixel) * 18);
    --bui_font_small_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_small_2_font-size: calc(var(--bui_rem_pixel) * 12);
    --bui_font_small_2_font-weight: 500;
    --bui_font_small_2_line-height: calc(var(--bui_rem_pixel) * 18);
    --bui_font_small_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_strong_1_font-size: calc(var(--bui_rem_pixel) * 16);
    --bui_font_strong_1_font-weight: 700;
    --bui_font_strong_1_line-height: calc(var(--bui_rem_pixel) * 24);
    --bui_font_strong_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_strong_2_font-size: calc(var(--bui_rem_pixel) * 14);
    --bui_font_strong_2_font-weight: 700;
    --bui_font_strong_2_line-height: calc(var(--bui_rem_pixel) * 20);
    --bui_font_strong_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --bui_font_display_1_font-size: calc(var(--bui_rem_pixel) * 96);
    --bui_font_display_1_font-weight: 800;
    --bui_font_display_1_line-height: calc(var(--bui_rem_pixel) * 108);
    --bui_font_display_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
    --bui_font_display_2_font-size: calc(var(--bui_rem_pixel) * 64);
    --bui_font_display_2_font-weight: 800;
    --bui_font_display_2_line-height: calc(var(--bui_rem_pixel) * 72);
    --bui_font_display_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
    --bui_font_display_3_font-size: calc(var(--bui_rem_pixel) * 48);
    --bui_font_display_3_font-weight: 800;
    --bui_font_display_3_line-height: calc(var(--bui_rem_pixel) * 62);
    --bui_font_display_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
    --bui_font_featured_1_font-size: calc(var(--bui_rem_pixel) * 32);
    --bui_font_featured_1_font-weight: 400;
    --bui_font_featured_1_line-height: calc(var(--bui_rem_pixel) * 40);
    --bui_font_featured_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_featured_2_font-size: calc(var(--bui_rem_pixel) * 24);
    --bui_font_featured_2_font-weight: 400;
    --bui_font_featured_2_line-height: calc(var(--bui_rem_pixel) * 32);
    --bui_font_featured_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_featured_3_font-size: calc(var(--bui_rem_pixel) * 20);
    --bui_font_featured_3_font-weight: 400;
    --bui_font_featured_3_line-height: calc(var(--bui_rem_pixel) * 28);
    --bui_font_featured_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_headline_1_font-size: calc(var(--bui_rem_pixel) * 32);
    --bui_font_headline_1_font-weight: 700;
    --bui_font_headline_1_line-height: calc(var(--bui_rem_pixel) * 40);
    --bui_font_headline_1_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_headline_2_font-size: calc(var(--bui_rem_pixel) * 24);
    --bui_font_headline_2_font-weight: 700;
    --bui_font_headline_2_line-height: calc(var(--bui_rem_pixel) * 32);
    --bui_font_headline_2_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_headline_3_font-size: calc(var(--bui_rem_pixel) * 20);
    --bui_font_headline_3_font-weight: 700;
    --bui_font_headline_3_line-height: calc(var(--bui_rem_pixel) * 28);
    --bui_font_headline_3_font-family: 'Blue Sans', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    --bui_font_emphasized_1_font-size: calc(var(--bui_rem_pixel) * 16);
    --bui_font_emphasized_1_font-weight: 500;
    --bui_font_emphasized_1_line-height: calc(var(--bui_rem_pixel) * 24);
    --bui_font_emphasized_1_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
    --bui_font_emphasized_2_font-size: calc(var(--bui_rem_pixel) * 14);
    --bui_font_emphasized_2_font-weight: 500;
    --bui_font_emphasized_2_line-height: calc(var(--bui_rem_pixel) * 20);
    --bui_font_emphasized_2_font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif;
  }
}
@font-face {
  font-family: Blue Sans;
  src: url(https://t-cf.bstatic.com/design-assets/assets/v3.109.6/fonts-brand/BookingExtraBold.woff) format('woff');
  font-weight: 800;
}
@font-face {
  font-family: Blue Sans;
  src: url(https://t-cf.bstatic.com/design-assets/assets/v3.109.6/fonts-brand/BookingRegular.woff) format('woff');
  font-weight: 400;
}
@font-face {
  font-family: Blue Sans;
  src: url(https://t-cf.bstatic.com/design-assets/assets/v3.109.6/fonts-brand/BookingBold.woff) format('woff');
  font-weight: 700;
}
.AriaLive-module__message___X9rsW {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
:root {
  --cx-primary-color: #0071c2;
  --cx-alert-color: #c00;
  --cx-success-color: #008009;
  --cx-bg-color-1: #fff;
  --cx-bg-color-2: #383838;
  --cx-bg-color-3: #949494;
  --cx-bg-color-4: #ededed;
  --cx-bg-color-5: #003580;
  --cx-bg-color-6: #d9d9d9;
  --cx-txt-color-1: #262626;
  --cx-txt-color-2: #6b6b6b;
  --cx-txt-color-3: #fff;
  --cx-txt-color-4: #949494;
  --cx-icon-color-1: #8c8c8c;
  --cx-icon-color-2: #000;
  --cx-icon-color-3: #6b6b6b;
  --cx-border-color-1: #e7e7e7;
  --cx-border-color-2: #949494;
  --cx-outline-color-1: #000;
}
.cx-widget.cx-theme-booking {
  font-size: 16px;
  color: var(--cx-txt-color-1);
  background-color: var(--cx-bg-color-1);
  scrollbar-face-color: var(--cx-bg-color-3);
  scrollbar-track-color: var(--cx-bg-color-3);
  scrollbar-arrow-color: var(--cx-bg-color-3);
}
.cx-widget.cx-theme-booking,
.cx-widget.cx-theme-booking h2.cx-title {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
}
.cx-widget.cx-theme-booking.cx-window-manager {
  background-color: var(--cx-bg-color-5);
  height: 100vh;
}
.cx-widget.cx-theme-booking .cx-theme {
  background-color: var(--cx-bg-color-1);
  color: var(--cx-txt-color-1);
  border-color: var(--cx-border-color-1) !important;
}
.cx-widget.cx-theme-booking .cx-overlay {
  background-color: var(--cx-bg-color-1);
}
.cx-widget.cx-theme-booking .cx-svg-icon-tone1 {
  fill: var(--cx-primary-color);
}
.cx-widget.cx-theme-booking .cx-svg-icon-tone2 {
  fill: var(--cx-icon-color-1);
}
.cx-widget.cx-theme-booking .cx-svg-icon-shadow1 {
  fill: var(--cx-icon-color-2);
  opacity: 0.2;
}
.cx-widget.cx-theme-booking .cx-svg-icon-shadow2 {
  fill: var(--cx-icon-color-2);
  opacity: 0.1;
}
.cx-widget.cx-theme-booking * {
  border-color: var(--cx-border-color-1);
}
.cx-widget.cx-theme-booking .cx-titlebar .cx-title {
  color: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking .cx-buttons-window-control .cx-svg-icon-tone1 {
  fill: var(--cx-icon-color-3);
}
.cx-widget.cx-theme-booking .cx-input-icon-overlay .cx-svg-icon-tone1 {
  fill: var(--cx-primary-color);
}
.cx-widget.cx-theme-booking label {
  color: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking a {
  color: var(--cx-primary-color);
}
.cx-widget.cx-theme-booking a:hover {
  color: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking .cx-icon-alert-circle {
  color: var(--cx-alert-color);
}
.cx-widget.cx-theme-booking .cx-branding,
.cx-widget.cx-theme-booking .cx-footer {
  color: var(--cx-txt-color-2);
}
.cx-widget.cx-theme-booking .cx-branding *,
.cx-widget.cx-theme-booking .cx-footer * {
  fill: var(--cx-txt-color-2);
}
.cx-widget.cx-theme-booking .cx-form-control {
  background-color: var(--cx-bg-color-1);
  color: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking .cx-form-control.cx-error {
  border-color: var(--cx-alert-color) !important;
}
.cx-widget.cx-theme-booking .cx-form-control::placeholder {
  color: var(--cx-txt-color-2);
}
.cx-widget.cx-theme-booking .cx-form-control:-moz-placeholder,
.cx-widget.cx-theme-booking .cx-form-control::-moz-placeholder {
  color: var(--cx-txt-color-2);
}
.cx-widget.cx-theme-booking .cx-form-control:-ms-input-placeholder {
  color: var(--cx-txt-color-2);
}
.cx-widget.cx-theme-booking .cx-form-control::-webkit-input-placeholder {
  color: var(--cx-txt-color-2);
}
.cx-widget.cx-theme-booking .cx-btn:focus {
  border-width: 1px;
}
.cx-widget.cx-theme-booking .cx-btn:focus,
.cx-widget.cx-theme-booking .cx-button-group button:focus,
.cx-widget.cx-theme-booking .cx-form-control:not(.cx-error):focus,
.cx-widget.cx-theme-booking input:focus,
.cx-widget.cx-theme-booking textarea:focus {
  border-color: var(--cx-primary-color) !important;
}
.cx-widget.cx-theme-booking input,
.cx-widget.cx-theme-booking select,
.cx-widget.cx-theme-booking textarea {
  background-color: var(--cx-bg-color-1);
  color: var(--cx-txt-color-1);
  border-color: var(--cx-border-color-2);
}
.cx-widget.cx-theme-booking .cx-btn-default {
  background: none;
  border-color: var(--cx-border-color-1) !important;
  color: var(--cx-primary-color);
  background-color: var(--cx-bg-color-1);
}
.cx-widget.cx-theme-booking .cx-btn.cx-disabled {
  background: var(--cx-bg-color-3);
}
.cx-widget.cx-theme-booking .cx-widget .cx-btn {
  font-weight: 500;
}
.cx-widget.cx-theme-booking .cx-btn-primary,
.cx-widget.cx-theme-booking .cx-btn-primary.cx-submit[data-form-valid='true'] {
  cursor: pointer;
  color: var(--cx-txt-color-3);
  border-color: transparent !important;
  background: var(--cx-primary-color);
  background: linear-gradient(180deg, var(--cx-primary-color), var(--cx-primary-color));
}
.cx-widget.cx-theme-booking .cx-btn-primary.cx-submit {
  cursor: not-allowed;
  color: var(--cx-bg-color-3);
  border-color: transparent !important;
  background: var(--cx-bg-color-6);
  background: linear-gradient(180deg, var(--cx-bg-color-6), var(--cx-bg-color-6));
}
.cx-widget.cx-theme-booking .cx-btn-primary.cx-close-confirm,
.cx-widget.cx-theme-booking .cx-btn-primary.cx-end-confirm {
  background: var(--cx-alert-color);
  background: linear-gradient(180deg, var(--cx-alert-color), var(--cx-alert-color));
}
.cx-widget.cx-theme-booking .cx-btn-primary:focus,
.cx-widget.cx-theme-booking .cx-button-group .cx-btn-primary:focus {
  border-color: var(--cx-outline-color-1) !important;
}
.cx-widget.cx-theme-booking .cx-ac-dropdown-menu {
  background: var(--cx-bg-color-1);
  border-color: var(--cx-border-color-1) !important;
}
.cx-widget.cx-theme-booking .cx-ac-suggestion.cx-active,
.cx-widget.cx-theme-booking .cx-ac-suggestion:hover {
  background-color: var(--cx-primary-color);
  color: var(--cx-primary-color);
}
.cx-widget.cx-theme-booking ::-webkit-scrollbar-thumb {
  background: var(--cx-bg-color-3);
}
.cx-widget.cx-theme-booking .cx-smokescreen {
  background-color: var(--cx-bg-color-1);
  opacity: 0.7;
}
.cx-widget.cx-theme-booking .cx-dialog-container .cx-dialog {
  background-color: var(--cx-bg-color-1);
  border-color: var(--cx-border-color-1) !important;
}
.cx-widget.cx-theme-booking .cx-theme-background {
  background-color: var(--cx-bg-color-1);
}
.cx-widget.cx-theme-booking .cx-theme-border {
  border: 1px solid var(--cx-border-color-1) !important;
}
.cx-widget.cx-theme-booking .cx-theme-text {
  color: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
  fill: var(--cx-primary-color);
}
.cx-widget.cx-theme-booking .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
  fill: var(--cx-success-color);
}
.cx-widget.cx-theme-booking .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
  fill: var(--cx-alert-color);
}
.cx-widget.cx-theme-booking .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
  fill: var(--cx-primary-color);
}
.cx-widget.cx-theme-booking .cx-warning {
  color: var(--cx-alert-color) !important;
}
.cx-widget.cx-theme-booking .cx-warning .cx-icon svg * {
  fill: var(--cx-alert-color) !important;
}
.cx-widget.cx-theme-booking .cx-countdown {
  color: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking .cx-countdown .cx-icon svg * {
  fill: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking .cx-webchat {
  width: 360px;
}
.cx-widget.cx-theme-booking .cx-webchat.cx-mobile {
  width: 400px !important;
  left: 50% !important;
  margin-left: -200px;
}
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-you .cx-bubble {
  background-color: var(--cx-bg-color-2);
  color: var(--cx-txt-color-3);
}
.cx-widget.cx-theme-booking
  .cx-transcript
  .cx-message-group
  > .cx-message.cx-participant.cx-you
  .cx-bubble
  .cx-message-text,
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-you .cx-bubble .cx-name,
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-you .cx-bubble .cx-time {
  color: var(--cx-txt-color-3);
}
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-you .cx-bubble-arrow svg {
  fill: var(--cx-bg-color-2);
}
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble {
  background-color: #ededed;
  color: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble .cx-message-text,
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble .cx-name,
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble .cx-time {
  color: var(--cx-txt-color-1);
}
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble-arrow svg {
  fill: #ededed;
}
.cx-widget.cx-theme-booking .cx-button-group.cx-buttons-window-control button.cx-icon.cx-button-minimize {
  display: none;
}
.cx-widget.cx-theme-booking .cx-input-container {
  font-size: 12px;
}
.cx-widget.cx-theme-booking .cx-input-container .cx-textarea-cell textarea.cx-message-input.cx-input {
  overflow: auto;
  font-size: 11px !important;
  line-height: 12px !important;
}
.cx-widget.cx-theme-booking .cx-webchat.cx-mobile .cx-form.cx-form-horizontal {
  padding: 18px 16px;
}
.cx-widget.cx-theme-booking .cx-webchat .cx-form table td {
  padding: 6px 0;
}
.cx-widget.cx-theme-booking input.cx-input[disabled] {
  background: #f2f2f2;
}
.cx-widget.cx-theme-booking label[for='cx_webchat_welcome'] {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.cx-widget.cx-theme-booking #consent-privacy-policy-link,
.cx-widget.cx-theme-booking label[for='cx_webchat_form_consent'] {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.cx-widget.cx-theme-booking input#cx_webchat_form_consent {
  margin-top: 4px;
}
.cx-widget.cx-theme-booking input#cx_webchat_welcome {
  display: none;
}
.cx-widget.cx-theme-booking .cx-webchat.cx-mobile .cx-footer {
  display: none !important;
}
.cx-widget.cx-theme-booking #cx_chat_confirm_close,
.cx-widget.cx-theme-booking #cx_chat_end_question {
  margin: 16px 0;
}
.useModalDialog-module__wrapper___MN0hs {
  padding-top: 0;
}
.ErrorAlert-module__illustrationContainer___WcvwC {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--bui_border_radius_300) var(--bui_border_radius_300) 0 0;
  background-color: var(--bui_color_background_base_alt);
  overflow: hidden;
}
.ErrorAlert-module__illustrationContainer___WcvwC svg {
  height: 60vh;
}
.ErrorAlert-module__infoWrapper___nfrkD {
  padding: var(--bui_spacing_4x);
}
.ErrorAlert-module__button___bC7te {
  width: 350px;
  height: var(--bui_spacing_12x);
  line-height: var(--bui_font_small_2_line-height);
  margin-top: var(--bui_spacing_3x) !important;
}
.ConfirmationInnerLoader-module__loading___b8BeE {
  font-size: var(--bui_font_emphasized_2_font-size);
  font-weight: var(--bui_font_emphasized_2_font-weight);
  line-height: var(--bui_font_emphasized_2_line-height);
  font-family: var(--bui_font_emphasized_2_font-family);
  text-align: center;
  color: var(--bui_color_foreground_alt);
  margin: var(--bui_spacing_4x);
}
.Frame-module__justify-content_flex-start___EWEFz {
  display: flex;
  justify-content: flex-start;
}
.Frame-module__justify-content_flex-end___LRDDB {
  display: flex;
  justify-content: flex-end;
}
.Frame-module__justify-content_start___zkDm1 {
  display: flex;
  justify-content: flex-start;
}
.Frame-module__justify-content_end___EcL8h {
  display: flex;
  justify-content: flex-end;
}
.Frame-module__justify-content_left___BxwSn {
  display: flex;
  justify-content: left;
}
.Frame-module__justify-content_right___j6VCt {
  display: flex;
  justify-content: right;
}
.Frame-module__justify-content_center___dZBwL {
  display: flex;
  justify-content: center;
}
.Frame-module__justify-content_space-around___HdMD8 {
  display: flex;
  justify-content: space-around;
}
.Frame-module__justify-content_space-between___sPQNS {
  display: flex;
  justify-content: space-between;
}
.Frame-module__align-items_flex-start___deoOz {
  display: flex;
  align-items: flex-start;
}
.Frame-module__align-items_flex-end___YyLpt {
  display: flex;
  align-items: flex-end;
}
.Frame-module__align-items_start___fpcqd {
  display: flex;
  align-items: flex-start;
}
.Frame-module__align-items_end___97r6q {
  display: flex;
  align-items: flex-end;
}
.Frame-module__align-items_top___ft1B5 {
  display: flex;
  align-items: top;
}
.Frame-module__align-items_bottom___2XYDm {
  display: flex;
  align-items: bottom;
}
.Frame-module__align-items_center___DCS7Y {
  display: flex;
  align-items: center;
}
.Frame-module__align-items_stretch___pFldB {
  display: flex;
  align-items: stretch;
}
.Frame-module__align-items_baseline___\+EEk9 {
  display: flex;
  align-items: baseline;
}
.Frame-module__align-items_left___l5DZq {
  display: flex;
  align-items: left;
}
.Frame-module__align-items_right___Di0-z {
  display: flex;
  align-items: right;
}
.Frame-module__flex-wrap_wrap___jtOMN {
  display: flex;
  flex-wrap: wrap;
}
.Frame-module__flex-wrap_nowrap___gT8jP {
  display: flex;
  flex-wrap: nowrap;
}
.Frame-module__flex-wrap_wrap-reverse___dTLui {
  display: flex;
  flex-wrap: wrap-reverse;
}
.Frame-module__flex-direction_column___ms2of {
  display: flex;
  flex-direction: column;
}
.Frame-module__flex-direction_column-reverse___z7TTR {
  display: flex;
  flex-direction: column-reverse;
}
.Frame-module__flex-direction_row___xHVKZ {
  display: flex;
  flex-direction: row;
}
.Frame-module__flex-direction_row-reverse___Jqyk9 {
  display: flex;
  flex-direction: row-reverse;
}
.Frame-module__flex-grow_0___3kAyO {
  display: flex;
  flex-grow: 0;
}
.Frame-module__flex-grow_1___2m7TV {
  display: flex;
  flex-grow: 1;
}
.Frame-module__flex-grow_2___CaQZD {
  display: flex;
  flex-grow: 2;
}
.Frame-module__flex-grow_3___AUtBp {
  display: flex;
  flex-grow: 3;
}
.Frame-module__flex-grow_4___gQEOO {
  display: flex;
  flex-grow: 4;
}
.Frame-module__flex-grow_5___PjV-y {
  display: flex;
  flex-grow: 5;
}
.Frame-module__margin_0___4DCXj {
  margin: 0;
}
.Frame-module__margin-top_0___WfxRq {
  margin-top: 0;
}
.Frame-module__margin-right_0___eLZds {
  margin-right: 0;
}
.Frame-module__margin-bottom_0___tpXvU {
  margin-bottom: 0;
}
.Frame-module__margin-left_0___4Phvi {
  margin-left: 0;
}
.Frame-module__margin_05___iO4cD {
  margin: var(--bui_spacing_half);
}
.Frame-module__margin-top_05___jjKux {
  margin-top: var(--bui_spacing_half);
}
.Frame-module__margin-right_05___Kg-ng {
  margin-right: var(--bui_spacing_half);
}
.Frame-module__margin-bottom_05___IwPGu {
  margin-bottom: var(--bui_spacing_half);
}
.Frame-module__margin-left_05___FmsG6 {
  margin-left: var(--bui_spacing_half);
}
.Frame-module__margin_1___3Y0Ut {
  margin: var(--bui_spacing_1x);
}
.Frame-module__margin-top_1___6FtSt {
  margin-top: var(--bui_spacing_1x);
}
.Frame-module__margin-right_1___kowlJ {
  margin-right: var(--bui_spacing_1x);
}
.Frame-module__margin-bottom_1___uhLkp {
  margin-bottom: var(--bui_spacing_1x);
}
.Frame-module__margin-left_1___Ov-P7 {
  margin-left: var(--bui_spacing_1x);
}
.Frame-module__margin_2___lrfq1 {
  margin: var(--bui_spacing_2x);
}
.Frame-module__margin-top_2___9V-am {
  margin-top: var(--bui_spacing_2x);
}
.Frame-module__margin-right_2___j0dnj {
  margin-right: var(--bui_spacing_2x);
}
.Frame-module__margin-bottom_2___WgKZu {
  margin-bottom: var(--bui_spacing_2x);
}
.Frame-module__margin-left_2___ksn65 {
  margin-left: var(--bui_spacing_2x);
}
.Frame-module__margin_3___m523J {
  margin: var(--bui_spacing_3x);
}
.Frame-module__margin-top_3___Lwpzy {
  margin-top: var(--bui_spacing_3x);
}
.Frame-module__margin-right_3___D9Imy {
  margin-right: var(--bui_spacing_3x);
}
.Frame-module__margin-bottom_3___s77IH {
  margin-bottom: var(--bui_spacing_3x);
}
.Frame-module__margin-left_3___32TSl {
  margin-left: var(--bui_spacing_3x);
}
.Frame-module__margin_4___3zoZe {
  margin: var(--bui_spacing_4x);
}
.Frame-module__margin-top_4___sv0RN {
  margin-top: var(--bui_spacing_4x);
}
.Frame-module__margin-right_4___yC0oB {
  margin-right: var(--bui_spacing_4x);
}
.Frame-module__margin-bottom_4___6J8o7 {
  margin-bottom: var(--bui_spacing_4x);
}
.Frame-module__margin-left_4___XIqnL {
  margin-left: var(--bui_spacing_4x);
}
.Frame-module__margin_6___3EOv3 {
  margin: var(--bui_spacing_6x);
}
.Frame-module__margin-top_6___qBVzW {
  margin-top: var(--bui_spacing_6x);
}
.Frame-module__margin-right_6___o4enb {
  margin-right: var(--bui_spacing_6x);
}
.Frame-module__margin-bottom_6___2eN6j {
  margin-bottom: var(--bui_spacing_6x);
}
.Frame-module__margin-left_6___LhGx8 {
  margin-left: var(--bui_spacing_6x);
}
.Frame-module__margin_8___WM0ph {
  margin: var(--bui_spacing_8x);
}
.Frame-module__margin-top_8___8vzTo {
  margin-top: var(--bui_spacing_8x);
}
.Frame-module__margin-right_8___vx4Sb {
  margin-right: var(--bui_spacing_8x);
}
.Frame-module__margin-bottom_8___RW42U {
  margin-bottom: var(--bui_spacing_8x);
}
.Frame-module__margin-left_8___0RaQm {
  margin-left: var(--bui_spacing_8x);
}
.Frame-module__padding_0___oo1\+0 {
  padding: 0;
}
.Frame-module__padding-top_0___mOQht {
  padding-top: 0;
}
.Frame-module__padding-right_0___-\+Z6K {
  padding-right: 0;
}
.Frame-module__padding-bottom_0___SDdgV {
  padding-bottom: 0;
}
.Frame-module__padding-left_0___Hf9Su {
  padding-left: 0;
}
.Frame-module__padding_05___be5R5 {
  padding: var(--bui_spacing_half);
}
.Frame-module__padding-top_05___lc2wc {
  padding-top: var(--bui_spacing_half);
}
.Frame-module__padding-right_05___KWvmF {
  padding-right: var(--bui_spacing_half);
}
.Frame-module__padding-bottom_05___Erx38 {
  padding-bottom: var(--bui_spacing_half);
}
.Frame-module__padding-left_05___UvVME {
  padding-left: var(--bui_spacing_half);
}
.Frame-module__padding_1___JgQWv {
  padding: var(--bui_spacing_1x);
}
.Frame-module__padding-top_1___VfyKH {
  padding-top: var(--bui_spacing_1x);
}
.Frame-module__padding-right_1___w727J {
  padding-right: var(--bui_spacing_1x);
}
.Frame-module__padding-bottom_1___gq6qu {
  padding-bottom: var(--bui_spacing_1x);
}
.Frame-module__padding-left_1___\+PLV4 {
  padding-left: var(--bui_spacing_1x);
}
.Frame-module__padding_2___Qw5iT {
  padding: var(--bui_spacing_2x);
}
.Frame-module__padding-top_2___WO5nj {
  padding-top: var(--bui_spacing_2x);
}
.Frame-module__padding-right_2___QCaJ8 {
  padding-right: var(--bui_spacing_2x);
}
.Frame-module__padding-bottom_2___8mp0T {
  padding-bottom: var(--bui_spacing_2x);
}
.Frame-module__padding-left_2___xDWfs {
  padding-left: var(--bui_spacing_2x);
}
.Frame-module__padding_3___XuYss {
  padding: var(--bui_spacing_3x);
}
.Frame-module__padding-top_3___uFpGl {
  padding-top: var(--bui_spacing_3x);
}
.Frame-module__padding-right_3___mVEP6 {
  padding-right: var(--bui_spacing_3x);
}
.Frame-module__padding-bottom_3___fF1GG {
  padding-bottom: var(--bui_spacing_3x);
}
.Frame-module__padding-left_3___nR6vg {
  padding-left: var(--bui_spacing_3x);
}
.Frame-module__padding_4___mVTBX {
  padding: var(--bui_spacing_4x);
}
.Frame-module__padding-top_4___MXbcl {
  padding-top: var(--bui_spacing_4x);
}
.Frame-module__padding-right_4___wHa2i {
  padding-right: var(--bui_spacing_4x);
}
.Frame-module__padding-bottom_4___i8mtx {
  padding-bottom: var(--bui_spacing_4x);
}
.Frame-module__padding-left_4___HnlOP {
  padding-left: var(--bui_spacing_4x);
}
.Frame-module__padding_6___Zt8cx {
  padding: var(--bui_spacing_6x);
}
.Frame-module__padding-top_6___SL2Jm {
  padding-top: var(--bui_spacing_6x);
}
.Frame-module__padding-right_6___GhdHw {
  padding-right: var(--bui_spacing_6x);
}
.Frame-module__padding-bottom_6___6Edyp {
  padding-bottom: var(--bui_spacing_6x);
}
.Frame-module__padding-left_6___EVmIu {
  padding-left: var(--bui_spacing_6x);
}
.Frame-module__padding_8___1QDmU {
  padding: var(--bui_spacing_8x);
}
.Frame-module__padding-top_8___99Vik {
  padding-top: var(--bui_spacing_8x);
}
.Frame-module__padding-right_8___co8qt {
  padding-right: var(--bui_spacing_8x);
}
.Frame-module__padding-bottom_8___5hhRq {
  padding-bottom: var(--bui_spacing_8x);
}
.Frame-module__padding-left_8___ZOqOO {
  padding-left: var(--bui_spacing_8x);
}
.FeedbackModal-module__root___9j2YX {
  padding: 0;
}
.FeedbackModal-module__rootDesktop___7gwEX {
  width: 600px;
}
.FeedbackModal-module__iframe___yVrHc {
  width: 100%;
  border: none;
}
.FeedbackModal-module__iframeMobile___c5M5I {
  height: calc(100vh - var(--bui_spacing_6x) * 2);
}
.FeedbackModal-module__iframeDesktop___q1PBt {
  height: 490px;
}
.Feedback-module__container___J5aOb {
  cursor: pointer;
}
.Feedback-module__containerRounded___t-1Lu {
  border-radius: var(--bui_border_radius_100);
}
.Feedback-module__link___Bt7wM {
  margin-bottom: var(--bui_spacing_1x);
}
@keyframes FeedbackButton-module__slidein___NzWy6 {
  0% {
    opacity: 0;
    transform: translateY(12px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.FeedbackButton-module__feedbackButton___z7xUs {
  position: fixed;
  bottom: 20px;
  animation: FeedbackButton-module__slidein___NzWy6 0.4s ease-out normal forwards;
  cursor: pointer;
}
[dir='ltr'] .FeedbackButton-module__feedbackButton___z7xUs {
  right: 20px;
}
[dir='rtl'] .FeedbackButton-module__feedbackButton___z7xUs {
  left: 20px;
}
.FeedbackButton-module__feedbackButtonText___QvAsW {
  border-radius: var(--bui_spacing_4x);
  padding: var(--bui_spacing_2x) var(--bui_spacing_4x);
  box-shadow: var(--bui_shadow_100);
  height: 36px;
}
.FeedbackButton-module__feedbackButtonText___QvAsW,
.TravellerFooter-module__root--variant-full___buV4D {
  background-color: var(--bui_color_background_base_alt);
}
.TravellerFooter-module__footer-lists___55Zxg {
  padding-top: var(--bui_spacing_8x);
  padding-bottom: var(--bui_spacing_8x);
}
.TravellerFooter-module__footer-lists--list___BqsG1 {
  max-width: 196px;
}
.TravellerFooter-module__root--variant-minimal___qdk9z {
  background-color: var(--bui_color_background_base_alt);
}
.TravellerFooter-module__full-footer-pickers___O1yXX.TravellerFooter-module__full-footer-pickers___O1yXX {
  margin-right: 0;
}
.TravellerFooter-module__minimal-footer-copyrights-container___a995e {
  padding-top: 0;
}
.TravellerFooter-module__minimal-footer-copyrights-divider___q67DT {
  margin-bottom: var(--bui_spacing_4x);
}
.TravellerFooter-module__inline-links-list--light___pf1sG {
  background-color: var(--bui_color_background_base);
}
.TravellerFooter-module__inline-links-list--dark___UoAgh {
  background-color: var(--bui_color_background_base_alt);
}
.TravellerFooter-module__inline-links-list-item___CINVs:after {
  content: '.';
  position: absolute;
  bottom: 2px;
  right: -8px;
}
.TravellerFooter-module__inline-links-list-item___CINVs:last-child:after {
  content: '';
  margin: 0;
}
.TravellerFooter-module__footer-control-section___W7r57 {
  padding-top: 0;
}
@media (min-width: 1024px) {
  .TravellerFooter-module__footer-lists___55Zxg {
    padding: var(--bui_spacing_8x);
  }
  .TravellerFooter-module__inline-links-list--content___tlT5c,
  .TravellerFooter-module__minimal-footer-copyrights-container___a995e {
    padding-left: var(--bui_spacing_8x);
    padding-right: var(--bui_spacing_8x);
  }
}
.LanguagePicker-module__flag___uvpCC {
  border: var(--bui_border_width_100) solid var(--bui_color_border_alt);
}
.Picker-module__mobile-content___Tv\+2g li,
.Picker-module__mobile-content___Tv\+2g ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.Picker-module__mobile-content___Tv\+2g {
  padding-top: calc(var(--bui_spacing_1x) * 10);
  padding-bottom: calc(var(--bui_spacing_1x) * 20);
}
.Picker-module__selection-list___DajFy {
  margin-left: calc(var(--bui_spacing_3x) * -1);
  margin-right: calc(var(--bui_spacing_3x) * -1);
}
.Picker-module__selection-text___0uu72 {
  unicode-bidi: plaintext;
}
.Picker-module__selection-trigger___I4WCY {
  width: auto;
}
.Picker-module__menu-title___a1nSW {
  padding: var(--bui_spacing_4x) var(--bui_spacing_6x) var(--bui_spacing_3x);
}
.CurrencyPicker-module__currency___fOLZv {
  color: var(--bui_color_foreground_alt);
}
.CurrencyPicker-module__currency--mobile___ekhjw {
  text-align: center;
  white-space: nowrap;
  width: var(--bui_spacing_6x);
}
.CurrencyPicker-module__currency--active___z2eJe {
  color: inherit;
}
.Container-module__container___QbI23 {
  min-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1100px;
}
@media (max-width: 350px) {
  .Container-module__container___QbI23 {
    min-width: auto;
  }
}
.Container-module__container___QbI23 > div {
  width: 100%;
}
.useSideBarSticky-module__sticky___\+iLI\+ {
  position: -webkit-sticky;
  position: sticky;
  top: var(--bui_spacing_4x);
}
.useSideBarSticky-module__innerScroll___TbSUh {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - var(--bui_spacing_4x));
}
[dir='ltr'] .useSideBarSticky-module__innerScroll___TbSUh[data-scroll-padding] {
  padding-right: var(--bui_spacing_4x);
}
[dir='rtl'] .useSideBarSticky-module__innerScroll___TbSUh[data-scroll-padding] {
  padding-left: var(--bui_spacing_4x);
}
.Header-module__root___tYKpB header [data-testid='header-xpb'] {
  display: none !important;
}
.Header-module__root___O6WrD {
  background: var(--bui_color_brand_primary_background_dynamic);
  color: var(--bui_color_on_brand_primary_background_dynamic);
}
.Header-module__root___O6WrD.Header-module__root--variant-transparent-light___OrlGR {
  background: transparent;
  color: var(--bui_color_white);
}
.Header-module__bar___PP8gv {
  display: flex;
  align-items: center;
  padding: var(--bui_spacing_2x) var(--bui_spacing_4x);
}
.Header-module__tab___5lYcC {
  padding: 0 var(--bui_spacing_4x) var(--bui_spacing_2x);
}
.Header-module__main___yPuV1 {
  flex-grow: 1;
  padding: var(--bui_spacing_2x) var(--bui_spacing_4x) var(--bui_spacing_2x) 0;
}
.Header-module__logo___jF\+mz img,
.Header-module__logo___jF\+mz svg {
  display: block;
  height: calc(var(--bui_spacing_1x) * 5);
}
@media (min-width: 1024px) {
  .Header-module__logo___jF\+mz img,
  .Header-module__logo___jF\+mz svg {
    height: calc(var(--bui_spacing_1x) * 6);
  }
  .Header-module__bar___PP8gv,
  .Header-module__tab___5lYcC {
    max-width: var(--bui_container);
    margin: 0 auto;
    box-sizing: border-box;
  }
  .Header-module__bar___PP8gv {
    padding: var(--bui_spacing_3x) var(--bui_spacing_8x) var(--bui_spacing_2x);
  }
  .Header-module__tab___5lYcC {
    padding-bottom: var(--bui_spacing_3x);
  }
}
.WorldwideFlightsItem-module__wrapper___W1Nc6 {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.WorldwideFlightsItem-module__image___yZvBX {
  border-radius: var(--bui_border_radius_100);
}
.WorldwideFlightsItem-module__textContainer___QJP7r {
  margin: 0 var(--bui_spacing_4x);
}
.WorldwideFlightsItem-module__text___mFRRm {
  margin-bottom: var(--bui_spacing_half);
}
.WorldwideFlights-module__mobileColumn___qtoNv {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.WorldwideFlights-module__mobileColumn___qtoNv > :not(:last-child) {
  margin-bottom: var(--bui_spacing_8x);
}
.WorldwideFlights-module__title___3lm8W {
  margin-bottom: var(--bui_spacing_3x);
}
.WorldwideFlights-module__tabPanelContent___sGzxr {
  padding-top: var(--bui_spacing_4x);
}
.WorldwideFlights-module__tabs___DgFA3 {
  margin-bottom: var(--bui_spacing_6x);
}
.WorldwideFlights-module__activeCarousel___zmUOD {
  display: block;
}
.WorldwideFlights-module__inactiveCarousel___R12zp {
  display: none;
}
.WorldwideFlights-desktop-module__title___HuAgM {
  margin-bottom: var(--bui_spacing_3x);
}
.WorldwideFlights-desktop-module__tabPanelContent___jpdrq {
  padding-top: var(--bui_spacing_4x);
}
.WorldwideFlights-desktop-module__activeGrid___AzuE\+ {
  display: block;
}
.AppShell-module__root___Yexdu {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}
.AppShell-module__appShell___cl5pA {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.AppShell-desktop-module__root___-GALm {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.AppShell-desktop-module__content___rttDd {
  display: flex;
  flex-grow: 1;
}
