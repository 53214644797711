.panorama {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  width: 100%;
  height: 100%;

  display: none;

  transition: opacity 0.3s;
}

.panorama--open_mod {
  display: block;
}

iframe.panorama__iframe {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.panorama__close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;

  width: 50px;
  height: 50px;

  border: none;
  background: transparent;

  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
  filter: none;
  border: none !important;
}

.panorama__close svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #fff;
}

button * {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
  filter: none;
  border: none !important;
}
