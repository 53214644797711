.seatmaps_gallery {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  padding-left: 12px;
}

.seatmaps_gallery__item {
  position: relative;

  width: calc(50% - 10px);
  aspect-ratio: 1.55;
}

.seatmaps_gallery__btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  opacity: 0;

  cursor: pointer;
}

.seatmaps_gallery__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
