.gallery {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s;

  .swiper {
    overflow: hidden !important;
  }
}

.gallery.gallery--open_mod {
  opacity: 1;
  pointer-events: initial;
}

.gallery__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background-color: black;
  opacity: 0.6;
}

.gallery__content {
  position: relative;

  padding: 20px 30px;
  margin: auto;
  width: 760px;
  max-width: 100%;

  border-radius: 20px;
  background-color: white;
}

.gallery__close {
  position: absolute;
  top: 20px;
  right: 30px;

  width: 20px;
  height: 20px;
  padding: 0;

  color: black;

  outline: none !important;
  background: transparent;
  border: none;
  cursor: pointer;
}

.gallery__close:focus,
.gallery__close:hover {
  outline: none;
}

.gallery__title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.gallery__slider {
  margin-bottom: 20px;
}

.gallery__slider_item {
  position: relative;
}

.gallery__slider_picture {
  position: relative;

  margin-bottom: 10px;
}

.gallery__slider_picture:before {
  content: '';
  display: block;
  padding-bottom: 60%;
}

.gallery__thumbs .gallery__slider_picture:before {
  padding-bottom: 90%;
}

.gallery__thumbs .swiper-button-prev,
.gallery__thumbs .swiper-button-next {
  width: 20px !important;
  height: 20px !important;
  color: white;
}

.gallery__slider .swiper-button-prev:after,
.gallery__slider .swiper-button-next:after {
  font-size: 25px !important;
}

.gallery__slider_iframe,
.gallery__slider_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery__slider_text {
  font-size: 14px;
}

.gallery__slider_360 {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: transparent;
  border: none;

  cursor: pointer;
}

.gallery__slider_360_pic {
  position: absolute;
  top: 10px;
  right: 10px;

  width: 40px;
  height: 40px;
}

.gallery__slider_360_img {
  display: block;
  width: 120%;
  height: 120%;
  object-fit: contain;
}

.gallery__thumbs .swiper-slide {
  opacity: 0.6;
}

.gallery__thumbs .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.gallery__thumbs .swiper-wrapper:not(:has(.swiper-slide-thumb-active)) .swiper-slide-active {
  opacity: 1;
}
