.jets-seat {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  float: left;
  pointer-events: auto;
  touch-action: auto;
}

.jets-seat * {
  pointer-events: none;
}

.jets-unavailable {
  position: relative;
  cursor: not-allowed;
  pointer-events: none;
}

.jets-available {
  position: relative;
  cursor: pointer;
}

.jets-selected {
  color: #fff;
  position: relative;
  cursor: pointer;
}

.jets-aisle,
.jets-index,
.jets-empty {
  pointer-events: none;
  /* transform: rotate(0) !important; */
}

.jets-seat-r-nw {
  transform: rotate(-20deg);
}

.jets-seat-r-nw > .jets-seat-passenger,
.jets-seat-r-nw > .jets-seat-number {
  transform: rotate(20deg);
}

.jets-seat-r-nw45 {
  transform: rotate(-45deg);
}

.jets-seat-r-nw45 > .jets-seat-passenger,
.jets-seat-r-nw45 > .jets-seat-number {
  transform: rotate(45deg);
}

.jets-seat-r-ne {
  transform: rotate(20deg);
}

.jets-seat-r-ne > .jets-seat-passenger,
.jets-seat-r-ne > .jets-seat-number {
  transform: rotate(-20deg);
}

.jets-seat-r-ne45 {
  transform: rotate(45deg);
}

.jets-seat-r-ne45 > .jets-seat-passenger,
.jets-seat-r-ne45 > .jets-seat-number {
  transform: rotate(-45deg);
}

.jets-seat-r-s {
  transform: rotate(180deg);
}

.jets-seat-r-s > .jets-seat-passenger,
.jets-seat-r-s > .jets-seat-number {
  transform: rotate(-180deg);
}

.jets-seat-r-se {
  transform: scale(0.8) rotate(160deg);
}

.jets-seat-r-se > .jets-seat-passenger,
.jets-seat-r-se > .jets-seat-number {
  transform: rotate(-160deg);
}

.jets-seat-r-sw {
  transform: scale(0.8) rotate(-160deg);
}

.jets-seat-r-sw > .jets-seat-passenger,
.jets-seat-r-sw > .jets-seat-number {
  transform: rotate(160deg);
}

svg {
  height: 100%;
  width: 100%;
}

.jets-seat-passenger {
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  max-width: 192px;
  max-height: 192px;
}

.jets-seat-svg {
  width: 100%;
  height: 100%;
}

.jets-seat-number {
  position: absolute;
  top: 18%;
  font-size: 30px;
  color: #fff;
  text-align: center;
}

.jets-unavailable .jets-seat-number {
  color: #595959;
}

.jets-available .jets-seat-svg rect:last-child,
.jets-unavailable .jets-seat-svg rect:last-child {
  fill: #ccc !important;
  opacity: 0.7;
}

.jets-seat-selected .jets-seat-number {
  color: rgb(0, 130, 52);
}

.jets-available .jets-seat-svg rect,
.jets-available .jets-seat-svg path {
  fill: #003b95;
}

.jets-available.jets-seat-no-price .jets-seat-svg rect,
.jets-available.jets-seat-no-price .jets-seat-svg path {
  fill: #006ce4;
}

.jets-seat-selected .jets-seat-svg path {
  fill: #f1fef6;
  stroke: rgb(0, 130, 52);
}

.jets-seat-selected .jets-seat-svg rect {
  fill: rgb(0, 130, 52);
}

.jets-seat-selected .jets-seat-svg rect:last-child {
  stroke: rgb(0, 130, 52);
  fill: #fff;
}

.seatmap_wrap_block[data-type='premium'] .jets-seat-selected .jets-seat-svg path:nth-child(2),
.seatmap_wrap_block[data-type='business'] .jets-seat-selected .jets-seat-svg path:last-child {
  fill: #fff;
}

.seatmap_wrap_block[data-type='business'] .jets-seat-svg polygon,
.seatmap_wrap_block[data-type='business'] .jets-seat-svg path:nth-child(2) {
  fill: rgb(204, 204, 204);
  stroke: rgb(204, 204, 204);
}

.ST-5,
.ST-6 {
  top: 28%;
}

.ST-8 {
  margin-left: 12px;
}
.ST-9 {
  margin-right: 12px;
}

.ST-10 {
  margin-left: 60px;
}
.ST-11 {
  margin-right: 60px;
}

.ST-12 {
  top: 25%;
  margin-right: 106px;
}
.ST-13 {
  top: 25%;
  margin-left: 106px;
}

.ST-15 {
  margin-left: 38px;
}
.ST-16 {
  margin-right: 38px;
}
.ST-17 {
  margin-left: 72px;
}
.ST-18 {
  margin-right: 72px;
}

.ST-20,
.ST-21 {
  top: 65%;
}

.ST-22,
.ST-23 {
  top: 60%;
}
.ST-24,
.ST-25 {
  top: 55%;
}
.ST-26 {
  top: 30%;
  margin-right: 50px;
}
.ST-27 {
  top: 30%;
  margin-left: 50px;
}
.ST-28 {
  top: 45%;
  margin-right: 62px;
}
.ST-29 {
  top: 45%;
  margin-left: 62px;
}
.ST-30 {
  top: 60%;
  margin-right: 62px;
}
.ST-31 {
  top: 60%;
  margin-left: 62px;
}
.ST-32 {
  top: 60%;
  margin-right: 58px;
}
.ST-33 {
  top: 60%;
  margin-left: 58px;
}
.ST-34 {
  top: 40%;
  margin-left: 27px;
}
.ST-35 {
  top: 40%;
  margin-right: 27px;
}
.ST-36 {
  top: 62%;
  margin-right: 122px;
}
.ST-37 {
  top: 62%;
  margin-left: 122px;
}
.ST-38 {
  top: 28%;
  margin-right: 122px;
}
.ST-39 {
  top: 28%;
  margin-left: 122px;
}

.ST-42 {
  top: 70%;
  margin-left: 260px;
}
.ST-43 {
  top: 70%;
  margin-right: 242px;
}
.ST-44 {
  top: 18%;
  margin-right: 254px;
}
.ST-45 {
  top: 18%;
  margin-left: 260px;
}

.jets-seat-price {
  position: absolute;
  bottom: calc(100% + 3px);
  left: 50%;

  padding: 4px 4px;

  font-size: 28px;
  color: #595959;
  white-space: nowrap;

  border: 1px solid currentColor;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  transform: translateX(-50%);
}

.jets-seat-price-onhover {
  opacity: 0;
  transition: opacity 0.3s;
}

.jets-seat:hover .jets-seat-price {
  opacity: 1;
}

.deck-exit {
  width: 160px !important;
  height: 70px !important;
  margin: 10px 10px;
}

.deck-exit__image {
  width: 100% !important;
  height: 100% !important;
}

/* wrap */

.wrap {
  width: 1110px;
  margin: 0 auto;
  padding: 40px 40px;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
}

/* tooltip */

.jets-seat-map {
  margin-left: auto;
  margin-right: auto;
}

.jets-tooltip {
  left: auto !important;
  right: calc(100% + 445px) !important;
  right: calc(100% + 180px) !important;
  /* top: 300px !important; */
  /* top: 530px !important; */
  top: calc(var(--pin-height) + 390px) !important;

  padding: 0 !important;

  border: none !important;
  box-shadow: none !important;
}

.seatmap_wrap_block[data-type='premium'],
.seatmap_wrap_block[data-type='business'] {
  padding-bottom: 30px;
}

.seatmap_wrap_block[data-type='premium'] .jets-tooltip {
  top: calc(var(--pin-height) + 390px - var(--business-height)) !important;
}

.seatmap_wrap_block[data-type='econom'] .jets-tooltip {
  top: calc(var(--pin-height) + 390px - var(--premium-height) - var(--business-height)) !important;
}

.jets-tooltip--arrow-pointer {
  display: none !important;
}

.jets-tooltip--content {
  display: flex;
  flex-direction: column;
}

.jets-tooltip--features {
  order: 2;
}

/*  */

.Accordion-module__button___B-zlf {
  position: relative;
}

.seatmap_class_trigger {
  position: absolute;
  right: 0;
  top: 19px;
  z-index: 10;

  display: flex;
  align-items: center;

  font-size: 22px;
  color: #000;
}

.seatmap_class_trigger__btn {
  width: 35px;
  height: 35px;

  border: none;
  background-color: transparent;

  cursor: pointer;

  transition: color 0.3s;
}

.seatmap_class_trigger__btn:last-child {
  margin-left: -10px;
}

.seatmap_class_trigger__btn:hover {
  color: #006ce4;
}

.seatmap_class_trigger__btn--next_mod {
  transform-origin: 50% 50%;
  transform: rotate(180deg);
}

/*  */

.booking_cols {
  position: relative;
  min-height: 50rem;
}
/* 
.booking_col {
  position: absolute;
  height: 100%;
  width: 100% !important;
  right: 0;

  overflow-y: auto;
} */

.seatmap__title {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}

.booking_cols {
  align-items: flex-start;
}

.seatmaps_sidebar {
  position: sticky;
  top: 0;
}

span[data-testid='header-logo'] {
  display: none;
}

.jets-tooltip {
  display: none;
}

.jets-seat-number {
  z-index: 20;
}

.seatmap_wrap_block[data-type='econom'] .jets-seat-svg svg {
  will-change: transform;
}
